import {  Flex, Image, Stack, Text,Icon,colorMode, toggleColorMode, useColorMode, Input, Grid, GridItem, SimpleGrid, Progress, Tag, Tabs, TabList, Tab, TabPanel, TabPanels} from "@chakra-ui/react"; 
import { useEffect, useState } from "react";
import LoadBg from "../../../components/backgound/LoadBg"; 
import { PageHK, SubHK } from "../../../components/heading/HeadingKalyst";
import { sendFormSocket } from "../../../components/session/SessionAdmin"; 
import { LuBox, LuLineChart, LuPen, LuTrash, LuUser2, LuUsers2 } from "react-icons/lu"; 
import ListResponsive from "../../../components/list/ListResponsive";
import { ButtonKNew } from "../../../components/button/ButtonKalyst";
import ModalPage from "../../../components/modal/ModalPage";
import FormDocumentClient from "./FormDocumentClient";
import { LojaSelect } from "../../../layout/painel/MenuPainel";
import BoxDialog from "../../../components/modal/BoxDialog";

 
export function PageAdminClientDocument(props){  
    const [open,setOpen]    = useState(false); 
    const [dados,setDados]  = useState(null); 
    const [edit,setEdit]    = useState({}); 
    const [alert,setAlert]    = useState({});  
    let tempLoja = null;
    function BuscarDados(reload){ 
        if(LojaSelect !==tempLoja){
            tempLoja = LojaSelect
            reload = true; 
        } 
        console.log('buscar')
        sendFormSocket({campo:'pages', modulo:'user',cmd:'document',form:{reload,loja:LojaSelect},callback:(result)=>{  
            setDados(result)
        }});
    } 
    useEffect(()=>{ 
        BuscarDados(true);
        var interV =setInterval(()=>{
            BuscarDados(false);
        },500);
        
        return  ()=>{
            clearInterval(interV);
        }
    },[]);

    if(dados == null){ 
        return <LoadBg/>
    }else{ 
        const titles = [
            {name:'Nome',       campo:'nome'        ,w:2}, 
            {name:'E-mail',     campo:'email'       ,w:2},
            {name:'Status',     campo:'status'      ,w:2},
            {name:'Data',       campo:'criacao'        ,w:2}   
        ];
        const lista = []
        if(dados.users.length>0){ 
            dados.users.forEach((cli)=>{
                lista.push({
                    id          :   cli.id,
                    title       :   cli.nome,
                    status      :   <Tag  colorScheme={cli.status.c}>{cli.status.n}</Tag>,  
                    nome        :   cli.nome,  
                    documento   :   cli.documento,  
                    telefone    :   cli.telefone,  
                    email       :   cli.email,     
                    nlojas      :   cli.nlojas,     
                    criacao     :   new Date(cli.criacao).toLocaleDateString('pt-BR', {  month: '2-digit', day: '2-digit',hour:'2-digit',minute:'2-digit'})
                })
            }) 
        } 
        return  ( 
            <Stack zIndex={3} w='full' gap={{base:'16px',md:'32px'}}  align={'flex-start'} justify='flex-start'   minH={'100vh'} pb='100px'  margin = '0 auto'  >
                <PageHK>Aprovar Documentos</PageHK>   
                <ListResponsive titles={titles} dados={lista} cmd={[
                    { icon:<LuPen/>,name:'Documentos', click:(d)=>{  
                        setEdit(d.id);
                        setOpen(true); 
                    }} 
                ]} /> 
               
                <ModalPage  open={open}   isCentered={false} size="'full" setOpen={setOpen}>
                    <FormDocumentClient  title={'Aprovação de Cliente'}  open={open} setOpen={(el)=>{ 
                        setOpen(el)
                    }} edit={edit} setEdit={setEdit}/>
                </ModalPage>
                <BoxDialog param={alert}  />
            </Stack> 
        )
    }
    
}
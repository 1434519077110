import { Box } from "@chakra-ui/react";

export function BgAnimatedLogin(){
    return (
        <Box position={'fixed'} top='0px' left={'0px'} h='100vh' w='100vw'   
        //  className='bgAnimatedLinear gradient-bg' 
            >
           {/* <Box position={'absolute'} top='0px' left={'0px'} zIndex={2}       backdropFilter='blur(60px)' h='100%' w='100%'></Box>
            <Box zIndex={1} className="g1"></Box>
            <Box zIndex={1} className="g2"></Box>
            <Box zIndex={1} className="g3"></Box>
            <Box zIndex={1} className="g4"></Box>  */}
        </Box>
    )
}
export function BgAnimatedAdmin(){
    return (
        <Box position={'fixed'} top='0px' left={'0px'} h='100vh' w='100vw'    
        // className='bgAnimatedLinearAdmin gradient-bg' 
        opacity={'0.2'}    > 
            {/* <Box position={'absolute'} top='0px' left={'0px'} zIndex={2}       backdropFilter='blur(60px)' h='100%' w='100%'></Box>
            <Box zIndex={1} className="a1"></Box>
            <Box zIndex={1} className="a2"></Box>
            <Box zIndex={1} className="a3"></Box>
            <Box zIndex={1} className="a4"></Box>  */}
        </Box>
    )
}
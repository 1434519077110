import { Box, Flex, HStack, SimpleGrid, Icon,Skeleton, Tab, TabList, TabPanel, TabPanels, Tabs, Tag, Text, Textarea, Stack } from "@chakra-ui/react";
import { HK4, HK5, PageHK, SubBHK, SubTHK } from "../../../components/heading/HeadingKalyst"; 
import { useEffect, useRef, useState } from "react"; 
import { sendFormSocket } from "../../../components/session/SessionAdmin"; 
import { getWebsite, website } from "../../../theme/PageConfig";
import FormDocumentFrente from "../document/InfoDocumentDados";
import ModalAprovarDados from "../document/ModalAprovarDados"; 
import { MdOutlineAssignment, MdOutlineAssignmentInd } from "react-icons/md";
import { TbCameraSelfie, TbFileTypePdf } from "react-icons/tb"; 
import { CiCircleList } from "react-icons/ci";
import ViewFileZoom from "../../../components/file/ViewFileZoom";
import FormSubConta from "../document/FormSubconta";
 

export default function FormAdquirenteCliente( {open,setOpen,title,edit = null,setEdit}){  
    const [load,setLoadD] = useState(null); 
    const [reload,setReload] = useState(false);  
    const [fase,setFase] = useState(true);
    const [dataReload,setDataReload] = useState(true);
    
    const [base,setBase] = useState(null);
    const [tipos,setTipos] = useState(null); 
    const [docs,setDocs] = useState(null);
    const [dadosG,setDadosG] = useState(null);    
    function BuscarDados(){  
        sendFormSocket({campo:'pages', modulo:'user',cmd:'documentUser',form:{reload:dataReload,codusu:edit},callback:(result)=>{   
            setDataReload(false); 
            setLoadD(1);
            setDadosG(result);  
            setBase(result.user); 
            setTipos(result.tipos);
            setDocs(result.docs);  
        }}); 
    } 
    useEffect(()=>{ 
        if(edit!==null){
            setLoadD(0); 
            setReload(0);
            setFase(0);
            setTimeout(()=>{
                BuscarDados();
            },50);
        }else{
            setLoadD(1);
        } 
    },[])   
    let color ={
        'P':'orange',
        'N':'green',
        'S':'green',
        'R':'red',
        'E':'gray'
    };
    let icons = [
        MdOutlineAssignmentInd ,
        MdOutlineAssignment ,
        TbCameraSelfie ,
        TbFileTypePdf  
    ] 
    useEffect(()=>{  },[reload]);

    if(!reload && load){    
        if((base.sysBan=='N' && base.sysRep=='N' && base.sysLoj=='N' && base.sysInf=='N' && base.sysEnd=='N' && base.sysDoc==null)|| base.sysDoc=='S' ){
            return  <FormSubConta setOpen={setOpen} title={title} dados = {dadosG} BuscarDados={BuscarDados}/>  
        }else{
            return (
                <Text  color={color[(base.sysDoc==null?'P':base.sysDoc)]} noOfLines={'1'} display={{base:'none',md:'initial'}} >Conta não ativada</Text>
            )
        } 
    }else{
        setTimeout(()=>{setReload(false)})
        return null;
    }
    
}


 
import { Flex, HStack, SimpleGrid, Skeleton, Textarea } from "@chakra-ui/react";
import { HK4, HK5, PageHK } from "../../../components/heading/HeadingKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { object, string } from "yup";
import { SendForm } from "../../../components/form/SendForm";
import { TextK1 } from "../../../components/text/TextKalyst";
import { LinkK } from "../../../components/button/LinkKalyst";
import { useEffect, useRef, useState } from "react";
import SelectK from "../../../components/select/SelectKalyst";
import { sendFormSocket } from "../../../components/session/SessionKalyst";
import { LojaSelect } from "../../../layout/painel/MenuPainel";

export default function FormClient( {open,setOpen,edit = null,setEdit}){  
    const [load,setLoadD] = useState(null);
    const [base,setBase] = useState(null);
    const [reload,setReload] = useState(false); 
    const [pessoa,setPessoa] = useState('');
    
    useEffect(()=>{
        if(edit!==null){
            setLoadD(0); 
            setTimeout(()=>{
                sendFormSocket({ modulo:'client',cmd:'info',form:{id:edit},callback:(result)=>{ 
                    setLoadD(1);
                    setBase(result); 
                    setPessoa(result.tipo);
                    setReload(true);
                    
                }}); 
            },500)
           
        }else{
            setLoadD(1);
        } 
    },[])  
    let dados = {}; 
    let send ={
        loja    :   LojaSelect
    }; 
    if(base!== null){
        send={
            id      :   base.id,
            loja    :   LojaSelect
        }; 
        dados = {
            nome:base.nome,
            pessoa: base.tipo,
            documento:base.documento, 

            email       :   base.email, 
            telefone    :   base.telefone,  

            cep:base.cep,
            rua:base.rua, 
            num:base.num,
            complemento:base.complemento,

            bairro:base.bairro,
            cidade:base.cidade,
            estado:base.estado,
            pais:base.pais
        };
    } 
    if(!reload){  
        return (
            <SendForm  dados = {{modulo:'client',cmd:'saveClient',form:send}}  
                initial={ dados } 
                schema={ 
                    object({  
                        nome:           string().required('Nome é obrigatório!'),  
                        pessoa:         string().required('Tipo de Cliente é obrigatório!'),
                        documento:      string().required('Documento é obrigatório!'),

                        email:          string().required('Email é obrigatório!'),  
                        telefone:       string().required('Telefone é obrigatório!'), 
 
                        cep:        string().required('CEP é obrigatório!'),
                        rua:        string().required('Endereço é obrigatório!'), 
                        bairro:     string().required('Bairro é obrigatório!'),  
                        cidade:     string().required('Cidade é obrigatório!'),  
                        estado:     string().required('Estado é obrigatório!'),  
                        pais:       string().required('País é obrigatório!')
                    })
                } 
                callback={(result)=>{ 
                    setOpen(false); 
                }}
                gap={'10px'}
            >  
            <PageHK   textAlign='left'>Cliente</PageHK> 
                <InputK loaded={load}  focus={true} type='text' label="Nome"   name="nome" placeholder="Nome"     autoComplete={false}/>  
                <Flex w={'full'} gap='20px'>
                    <SelectK loaded={load}  label={'Tipo Cliente'} w="100%" onChange={(e)=>{
                        setPessoa(e.target.value); 
                    }} placeholder="Selecione" value={[{value:'PF',name:'Física'},{value:'PJ',name:'Jurídica'}]} name='pessoa' ></SelectK> 
                    <InputK loaded={load} disabled={pessoa==''?1:0} type={pessoa=='PJ'?'cnpj':'cpf'}  label='Documento'    w="100%"  name="documento" placeholder={pessoa=='PJ'?'00.000.000/0000-00':'000.000.000-00'}   autoComplete={false}/> 
                </Flex> 
                <PageHK   textAlign='left'>Contato</PageHK> 
                <Flex w={'full'} gap='20px'> 
                    <InputK loaded={load} type='email' label="Email" name="email" placeholder="Digite seu email"  w='50%'    autoComplete={false}/>
                    <InputK loaded={load}  type='phone' label="Telefone" name="telefone" placeholder="Telefone" w='50%'   autoComplete={false}/>
                </Flex>
                <PageHK   textAlign='left'>Endereço</PageHK>  
                <Flex w={'full'} gap={{base:'10px', md:"20px"}}>
                    <InputK loaded={load}   type='cep'    label="CEP" name="cep" w={{base:'130px',md:'160px',lg:'170px'}}  placeholder="00000-000"   focus={false} autoComplete={false} />
                    <InputK loaded={load}  type='text'   label="Endereço"  name="rua"       placeholder="rua,cond,av..."   focus={false} autoComplete={false}/>
                </Flex> 
                <Flex w={'full'} gap={{base:'10px', md:"20px"}}>
                    <InputK loaded={load}  type='integer' w={{base:'80px',md:'100px',lg:'120px'}}                                           label="Número"    name="num"     placeholder="0"   focus={false} autoComplete={false}/>
                    <InputK loaded={load}  type='text'    w={{base:"calc(60% - 80px)",md:"calc(60% - 100px)",lg:"calc(50% - 120px)"}}       label="Complemento"  name="complemento"   placeholder={'bl,ap,lt...'}   focus={false} autoComplete={false}/>
                    <InputK loaded={load}  type='text'    w={{base:"calc(40%)",md:"calc(40%)",lg:"calc(50%)"}}                              label="Bairro"  name="bairro"   placeholder={'Nome do Bairro'}   focus={false} autoComplete={false}/>
                </Flex> 
                <Flex w={'full'}  gap={{base:'10px', md:"20px"}}>
                    <InputK loaded={load}  type='text'   w="calc(40%)" label="Cidade"  name="cidade"  placeholder={'Nome da Cidade'}   focus={false}    autoComplete={false}/>
                    <InputK loaded={load}  type='text'   w="calc(40%)" label="Estado"  name="estado"  placeholder={'Nome do Estado'}   focus={false}    autoComplete={false}/>
                    <InputK loaded={load}  type='text'   w="calc(20%)" label="País"    name="pais"    placeholder={'Nome do País'}     focus={false}    autoComplete={false}/>
                </Flex> 
                <Flex w={'full'} gap={'20px'} align={'center'}  justify={'flex-end'}> 
                    <ButtonKalyst  >
                        {edit==null?'Registrar Cliente':'Salvar Cliente'}
                    </ButtonKalyst> 
                    <LinkK onClick={()=>{
                        setOpen(false);
                    }}>
                        Cancelar
                    </LinkK>
                </Flex>
                
            </SendForm>
        )
    }else{
        setTimeout(()=>{setReload(false)})
        return null;
    }
    
}




// import { Flex, HStack, SimpleGrid } from "@chakra-ui/react";
// import { HK4, HK5, PageHK } from "../../../components/heading/HeadingKalyst";
// import { InputK } from "../../../components/input/InputKalyst";
// import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
// import { object, string } from "yup";
// import { SendForm } from "../../../components/form/SendForm";
// import { TextK1 } from "../../../components/text/TextKalyst";
// import { LinkK } from "../../../components/button/LinkKalyst";
// import { useRef, useState } from "react";

// export default function FormClint( {open,setOpen,dados = {}}){ 
//     const [redirect,setRedirect] = useState(null);  
//     const end = useRef(null);
//     const [bairro,setBairro]    = useState((typeof dados.bairro!=='undefined'?dados.bairro:'')); 
//     const [cidade,setCidade]    = useState((typeof dados.cidade!=='undefined'?dados.cidade:''));  
    
//     return (
//         <SendForm  dados = {{modulo:'user',cmd:'register'}}  
//             initial={ dados }

//             schema={ 
//                 object({ 
//                     nome:       string().required('Razão Social é obrigatório!'),
//                     fantasia:   string().required('Nome Fantasia é obrigatório!'),
//                     email:      string().required('E-mail é obrigatório!'), 
//                     cnpj:       string().required('CNPJ é obrigatório!'), 
//                     telefone:   string().required('Telefone é obrigatório!'), 
//                 })
//             } 
//             callback={(result)=>{ 
              
//             }}
//             gap={'10px'}
//             > 
            
//         </SendForm>
//     )
// }
import { Flex, Stack,Icon, Text, others, IconButton, Tooltip, Skeleton, useToast, useClipboard, Spacer, Progress, GridItem, Switch, Input } from "@chakra-ui/react"; 
import { Space } from "lucide-react";
import { FaInfoCircle } from "react-icons/fa";
import { FaInfo } from "react-icons/fa6";
import { LuBadgeInfo, LuCopy, LuInfo } from "react-icons/lu";
import { InputK } from "../input/InputKalyst";
import { useEffect, useState } from "react";
export function CardSettings({load=true,icon=null, dados={},value = {},onChange=()=>{},setEdit,variant='',...others}){ 
    const [reload,setReload] = useState(null);
    useEffect(()=>{

    },[reload])
    
    return ( 
        <Stack w={'full'}  borderRadius='8px'     align='center' justify={'center'} bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}} p='16px'    {...others}>
            <Flex w={'full'} h={{base:'16px',md:'18px'}} align='center' gap='8px'>
                {icon!==null?<IconButton  minW={'30px'} maxH={'30px'} borderRadius={'8px'}  p={'6px'} color='textDark.100'  icon={icon} _hover={{}}  />:null}
                <Text fontSize={{base:'10px',md:'12px',lg:'13px'}} fontWeight='900' color={variant=='admin'?'textAdmin.300':'textLight.100'} _dark={{color:variant=='admin'?'textAdmin.300':'textDark.100'}}>{dados.title}</Text> 
            </Flex> 
            {dados.dados.map((b,i)=>{
                var valor = b.padrao;
                var campo = b.id;
                if(typeof value[b.id] !='undefined'){
                    valor = value[b.id]; 
                } 
                var dataView = null
                switch (b.type) {
                    case 'porcentagem': 
                        dataView = (
                            <Stack key={b.id+'-'+i} w={'full'} gap='0px' align={'center'} justify='center' h='60px'>  
                                <InputK  type='porcentagem' label={b.name}  size='sm'   placeholder="%"   onChange={(v,v2)=>{
                                    var valor = v2.result.replace(/[^0-9,]/gi, '').replace(',', '.');
                                    value[b.id] = valor;
                                    onChange(value);
                                    // setReload({});
                                }}      name={campo}   max="100"       step='0,00'     focus={false}   autoComplete={false} w='100%' pb='0px'  value={valor.toString()}/>
                            </Stack>
                        )
                    break; 
                    case 'decimal': 
                    console.log(campo,valor)
                        dataView = (
                            <Stack key={b.id+'-'+i} w={'full'} gap='0px' align={'center'} justify='center' h='60px'>   
                                <InputK  type='decimal'     label={b.name}      size='sm'   placeholder="R$"     name={campo}  onChange={(v,v2)=>{
                                    var valor = v.replace(/[^0-9]/gi, '') 
                                    value[b.id] = valor;
                                    onChange(value);
                                    setReload({});
                                }}   sigla={'R$'}    step='0,00'     focus={false}   autoComplete={false} w='100%' value={valor.toString()}/>
                            </Stack>
                        )
                    break;           
                    default: 
                        dataView = (
                            <Flex key={b.id+'-'+i} w={'full'} gap='14px' align={'center'} justify='center'> 
                                <Icon as={FaInfoCircle}  color={variant=='admin'?'textAdmin.100':'textLight.100'} _dark={{color:variant=='admin'?'textAdmin.100':'textDark.100'}} fontSize={{base:'12px',md:'14px',lg:'16px'}}/> 
                                <Text  color={variant=='admin'?'textAdmin.100':'textLight.100'} _dark={{color:variant=='admin'?'textAdmin.100':'textDark.100'}} fontSize={{base:'12px',md:'13px',lg:'14px'}} >{b.name}</Text> <Spacer/>
                                <Switch colorScheme="purple" isChecked={(valor?true:false)} onChange={(e)=>{ 
                                    if(valor){
                                        valor = false;
                                    }else{
                                        valor = true;
                                    }  
                                    value[b.id] = valor;
                                    onChange(value)
                                    setReload({});
                                }}/>
                            </Flex>
                        )
                    break;
                }
                return dataView;
            })}
            
            {/* <Stack  w={'full'} gap={'6px'} pt={'6px'}>
                <Text fontSize={{base:'10px',md:'11px',lg:'12px'}} fontWeight='bold'  _dark={{color:'textDark.100'}}>
                    <Text as='span' fontSize={{base:'10px'}} _dark={{color:'textDark.100'}}   mr='5px'>{sigla}</Text>
                    {value.toString().split(',')[0]}
                    {typeof value.toString().split(',')[1]!=='undefined'?
                    <Text as='span' fontSize={{base:'10px',md:'12px'}} _dark={{color:'textDark.100'}}  >,{value.toString().split(',')[1]}</Text>
                    :null} 
                    
                </Text>  
                <Progress colorScheme={colorScheme}  h='8px' w={'full'}   bg='lightTema1.100' _dark={{bg:'bgMenuDark.200'}} borderRadius='8px'  value={porcent} />    
            </Stack>  */}
        </Stack> 
    );
}
const ads = {
    'dorapag.com':{
        registerGoogle    :   `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://track.dorapag.com/zwrrxeqw.js?st='+i+dl+'';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','TGTB779M');`,
        login       :   '',
        home        :   ''
    }
}
const loadT = {};
export function ImportScript(tag,src='',preload =true,callback){ 
    try {
        loadT[tag]  = document.createElement('script'); 
        loadT[tag].innerText   = src;  
        document.body.appendChild(loadT[tag]); 
    } catch (error) {
        
    } 
}
export function removeScript(tag){ 
    try { 
        document.body.removeChild(loadT[tag]); 
        delete loadT[tag];
    } catch (error) {
        
    } 
}
export default function DadosScript(site,tag){
    if(typeof ads[site] == 'undefined' || typeof ads[site][tag] == 'undefined' || typeof ads[site][tag] =='' || typeof ads[site][tag] ==null){
        return null
    }else{ 
        if(typeof loadT[site+'_'+tag] == 'undefined'){ 
            ImportScript((site+'_'+tag),ads[site][tag],true ); 
        } 
        return ads[site][tag];
    }
}

import { Flex, Stack,Icon, Text, others, IconButton, Tooltip, Skeleton, useToast, useClipboard, Spacer, Progress } from "@chakra-ui/react"; 
import { Space } from "lucide-react";
import { LuBadgeInfo, LuCopy, LuInfo } from "react-icons/lu";
export function CardBarra({load=true,icon=null, title,value=0,info='',sigla='R$',infoM=null,colorScheme='lojista',porcent=0,...others}){
    return (
        <Skeleton isLoaded={load} w={'full'}>
            <Stack w={'full'}  borderRadius='8px'     align='center' justify={'center'} bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}}   {...others}>
               <Flex w={'full'} h={{base:'16px',md:'18px'}} align='center' gap='8px'>
                    {icon!==null?<IconButton  minW={'30px'} maxH={'30px'} borderRadius={'8px'}  p={'6px'} color='textDark.100'  icon={icon} _hover={{}}  />:null}
                    <Text fontSize={{base:'10px',md:'12px',lg:'13px'}} fontWeight='900' color='textLight.100' _dark={{color:'textDark.200'}}>{title}</Text> 
                </Flex> 
                  
                <Stack  w={'full'} gap={'6px'} pt={'6px'}>
                    <Text fontSize={{base:'10px',md:'11px',lg:'12px'}} fontWeight='bold'  _dark={{color:'textDark.100'}}>
                        <Text as='span' fontSize={{base:'10px'}} _dark={{color:'textDark.100'}}   mr='5px'>{sigla}</Text>
                        {value.toString().split(',')[0]}
                        {typeof value.toString().split(',')[1]!=='undefined'?
                        <Text as='span' fontSize={{base:'10px',md:'12px'}} _dark={{color:'textDark.100'}}  >,{value.toString().split(',')[1]}</Text>
                        :null} 
                        
                    </Text>  
                    <Progress colorScheme={colorScheme}  h='8px' w={'full'}   bg='lightTema1.100' _dark={{bg:'bgMenuDark.200'}} borderRadius='8px'  value={porcent} />    
                </Stack> 
            </Stack>
        </Skeleton>
    );
}
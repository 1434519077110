import {  Flex,  Stack, Text, Grid, GridItem,  TagLeftIcon, TagLabel, Tag, Tabs, TabList, Tab, TabPanels, TabPanel} from "@chakra-ui/react"; 
import { useEffect, useRef, useState } from "react";
import LoadBg from "../../../components/backgound/LoadBg"; 
import { PageH, PageHK } from "../../../components/heading/HeadingKalyst";
import { sendFormSocket, user } from "../../../components/session/SessionKalyst"; 
import {  LuCheckCheck, LuReceipt, LuTruck, LuUsers2 } from "react-icons/lu";  
import ListResponsive, { ListaResultadoResponsive, ListPageResponsive } from "../../../components/list/ListResponsive"; 
import { LojaSelect } from "../../../layout/painel/MenuPainel"; 
import { FaArrowTrendUp, FaBarcode, FaCreditCard, FaPix } from "react-icons/fa6";
import { getFilter, setFilterFiltro, setFilterPage } from "../../../components/filter/FilterDate";
import md5 from "md5";
import { FilterKalyst } from "../../../components/filter/FIlterKalyst";
import { CardPrice } from "../../../components/card/CardValues";
import { BiMoney } from "react-icons/bi";
import { ButtonKNew } from "../../../components/button/ButtonKalyst";
import ModalPage from "../../../components/modal/ModalPage";
import BoxDialog from "../../../components/modal/BoxDialog";
// import FormReports from "./FormReports";
import FilterPainel from "../../../components/filter/FilterPainel";
// import InfoReports from "./InfoReports";
import { InputK } from "../../../components/input/InputKalyst";
import SelectK from "../../../components/select/SelectKalyst";
import { DataPickerKalyst } from "../../../components/input/DataPickerKalyst";
import { TbCurrencyReal } from "react-icons/tb";
import { viewPrice } from "../../../components/Function";
var session = null;
export function PageAffiliates(props){  
    const [open,setOpen]    = useState(false); 
    const [dados,setDados]  = useState(null); 
    const [edit,setEdit]    = useState({}); 
    const [alert,setAlert]    = useState({}); 
    const [ViewBox,setViewBox]    = useState({}); 
  
    
    const hoje = new Date(); 
    let tempLoja = null;
    let tempFilter = null;
    let dFilter   = null
    function BuscarDados(reload){  
        session = md5(user.id+'afiliatesv2');
        dFilter = getFilter(session);
        if(LojaSelect !==tempLoja  || dFilter.up !==tempFilter ){
            tempLoja = LojaSelect
            tempFilter = dFilter.up
            reload = true; 
        }      
        sendFormSocket({campo:'pages', modulo:'affiliates',cmd:'list',form:{reload,loja:LojaSelect,filtro:dFilter},callback:(result)=>{ 
            setDados(result)
        }});
    } 
    useEffect(()=>{ 
        BuscarDados(true);
        var interV =setInterval(()=>{
            BuscarDados(false);
        },500); 
        return  ()=>{
            clearInterval(interV);
        }
    },[]);

    if(dados == null){ 
        return <LoadBg/>
    }else{ 
        if(window.location.hash!=''){
            setTimeout(()=>{
                window.location.hash ='';
               
                setOpen(true);
                setEdit(null);
                // setViewBox(
                //     <FormReports  open={open} setOpen={(el)=>{ 
                //         setOpen(el)
                //     }} edit={edit} setEdit={setEdit}/>
                // )
            })
           
        }
      
        const titles = [
            {name:'Nome',       campo:'title'       ,w:3},
            {name:'E-mail',     campo:'email'       ,w:2},
            {name:'Telefone',   campo:'telefone'    ,w:1}, 
            {name:'Vendas',     campo:'vendas'      ,w:1}, 
            {name:'Registro',   campo:'registro'    ,w:1} 
        ]; 
        const boxInfo={
            v1:{value:0,info:0},
            v2:{value:0,info:0},
            v3:{value:0,info:0},
            v4:{value:0,info:0},
        }
        try { 
            boxInfo.v1.value = dados.info.rastreio.pendente;
            boxInfo.v1.info  = (dados.info.rastreio.saldo*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2});
            
            boxInfo.v2.value = (dados.info.totalPix.pago*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2});
            boxInfo.v2.info  = (dados.info.totalPix.pendente*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2});
            
            boxInfo.v3.value = (dados.info.totalCard.pago*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2});
            boxInfo.v3.info  = (dados.info.totalCard.pendente*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2});
            
            boxInfo.v4.value = (dados.info.totalBoleto.pago*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2});
            boxInfo.v4.info  = (dados.info.totalBoleto.pendente*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2});
        } catch (error) {
            
        }
        const lista = [];
        dFilter = getFilter(session);  
        let initial = {}
        try {
            Object.keys(dFilter.filtro).forEach((key)=>{
                initial[key]=dFilter.filtro[key].value;
            })
        } catch (error) {
            
        } 
        if(dados.affiliates.length>0){    
            dados.affiliates.forEach((cli)=>{  
                const data = new Date(cli.criacao);  
                lista.push({
                    id:cli.id,
                    title       :    cli.nome, 
                    email       :    cli.email, 
                    telefone    :    cli.telefone, 
                    vendas      :    <Tag size={'sm'}  variant='subtle' colorScheme={cli.vendas>0?'green':'gray'}>
                    <TagLeftIcon boxSize='12px' as={TbCurrencyReal} />
                        <TagLabel>{viewPrice(cli.vendas)}</TagLabel>
                    </Tag>, 
                    // status      :   (
                    //     <Tag size={'sm'}  variant='subtle' colorScheme={typeof status[cli.status] !=='undefined'?status[cli.status].color:'gray'}> 
                    //         <TagLabel>{ typeof status[cli.status] !=='undefined'?status[cli.status].name:cli.status}</TagLabel>
                    //     </Tag>
                    // ), 
                    // nome        :   <Text color='textLight.100' _dark={{color:'textDark.100'}} >{(typeof cli.customer.name == 'undefined')?'Desconhecido':cli.customer.name}</Text>, 
                    // adq         :   (
                        
                    // ),
                    // fisico      :   (cli.traceable?
                    //     <Tag size={'sm'}  variant='subtle' colorScheme={(cli.rastreio==null||cli.rastreio==''?'orange':'green')}>
                    //         <TagLeftIcon boxSize='12px' as={(cli.rastreio==null||cli.rastreio==''?LuTruck:LuCheckCheck)} />
                    //         <TagLabel>SIM</TagLabel>
                    //     </Tag>
                    //     :
                    //     <Tag size={'sm'}  variant='subtle' colorScheme={'green'}>
                    //         <TagLeftIcon boxSize='12px' as={LuCheckCheck} />
                    //         <TagLabel>Não</TagLabel>
                    //     </Tag>
                    // ),
                    // preco       :   cli.valor!=cli.valorP && cli.status=='paid'?''+cli.valorP.toLocaleString('pt-BR',{minimumFractionDigits:2}):'R$ '+(cli.valor*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2}),
                    // pagamento   :    
                    //     <Tag size={'sm'}  variant='subtle' colorScheme={cli.dataP!=null?'green':'gray'}> 
                    //         <TagLabel>{cli.dataP!=null?new Date(dataP.getTime()-(data.getTimezoneOffset() * 60000)).toLocaleDateString('pt-BR', {  month: '2-digit', day: '2-digit',hour:'2-digit',minute:'2-digit'}): (cli.method=='credit'?"Crédito" :'NÃO PAGO')}</TagLabel>
                    //     </Tag>,
                    registro    :    new Date(data.getTime()-(data.getTimezoneOffset() * 60000)).toLocaleDateString('pt-BR', {  month: '2-digit', day: '2-digit',hour:'2-digit',minute:'2-digit'})
                }) 
            }) 
        }   
        let fastV='';
        try { fastV = dFilter.filtro.fast.value;  } catch (error) {  };
        //filter
        const viewFilter = (
            <Stack gap={'0px'}> 
                <Tabs variant='soft-rounded' colorScheme='green' size={'sm'}   pt={'0px'} px={'16px'}  pb='0'>
                    <TabList>
                        <Tab fontSize={'10px'}>Nome</Tab> 
                        <Tab fontSize={'10px'}>E-mail</Tab>
                        <Tab fontSize={'10px'}>Telefone</Tab>
                    </TabList>
                    <TabPanels px={'0'}  pb='0'>
                        <TabPanel  px={'0'} pb='0' >
                            <InputK  focus={true}  type={'text'} label="Afiliado Nome" size='sm'  name="LIKE_AND-nome" placeholder={"Nome completo "}   autoComplete={false} initial={initial}/>  
                        </TabPanel> 
                        <TabPanel  px={'0'} pb='0px'>
                            <InputK  focus={false}  type={'email'} label="Afiliado E-mail" size='sm'  name="LIKE_AND-email" placeholder={"email válido"}   autoComplete={false} initial={initial}/>
                        </TabPanel>
                        <TabPanel  px={'0'} pb='0px'>
                            <InputK  focus={false}  type={'phone'} label="Afiliado Telefone" size='sm'  name="LIKE_AND-telefone" placeholder={"ddd + número"}   autoComplete={false} initial={initial}/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
                <DataPickerKalyst  name="DATA_PERIODO-criacao" max='hoje'  initial={initial}/>
                 
            </Stack> 
        )  
        var tempReload = null;
        return  ( 
            <Stack   w='full' gap={{base:'16px',md:'32px'}}  align={'flex-start'} justify='flex-start' overflowX='hidden'   minH={{base:'calc(100vh - 168px)',md:'calc(100vh - 152px)'}} pb='100px'  margin = '0 auto'  >
                {/* <Grid
                    w={'full'}  
                    templateColumns={{base:'repeat(4, 1fr)',sm:'repeat(8, 1fr)',md:'repeat(4, 1fr)',lg:'repeat(8, 1fr)'}}
                    gap={'16px'}
                    py='16px'
                >
                    <GridItem colSpan={2}  > 
                        <CardPrice icon={BiMoney} title='Sem Rastreio'  
                        value={boxInfo.v1.value}  
                        sigla='N°'
                        info={<Text color={'orange.500'} fontWeight='bold'>{boxInfo.v1.info}</Text>}
                        infoM={'Pedidos não Pago'}
                    />
                    </GridItem> 
                    <GridItem colSpan={2}  >
                        <CardPrice icon={FaPix} title='Vendas Pix'  
                        value={boxInfo.v2.value}   
                        info={<Text color={'orange.500'} fontWeight='bold'>R$ {boxInfo.v2.info}</Text>}
                        infoM={'Pedidos PIX não Pago'}
                    />
                    </GridItem> 
                    <GridItem colSpan={2}  >
                        <CardPrice icon={FaCreditCard} title='Vendas Cartão' value={boxInfo.v3.value} 
                         info={<Text color={'orange.500'} fontWeight='bold'>R$ {boxInfo.v3.info}</Text>}
                         infoM={'Pedidos Cartão Recusado'}/>
                    </GridItem> 
                    <GridItem colSpan={2}  >
                        <CardPrice icon={FaBarcode} title='Vendas Boleto' value={boxInfo.v4.value}
                        info={<Text color={'orange.500'} fontWeight='bold'>R$ {boxInfo.v4.info}</Text>}
                        infoM={'Pedidos Boleto Recusado'}/>
                    </GridItem> 
                </Grid> */}
                <PageHK>Afiliados</PageHK>  
                <Stack gap='0px' w={'full'} >   
                    <FilterPainel dFilter={dFilter} session={session}  
                        onChange={()=>{
                            clearTimeout(tempReload);
                            tempReload = setTimeout(()=>{ 
                                tempLoja = null;
                                BuscarDados(true); 
                            },1000) 
                        }} 
                        ordem={[
                            {id:'id_desc'       ,name:'Mais Recentes'},
                            {id:'id_asc'        ,name:'Menos Recentes'},
                            {id:'nome_desc'   ,name:'Nome [Z-A]'},
                            {id:'nome_asc'    ,name:'Nome [A-Z]'}, 
                            {id:'email_desc'   ,name:'E-mail [Z-A]'},
                            {id:'email_asc'    ,name:'E-mail [A-Z]'}, 
                            {id:'vendas_desc'    ,name:'Maior Valor'},
                            {id:'vendas_asc'     ,name:'Menor Valor'}, 
                        ]}
                        fast={['nome','email','vendas']}
                        viewFilter={viewFilter}
                    /> 
                    <ListaResultadoResponsive session={session}  page={dados.page}/>
                    <ListResponsive titles={titles} dados={lista} cmd={
                        [
                            { icon:<LuReceipt />,name:'Editar', click:(d)=>{  
                                setEdit(d.id);
                                // setViewBox(<InfoReports open={open} setOpen={(el)=>{ 
                                //     setOpen(el)
                                // }} edit={d.id} setEdit={setEdit}/>)  
                                setOpen(true); 
                            }}  
                        ]
                    } /> 
                    <ListPageResponsive onChange={()=>{ 
                        tempLoja = null;
                        BuscarDados(true);
                    }} session={session}  page={dados.page} />
                </Stack>
                
             
                <ModalPage open={open} setOpen={setOpen}>
                    {ViewBox}
                </ModalPage>
                <BoxDialog param={alert}  />
            </Stack> 
        )
    }
    
}
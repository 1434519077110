let GFilter = {}; 
const {user}  =require("../session/SessionKalyst");
export const setSession = function(send){   
    // localStorage.setItem(web.session,send.token); 
    // web.token       =   send.token;  
    // user            =   send.dados;  
    // if(user.sysDoc == null){
    //     fase = 1; 
    // }else if(user.sysDoc == 'S'){ 
    //     if(user.stores>0){
    //         fase = 3;
    //     }else{
    //         fase = 2;
    //     } 
    // }  
}  
export function setFilterFiltro(session,page){  
    var temp = getFilter(session);
    temp.up = new Date().getTime();
    temp.filtro = page;   
    localStorage.setItem(session,JSON.stringify(temp));  
}
export function setFilterPage(session,page){  
    var temp = getFilter(session);
    temp.up = new Date().getTime();
    temp.page = page;   
    localStorage.setItem(session,JSON.stringify(temp));  
} 
export function getFilter(session){ 
    const padrao = {
        up :0,
        filtro:{},
        page:{ 
            n:10,
            p:0,
            t:'id',
            o:'desc'
        }
    } 
    let temp    =   localStorage.getItem(session);  
    try {
        temp = temp!==null?JSON.parse(temp):padrao; 
    } catch (error) {
        // console.log(error.message)
        temp = padrao;
    } 
    return temp;
}
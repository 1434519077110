import {Stack, Text,Icon} from "@chakra-ui/react"; 
import {useEffect, useState } from "react";
import LoadBg from "../../../components/backgound/LoadBg"; 
import {PageHK} from "../../../components/heading/HeadingKalyst";
import {sendFormSocket } from "../../../components/session/SessionAdmin"; 
import {LuPen, LuTrash } from "react-icons/lu";  
import { ButtonKNew } from "../../../components/button/ButtonKalyst";
import ModalPage from "../../../components/modal/ModalPage"; 
import { LojaSelect } from "../../../layout/painel/MenuPainel";
import BoxDialog from "../../../components/modal/BoxDialog";
import ListBox from "../../../components/list/ListBox";
import FormAdminAcquirer from "./FormPlans";


export function PageAdminPlans(props){  
    const [open,setOpen]    = useState(false); 
    const [dados,setDados]  = useState(null); 
    const [edit,setEdit]    = useState({}); 
    const [alert,setAlert]    = useState({}); 
  
    const hoje = new Date(); 
    let tempLoja = null;
    function BuscarDados(reload){ 
        if(LojaSelect !==tempLoja){
            tempLoja = LojaSelect
            reload = true; 
        } 
        sendFormSocket({campo:'pages', modulo:'plans',cmd:'list',form:{reload,loja:LojaSelect},callback:(result)=>{   
            setDados(result)
        }});
    } 
    useEffect(()=>{ 
        BuscarDados(true);
        var interV =setInterval(()=>{
            BuscarDados(false);
        },500);
        
        return  ()=>{
            clearInterval(interV);
        }
    },[]);

    if(dados == null){ 
        return <LoadBg/>
    }else{ 
        const titles = [ 
            {name:'Lucros',             campo:'lucro'        ,w:2}, 
            {name:'Processado',         campo:'total'        ,w:2}  
        ];
        const lista = [] 
        let dadosPlan = {};
        if(dados.plans.length>0){
            dados.plans.forEach((cli)=>{
                dadosPlan[cli.id] = cli;
                lista.push({
                    id          :   cli.id,
                    title       :   cli.nome, 
                    lucro       :   0, 
                    total       :   0, 
                    sub         :   <>
                        <Text fontSize={{base:'10px',md:'12px'}} color={'textAdmin.100'}>Users</Text>
                        <Text fontSize={{base:'10px',md:'12px'}} color={'textAdmin.300'}>{cli.users}</Text>
                    </> 
                })
            }) 
        }  
        return  ( 
            <Stack zIndex={3} w='full' gap={{base:'16px',md:'32px'}}  align={'flex-start'} justify='flex-start'   minH={'100vh'} pb='100px'  margin = '0 auto'  >
                <PageHK>Planos</PageHK> 
                <ListBox titles={titles} dados={lista} cmd={[
                    { icon:<LuPen/>,name:'Editar', click:(d)=>{  
                        setEdit(d.id);
                        setOpen(true); 
                    }}, 
                    { icon:<LuTrash/>,name:'Deletar', click:(d)=>{  
                        setAlert({load:true});
                        setTimeout(()=>{ 
                            setAlert({
                                fechar:true,
                                icon   : <Icon as={LuTrash} h={{base:'50px'}} w={{base:'50px'}} color='red.300' />, 
                                titulo:'Deletar Plano',
                                size:'md',
                                mensagem:'Caso tenha clientes vinculados a api, todos irão ficar sem Plano, deseja realmente deletar o Plano <b style="color:red">'+d.title+'</b>?', 
                                botao:[
                                    {
                                        nome:'SIM', 
                                        cmd:()=>{
                                            setAlert({load:true});
                                            sendFormSocket({ modulo:'plans',cmd:'deletePlan',form:{id:d.id},callback:(result)=>{ 
                                                setTimeout(()=>{
                                                    setAlert({exit:true});
                                                    BuscarDados(false); 
                                                },500)
                                            }});
                                        }
                                    },
                                    {
                                        nome:'NÃO',
                                        variant:'ghost',
                                    }
                                ]
                            });
                        }) 
                    }}
                ]} /> 
                <ButtonKNew onClick={()=>{
                    setOpen(true);
                    setEdit(null);
                }} variant={'admin'}>Adicionar Adquirente</ButtonKNew>
               <ModalPage open={open} setOpen={setOpen}>
                    <FormAdminAcquirer   open={open} setOpen={(el)=>{ 
                        setOpen(el)
                    }} edit={edit} setEdit={setEdit}   plans={dadosPlan}/>
                </ModalPage>
                <BoxDialog param={alert}  />
            </Stack> 
        )
    }
    
}
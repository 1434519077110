import { useLocation } from "react-router-dom";
import LayoutPainel from "./painel/LayoutPainel"
import LayoutAdmin from "./admin/LayoutAdmin"
import '../theme/index.css';    
export default  function Layout(props){ 
    var page = null; 
    const location = useLocation();  
    var pag=[];
    (location.pathname.toUpperCase()+'/').replace('//','/').split('/').forEach((path)=>{
        if(path!==''){
            pag.push(path);
        }
    });    
    switch (typeof pag[0] === 'undefined'?'':pag[0].toLowerCase()) {   
        case 'admin':
            page= <LayoutAdmin content={props.children} page={pag} location={location} />
        break;
        default:
            page= <LayoutPainel content={props.children} page={pag} location={location} />
        break; 
    } 
    return      page;
} 
import { Stack } from "@chakra-ui/react";
import MenuAdmin from "./MenuAdmin"; 

export default function TemaAdmin({children,page}){
    return <Stack w={'100%'}  minH={'100vh'}>
        
        <MenuAdmin page={page}/>
        <Stack zIndex={10}  ml={{base:'0px',md:'280px'}}   mt={{base:'136px',md:'88px'}} p={{base:'16px',md:'32px'}}     gap={0} >
          {children}
        </Stack> 
    </Stack>
}
import { parseValor, validateCNPJ,validateCPF } from '../Function';
import StringMask from './string-mask';
export function getMaskString({ value = '', type = '', step = '0', max = '' ,sigla=''}) {
    var r = {
        result: '',
        message: '',
        valid: false
    };
    var tR = null
    switch (type.toUpperCase()) {
        case 'NAME':
            r.result = value.replace(/[^a-z0-9 ]/gi, '');
            r.valid = true;
        break;
        case 'SITE':
            r.result = value.replace(/[^a-z0-9_.-:\/]/gi, '');
            r.valid = true;
        break;
        case 'LINK':
            r.result = value.replace(new RegExp('https://', "ig"),''); 
            r.valid = true;
        break;
        case 'PHONE':
            // var result = StringMask.process('some value string', 'some mask', optionsObject);
            tR = new StringMask('(00) 0000-0000').apply(value.replace(/[- ().]/g, ''));
            r.valid = new StringMask('(00) 0000-0000').validate(value.replace(/[- ().]/g, ''))
            if (value.replace(/[- ().]/g, '').length >= 11) {
                r.valid = new StringMask('(00) 0.0000-0000').validate(value.replace(/[- ().]/g, ''))
                tR = new StringMask('(00) 0.0000-0000').apply(value.replace(/[- ().]/g, ''));
            }

            if (tR.length > 0 && (tR[tR.length - 1] == '.' || tR[tR.length - 1] == '-' || tR[tR.length - 1] == ' ' || tR[tR.length - 1] == ')' || tR[tR.length - 1] == '(')) {

                if (tR[tR.length - 1] == ' ') {
                    r.result = tR.slice(0, -2)
                } else {
                    r.result = tR.slice(0, -1)
                }

            } else {
                r.result = tR;
            }


            if (!r.valid) {
                r.message = 'Telefone Inválido';
            }
            break;
        case 'CEP':
            // var result = StringMask.process('some value string', 'some mask', optionsObject);
            tR = new StringMask('00000-000').apply(value.replace(/[.,-]/g, ''));
            if (tR.length > 0 && (tR[tR.length - 1] == '.' || tR[tR.length - 1] == '-')) {
                r.result = tR.slice(0, -1)
            } else {
                r.result = tR;
            }
            r.valid = new StringMask('00000-00').validate(value.replace(/[.,-]/g, ''))
            if (!r.valid) {
                r.message = 'CEP Inválido';
            }
        break;
        case 'AGENCIA': 
            r.result = new StringMask('00000000000').apply(value.replace(/[/.,-]/g, '')); 
            r.valid = true; 
        break;
        case 'CPF':
            // var result = StringMask.process('some value string', 'some mask', optionsObject);
            tR = new StringMask('000.000.000-00').apply(value.replace(/[.,-]/g, ''));
            if (tR.length > 0 && (tR[tR.length - 1] == '.' || tR[tR.length - 1] == '-')) {
                r.result = tR.slice(0, -1)
            } else {
                r.result = tR;
            }

            r.valid = new StringMask('000.000.000-00').validate(value.replace(/[.,-]/g, ''))
            if (!r.valid) {
                r.message = 'CPF Inválido';
            }else{
                r.valid = validateCPF(value.replace(/[.,-]/g, ''))
                r.message = 'CPF Inválido'; 
            }
        break;
       
        case 'CNPJ':
            // var result = StringMask.process('some value string', 'some mask', optionsObject);
            tR = new StringMask('00.000.000/0000-00').apply(value.replace(/[/.,-]/g, ''));
            if (tR.length > 0 && (tR[tR.length - 1] == '.' || tR[tR.length - 1] == '-' || tR[tR.length - 1] == '/')) {
                r.result = tR.slice(0, -1)
            } else {
                r.result = tR;
            }

            r.valid = new StringMask('00.000.000/0000-00').validate(value.replace(/[.,-/]/g, ''))
            if (!r.valid) {
                r.message = 'CNPJ incompleto';
            }else{
                r.valid = validateCNPJ(value.replace(/[.,-/]/g, ''))
                r.message = 'CNPJ Inválido'; 
            }
        break;
        case 'CPFCNPJ':
            // var result = StringMask.process('some value string', 'some mask', optionsObject);
            if(value.length<=14){
                tR = new StringMask('000.000.000-00').apply(value.replace(/[.,-]/g, ''));
                if (tR.length > 0 && (tR[tR.length - 1] == '.' || tR[tR.length - 1] == '-')) {
                    r.result = tR.slice(0, -1)
                } else {
                    r.result = tR;
                }
    
                r.valid = new StringMask('000.000.000-00').validate(value.replace(/[.,-]/g, ''))
                if (!r.valid) {
                    r.message = 'CPF Inválido';
                }else{
                    r.valid = validateCPF(value.replace(/[.,-]/g, ''))
                    r.message = 'CPF Inválido'; 
                } 
            }else{ 
                // var result = StringMask.process('some value string', 'some mask', optionsObject);
                tR = new StringMask('00.000.000/0000-00').apply(value.replace(/[/.,-]/g, ''));
                if (tR.length > 0 && (tR[tR.length - 1] == '.' || tR[tR.length - 1] == '-' || tR[tR.length - 1] == '/')) {
                    r.result = tR.slice(0, -1)
                } else {
                    r.result = tR;
                }

                r.valid = new StringMask('00.000.000/0000-00').validate(value.replace(/[.,-/]/g, ''))
                if (!r.valid) {
                    r.message = 'CNPJ incompleto';
                }else{
                    r.valid = validateCNPJ(value.replace(/[.,-/]/g, ''))
                    r.message = 'CNPJ Inválido'; 
                }
            }
          
        break;
        case 'DECIMAL': 
            if(parseInt(value.toString().replace(/[^0-9]/gi, ''))>0){  
                value =value.replace(sigla+' ','');
                var formatter = new StringMask('#.##'+step , { reverse: true });
                r.result = (sigla==''?'':sigla+' ')+formatter.apply(parseFloat(value.toString().replace(/[^0-9]/gi, '')));  
                r.valid = true;
              
            }else{
                r.result = ''
                r.valid = false;
            }
        break;
        case 'PORCENTAGEM': 
            if(parseInt(value.toString().replace(/[^0-9]/gi, ''))>0){  
                if(value.length>1 && !value.includes('%')){ 
                    value= value.slice(0,-1)
                }

                var formatter = new StringMask('#.##'+step+'%' , { reverse: true });
                // console.log(value.replace(/[.!]/gi, '').replace(/[,]/gi, '.'));
                r.result = formatter.apply(parseFloat(value.toString().replace(/[^0-9]/gi, '')));  
                var temp = parseFloat(r.result.replace(/[.!]/gi, '').replace(/[,]/gi, '.')); 
                if (max != '' && temp >= parseFloat(max)) {
                    value = parseFloat(max).toFixed(step.length-2); 
                    r.result = formatter.apply(parseFloat(value.toString().replace(/[^0-9]/gi, '')));  
                } 
               

                r.valid = true; 
            }else{
                r.result = ''
                r.valid = false;
            }
        break;
        case 'NUMBER':
            var formatter = new StringMask('#.##0', {reverse: true});
            value = value.toString();
            if(typeof value!=='undefined' && value !=='' && value.toString().split('')[value.length-1]==','){ 
                value= value.slice(0,-2)
            }
             
            // const { result, valid } = new StringMask('###.###,##', { reverse: true }).process(parseInt(value.replace('.','')).toString());

            r.result=formatter.apply(value.replace(',0','').replace(/[^0-9]/gi, ''));
            // r.result = (result === '') ? '' : result;

            // r.valid = valid;
            if (max != '' && parseInt(value.replace(/[^0-9]/gi, '')) >= parseInt(max)) {
                r.result = max;
                r.valid = true;
            }
            if( r.result!=''){
                r.result+=',0';
            }
           
        break;
        case 'INTEGER':

            const { result, valid } = new StringMask('#0', { reverse: true }).process(parseInt(value).toString());

            r.result = (result === '') ? '' : result;

            r.valid = valid;
            if (max != '' && parseInt(value) >= parseInt(max)) {
                r.result = max;
                r.valid = true;
            }
        break;
        case 'USER':
            r.result = value.replace(/[^a-z0-9_]/gi, '');
            r.valid = true;
            break;
        case 'EMAIL':
            // a-zA-Z0-9_-@.
        r.result = value.replace(/[^a-z0-9_@.-]/gi, '');
        r.valid = true;
        break;
        default:
            if (value === ' ') {
                r.result = '';
                r.valid = true;
            } else {
                r.result = value;
                r.valid = true;
            }

            break;
    }
    return r;
}
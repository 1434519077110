import {  Flex, Image, Stack, Text,Icon,colorMode, toggleColorMode, useColorMode, Input, Grid, GridItem, SimpleGrid, Progress, Tag} from "@chakra-ui/react"; 
import { useEffect, useState } from "react";
import LoadBg from "../../../components/backgound/LoadBg"; 
import { PageHK, SubHK } from "../../../components/heading/HeadingKalyst";
import { sendFormSocket } from "../../../components/session/SessionAdmin"; 
import { LuBox, LuLineChart, LuPen, LuTrash, LuUser2, LuUsers2 } from "react-icons/lu"; 
import ListResponsive from "../../../components/list/ListResponsive";
import { ButtonKNew } from "../../../components/button/ButtonKalyst";
import ModalPage from "../../../components/modal/ModalPage"; 
import { LojaSelect } from "../../../layout/painel/MenuPainel";
import BoxDialog from "../../../components/modal/BoxDialog";
import ListBox from "../../../components/list/ListBox";
import FormAdminAcquirer from "./FormAdminBass";


export function PageAdminBass(props){  
    const [open,setOpen]    = useState(false); 
    const [dados,setDados]  = useState(null); 
    const [edit,setEdit]    = useState({}); 
    const [alert,setAlert]    = useState({}); 
    const hoje = new Date(); 
    let tempLoja = null;
    function BuscarDados(reload){ 
        if(LojaSelect !==tempLoja){
            tempLoja = LojaSelect
            reload = true; 
        } 
        sendFormSocket({campo:'pages', modulo:'integration',cmd:'bass',form:{reload,loja:LojaSelect},callback:(result)=>{    
            setDados(result)
        }});
    } 
    useEffect(()=>{ 
        BuscarDados(true);
        var interV =setInterval(()=>{
            BuscarDados(false);
        },500);
        
        return  ()=>{
            clearInterval(interV);
        }
    },[]);

    if(dados == null){ 
        return <LoadBg/>
    }else{ 
        const titles = [
            {name:'Documento',          campo:'documento'   ,w:2}, 
            {name:'Vencimento Mês',    campo:'dia'              ,w:2},   
            {name:'Pix Mensal',       campo:'nPix'        ,w:2} 
        ];
        const lista = [] 
        if(dados.bass.length>0){
            dados.bass.forEach((cli)=>{ 
                lista.push({
                    id          :   cli.id,
                    title       :   cli.nome, 
                  
                    nPix        :   cli.nPix,  
                    dia         :   cli.saqueDia,    
                    status      :   <Tag size={'sm'} minW={'max-content'} colorScheme={cli.status.c}>{cli.status.n}</Tag>, 
                    sub         :   <>
                        <Text fontSize={{base:'10px',md:'12px'}} color={'textAdmin.100'}>Total Pago</Text>
                        <Text fontSize={{base:'10px',md:'12px'}} color={'textAdmin.300'}>R$ 0,00</Text>
                    </>,
                    
                    // criacao     :   new Date(cli.criacao).toLocaleDateString('pt-BR', {  month: '2-digit', day: '2-digit',hour:'2-digit',minute:'2-digit'})
                })
            }) 
        }  
        return  ( 
            <Stack zIndex={3} w='full' gap={{base:'16px',md:'32px'}}  align={'flex-start'} justify='flex-start'   minH={'100vh'} pb='100px'  margin = '0 auto'  >
                <PageHK>Gestão BasS</PageHK> 
                <ListBox titles={titles} dados={lista} cmd={[
                    { icon:<LuPen/>,name:'Editar', click:(d)=>{  
                        setEdit(d.id);
                        setOpen(true); 
                    }}, 
                    { icon:<LuTrash/>,name:'Deletar', click:(d)=>{  
                        setAlert({load:true});
                        setTimeout(()=>{ 
                            setAlert({
                            fechar:true,
                            icon   : <Icon as={LuTrash} h={{base:'50px'}} w={{base:'50px'}} color='red.300' />, 
                            titulo:'Deletar Bass',
                            size:'md',
                            mensagem:'Deseja realmente deletar a conta BaaS <b style="color:red">'+d.title+'</b>? ', 
                            botao:[
                                {
                                    nome:'SIM', 
                                    variant:'admin',
                                    cmd:()=>{
                                        setAlert({load:true});
                                        sendFormSocket({ modulo:'acquirer',cmd:'deleteAdq',form:{id:d.id},callback:(result)=>{ 
                                            setTimeout(()=>{
                                                setAlert({exit:true});
                                                BuscarDados(false); 
                                            },500)
                                        }});
                                    }
                                },
                                {
                                    nome:'NÃO',
                                    variant:'ghost',
                                }
                            ]
                            });
                        }) 
                    }}
                ]} /> 
                <ButtonKNew onClick={()=>{
                    setOpen(true);
                    setEdit(null);
                }} variant={'admin'}>Adicionar Bass</ButtonKNew>
               <ModalPage open={open} setOpen={setOpen}>
                    <FormAdminAcquirer   open={open} setOpen={(el)=>{ 
                        setOpen(el)
                    }} edit={edit} setEdit={setEdit} subs={dados.acquirers} regsubs={dados.bass}/>
                </ModalPage>
                <BoxDialog param={alert}  />
            </Stack> 
        )
    }
    
}
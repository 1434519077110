import { Stack, Text } from "@chakra-ui/react";
import MenuPainel from "./MenuPainel";

export default function TemaPainel({children,page}){
    return <Stack w={'100%'} bg={'bgMenuLight.200'} _dark={{bg: 'bgMenuDark.200'}} minH={'100vh'}>
        <MenuPainel page={page}/>
        <Stack zIndex={10}  ml={{base:'0px',md:'280px'}}   mt={{base:'136px',md:'88px'}} p={{base:'16px',md:'32px'}}  bg='rgba(255,255,255,0.7)' _dark={{bg:'rgba(40,40,40,0.8)'}}  gap={0} >
          {children}
        </Stack> 
    </Stack>
}
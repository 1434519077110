import { Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Stack, Text } from "@chakra-ui/react";

export default function InfoBoxList({ children=null,info=null,variant=null}){
    var value = null;
    return  <Popover placement='bottom-start' closeOnBlur={false}  closeOnEsc={false}  trigger='hover' >
    <PopoverTrigger>
        <Stack     w='full'    align={'flex-start'} justify='center' borderRadius={'lg'} cursor='pointer'    >
             {children} 
        </Stack> 
    </PopoverTrigger>
    <PopoverContent boxShadow={'xl'}  bg='bgMenuLight.100' _dark={{ bg:'bgMenuDark.100'}}  >
        <PopoverArrow bg='transparent'   /> 
        <PopoverBody >
            {info} 
        </PopoverBody> 
    </PopoverContent>
</Popover> 
}
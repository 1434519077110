import { Flex, Heading, Icon, Text} from "@chakra-ui/react";

export function HK1({children,...others}){
    return <Heading data-test="h1" fontSize={{base:'56px',md:'66px',lg:'76px'}} _dark={{color:'textDark.100'}}  noOfLines={1} {...others}>{children}</Heading>
}
export function HK2({children,...others}){
    return <Heading data-test="h2" fontSize={{base:'41px',md:'51px',lg:'61px'}} _dark={{color:'textDark.100'}}  noOfLines={1} {...others}>{children}</Heading>
}
export function HK3({children,...others}){
    return <Heading data-test="h3" fontSize={{base:'29px',md:'39px',lg:'49px'}} _dark={{color:'textDark.100'}}  noOfLines={1} {...others}>{children}</Heading>
} 
export function HK4({children,...others}){
    return <Heading data-test="h4" fontSize={{base:'19px',md:'29px',lg:'39px'}}  _dark={{color:'textDark.100'}}  noOfLines={1} {...others}>{children}</Heading>
}
export function HK5({children,...others}){
    return <Heading data-test="h5"  fontSize={{base:'14px',md:'21px',lg:'31px'}}  _dark={{color:'textDark.100'}} noOfLines={1} {...others}>{children}</Heading>
}
export function PageHK({children,...others}){
    return <Flex w={'100%'}   borderBottom={'2px solid'} borderColor='darkTema1.400'  _dark={{borderColor:'lightTema1.400'}} h={{base:'32px',md:'42px',lg:'52px'}} >
        <Heading  data-test="h4" fontSize={{base:'14px',md:'21px',lg:'31px'}} color='darkTema1.200' _dark={{color:'lightTema1.100'}} noOfLines={1} {...others}>{children}</Heading>
    </Flex>
}
export function PageHKS({children,...others}){
    return <Flex w={'100%'}     borderColor='darkTema1.400'  _dark={{borderColor:'lightTema1.400'}} h={{base:'32px',md:'42px',lg:'52px'}} >
        <Heading  data-test="h4" fontSize={{base:'14px',md:'21px',lg:'31px'}} color='darkTema1.200' _dark={{color:'lightTema1.100'}} noOfLines={1} {...others}>{children}</Heading>
    </Flex>
}
export function SubHK({icon,title}){
    return <Flex w={'full'} h='24px' align='center' gap='8px'>
    {icon!==null?<Icon as={icon} fontSize={'20px'} color='textLight.100' _dark={{color:'textDark.100'}} />:null}
        <Text fontSize={'13px'} fontWeight='900'>{title}</Text>
    </Flex>
}
export function SubTHK({children,...others}){
    return <Flex w={'100%'}   borderTop={'2px solid'} borderColor='darkTema1.400'  _dark={{borderColor:'lightTema1.400'}} h={{base:'32px',md:'42px',lg:'52px'}} >
        <Heading  data-test="h4" textAlign={"right"} w={'100%'} py={'10px'} fontSize={{base:'14px',md:'16px',lg:'18px'}} color='darkTema1.200' _dark={{color:'lightTema1.100'}} noOfLines={1} {...others}>{children}</Heading>
    </Flex>
}
export function SubBHK({children,...others}){
    return <Flex w={'100%'}   borderBottom={'2px solid'} borderColor='darkTema1.400'  _dark={{borderColor:'lightTema1.400'}} h={{base:'34px',lg:'44px'}} mb='8px'  >
        <Heading    data-test="h4" textAlign={"right"} w={'100%'} py={'10px'} fontSize={{base:'14px',md:'16px',lg:'18px'}} color='darkTema1.200' _dark={{color:'lightTema1.100'}}   {...others}>{children}</Heading>
    </Flex>
}
export function SubHK4({children,...others}){
    return <Flex w={'100%'}    borderColor='darkTema1.400'  _dark={{borderColor:'lightTema1.400'}} h={{base:'24px',lg:'34px'}} mb='6px'   {...others} >
        <Heading    data-test="h4" textAlign={"right"} w={'100%'} py={'10px'} fontSize={{base:'14px',md:'16px',lg:'18px'}} color='darkTema1.200' _dark={{color:'lightTema1.100'}}   {...others}>{children}</Heading>
    </Flex>
}
export function SubHK5({children,...others}){
    return <Flex w={'100%'}    borderColor='darkTema1.400'  _dark={{borderColor:'lightTema1.400'}} h={{base:'24px',lg:'34px'}} mb='6px'  {...others} >
        <Heading    data-test="h4" textAlign={"right"} w={'100%'} py={'10px'} fontSize={{base:'12px',md:'14px',lg:'16px'}} color='darkTema1.200' _dark={{color:'lightTema1.100'}}   {...others}>{children}</Heading>
    </Flex>
}
export function SubHK6({children,...others}){
    return <Flex w={'100%'}    borderColor='darkTema1.400'  _dark={{borderColor:'lightTema1.200'}} h={{base:'24px',lg:'34px'}} mb='6px'  {...others} >
        <Heading    data-test="h4" textAlign={"right"} w={'100%'} py={'10px'} fontSize={{base:'12px',md:'13px',lg:'14px'}} color='darkTema1.200' _dark={{color:'lightTema1.200'}}   {...others}>{children}</Heading>
    </Flex>
}
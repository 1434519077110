import {  Flex,  Stack, Text, Grid, GridItem,  TagLeftIcon, TagLabel, Tag, Tabs, TabList, Tab, TabPanel, TabPanels} from "@chakra-ui/react"; 
import { useEffect, useState } from "react";
import LoadBg from "../../../components/backgound/LoadBg"; 
import { PageH, PageHK } from "../../../components/heading/HeadingKalyst";
import { sendFormSocket, user } from "../../../components/session/SessionKalyst"; 
import {  LuCheckCheck, LuReceipt, LuTruck, LuUsers2 } from "react-icons/lu";  
import ListResponsive, { ListaResultadoResponsive, ListPageResponsive } from "../../../components/list/ListResponsive"; 
import { LojaSelect } from "../../../layout/painel/MenuPainel"; 
import { FaArrowTrendUp, FaBarcode, FaCartShopping, FaCreditCard, FaPix, FaScaleBalanced } from "react-icons/fa6";
import { getFilter, setFilterFiltro, setFilterPage } from "../../../components/filter/FilterDate";
import md5 from "md5";
import { FilterKalyst } from "../../../components/filter/FIlterKalyst";
import { CardInfoText, CardPrice } from "../../../components/card/CardValues";
import { BiMoney, BiMoneyWithdraw } from "react-icons/bi";
import { ButtonKNew } from "../../../components/button/ButtonKalyst";
import ModalPage from "../../../components/modal/ModalPage";
import BoxDialog from "../../../components/modal/BoxDialog"; 
import { TempoCronometro, viewPrice } from "../../../components/Function";
import { FaBalanceScale, FaCheckDouble } from "react-icons/fa"; 
import FormWithdraw from "./FormWithdraw"; 
import { InputK } from "../../../components/input/InputKalyst";
import SelectK from "../../../components/select/SelectKalyst";
import { DataPickerKalyst } from "../../../components/input/DataPickerKalyst";
import FilterPainel from "../../../components/filter/FilterPainel";
import InfoWithdraw from "./InfoWithdraw";
import InfoBoxList from "../../../components/input/InfoBoxList";

let session   = null;
export function PagePainelWithdraw(props){   
    const [open,setOpen]    = useState(false); 
    const [dados,setDados]  = useState(null); 
    const [edit,setEdit]    = useState({});  
    const [viewPage,setViewPage]    = useState(null);     
    const hoje = new Date(); 
    let tempLoja = null;
    let tempFilter = null;
    let dFilter   = null
    const status={
        Pago        :   {name:'Pago',       color:'green'}, 
        Rejeitado   :   {name:'Rejeitado',  color:'red'},
        Devolvido   :   {name:'Devolvido',  color:'red'},
        Cancelado   :   {name:'Cancelado',  color:'red'},
        'Proces. BasS':   {name:'Pendente',   color:'orange'}, 
        Erro:   {name:'Erro',   color:'orange'},
        Pendente    :   {name:'Pendente',   color:'orange'}, 
    }
    function BuscarDados(reload){  
        session = md5(user.id+'withdrawv2');
        dFilter = getFilter(session);
        if(LojaSelect !==tempLoja  || dFilter.up !==tempFilter ){
            tempLoja = LojaSelect
            tempFilter = dFilter.up
            reload = true; 
        }     
        sendFormSocket({campo:'pages', modulo:'withdraw',cmd:'list',form:{reload,loja:LojaSelect,filtro:dFilter},callback:(result)=>{   
                console.log(result);
            setDados(result) 
        }});
    } 
    useEffect(()=>{ 
        BuscarDados(true);
        var interV =setInterval(()=>{
            BuscarDados(false);
        },500); 
        return  ()=>{
            clearInterval(interV);
        }
    },[]);

    if(dados == null){ 
        return <LoadBg/>
    }else{ 
        if(window.location.hash!=''){
            setTimeout(()=>{
                window.location.hash ='';
                setOpen(true);
                setEdit(null);
            })
           
        } 

        const titles = [
            {name:'Pix',       campo:'nome'        ,w:2}, 
            {name:'Status',    campo:'status'      ,w:2},   
            {name:'Valor',     campo:'preco'       ,w:1},  
            {name:'Saque',      campo:'tipo'        ,w:2},  
            {name:'Registro',  campo:'registro'    ,w:1} 
        ]; 
        let tipos ={
            1:'CNPJ',
            2:'E-mail',
            3:'Telefone',
            4:'Aleatória'
        }; 
        const boxInfo={
            v1:{value:0,info:0},
            v2:{value:0,info:0},
            v3:{value:0,info:0},
            v4:{value:0,info:0},
        }
        try {  
            boxInfo.v1.value = viewPrice(dados.saldos.saquesP);  
            boxInfo.v2.value = viewPrice(dados.saldos.saldo);  
            boxInfo.v3.value = viewPrice(dados.saldos.saquesD);
            boxInfo.v3.info  = viewPrice(dados.saldos.saques); 
            boxInfo.v4.value = viewPrice(dados.saldos.vendasH);
            boxInfo.v4.info  = viewPrice(dados.saldos.vendas);
        } catch (error) {
            
        }
        const lista = [];
        dFilter = getFilter(session);  
        //caregando dados do filtro nos campos 
        let initial = {}
        try {
            Object.keys(dFilter.filtro).forEach((key)=>{
                initial[key]=dFilter.filtro[key].value;
            })
        } catch (error) {
            
        } 
        if(dados.reports.length>0){    
            dados.reports.forEach((cli)=>{ 
                const data = new Date(cli.criacao);  
                lista.push({
                    id:cli.id,
                    chave       :   cli.chave, 
                    tipo      :   (
                        <Tag size={'sm'}  variant='subtle' colorScheme={ cli.titular !==null? 'orange':'blue'}> 
                            <TagLabel>{  cli.titular !==null ?'Terceiros':'Titular'}</TagLabel>
                        </Tag>
                    ),
                    nome       :   <InfoBoxList  info={ 
                        cli.titular==null?
                        <Stack>  
                            <CardInfoText    title='Tipo'          value={(typeof tipos[cli.codtip] == 'undefined')?'Desconhecido':tipos[cli.codtip]}  /> 
                            <CardInfoText    title='Chave'         value={(typeof cli.chave == 'undefined')?'Desconhecido':cli.chave}  />  
                            <CardInfoText    title='Titular'       value={(typeof cli.nome == 'undefined')?'Desconhecido':cli.nome}  /> 
                            <CardInfoText    title='Documento'      value={(typeof cli.documento == 'undefined')?'Desconhecido':cli.documento}  />  
                        </Stack>:
                        <Stack>
                            <CardInfoText     title='Tipo'          value={(typeof tipos[cli.codtip] == 'undefined')?'Desconhecido':tipos[cli.codtip]}  /> 
                            <CardInfoText     title='Chave'         value={(typeof cli.chave == 'undefined')?'Desconhecido':cli.chave}  />  
                            <CardInfoText     title='Terceiro'      value={(typeof cli.titular.name== 'undefined')?'Desconhecido':cli.titular.name}  />  
                            <CardInfoText     title='Documento'     value={(typeof cli.titular.document == 'undefined')?'Desconhecido':cli.titular.document}  />  
                        </Stack> 
                }>
                     <Text color='textLight.100' _dark={{color:'textDark.100'}} noOfLines={1} w={'full'}  textAlign={{base:'right',md:'center'}} >
                        {cli.chave}  
                    </Text>
                </InfoBoxList>, 
                    status      :<Tag size={'sm'}  variant='subtle' colorScheme={typeof status[cli.status] !=='undefined'?status[cli.status].color:'gray'}> 
                        <TagLabel>{ typeof status[cli.status] !=='undefined'?status[cli.status].name:cli.status}</TagLabel>
                    </Tag>, 
                    preco       :   cli.valor!=cli.valorP && cli.status=='paid'?''+cli.valorP.toLocaleString('pt-BR',{minimumFractionDigits:2}):'R$ '+(cli.valor*0.01).toLocaleString('pt-BR',{minimumFractionDigits:2}),
                    espera    :  <Tag size={'sm'}   variant='subtle' colorScheme={'orange'}><TempoCronometro admin={true} time={data.getTime()} view = 'tempo'/> </Tag>,   
                    registro    :    new Date(data.getTime()-(data.getTimezoneOffset() * 60000)).toLocaleDateString('pt-BR', {  month: '2-digit', day: '2-digit',hour:'2-digit',minute:'2-digit'})  
                }) 
            }) 
        }   
        let fastV='';
        try {
            fastV = dFilter.filtro.fast.value;
        } catch (error) {
            
        }  
         //filter
         const viewFilter = (
            <Stack gap={'0px'}>  
                <DataPickerKalyst  name="DATA_PERIODO-criacao" max='hoje'  initial={initial}/>
                <Stack gap='0px' px='16px'  >
                    <SelectK label={'Status'} placeholder="Selecione" size='sm'    value={
                        Object.keys(status).length>0?Object.keys(status).map((key)=>{

                        return {value:key,name:status[key].name}
                    }):null 
                    } name='IGUAL_AND-status' initial={initial}/>
                    <InputK  focus={false}  type={'text'} label="Chave PIX" size='sm'  name="LIKE_AND-chave" placeholder={"Código ou Site"}   autoComplete={false} initial={initial}/>  
                </Stack> 
            </Stack> 
        )  
        
        var tempReload = null;
        return  ( 
            <Stack   w='full' gap={{base:'16px',md:'32px'}}  align={'flex-start'} justify='flex-start' overflowX='hidden'   minH={{base:'calc(100vh - 168px)',md:'calc(100vh - 152px)'}} pb='100px'  margin = '0 auto'  >
                <Grid
                    w={'full'}  
                    templateColumns={{base:'repeat(4, 1fr)',sm:'repeat(8, 1fr)',md:'repeat(4, 1fr)',lg:'repeat(8, 1fr)'}}
                    gap={'16px'}
                    py='16px'
                >
                    <GridItem colSpan={2}  > 
                        <CardPrice icon={BiMoney} title='Saques'  
                        value={boxInfo.v1.value}   
                        info={<Text color={'orange.500'} fontWeight='bold'>Pendente</Text>} 
                    />
                    </GridItem> 
                    <GridItem colSpan={2}  >
                        <CardPrice icon={FaCheckDouble} title='Saldo Geral'  
                        value={boxInfo.v2.value}   
                         info={<Text color={'green.500'} fontWeight='bold'>Disponível</Text>}
                    />
                    </GridItem> 
                    <GridItem colSpan={2}  >
                        <CardPrice icon={BiMoneyWithdraw} title='Saques Geral' value={boxInfo.v3.value} 
                         info={<Text color={'orange.500'} fontWeight='bold'>R$ {boxInfo.v3.info}</Text>}
                         infoM={'Saques Pago'}/>
                    </GridItem> 
                    <GridItem colSpan={2}  >
                        <CardPrice icon={FaCartShopping} title='Vendas Geral' value={boxInfo.v4.value}
                        info={<Text color={'orange.500'} fontWeight='bold'>R$ {boxInfo.v4.info}</Text>}
                        infoM={'Vendas Total'}/>
                    </GridItem> 
                </Grid>
                <PageHK>Saques</PageHK>  
                <Stack gap='0px' w={'full'} >    
                    <FilterPainel  dFilter={dFilter} session={session}  
                        onChange={()=>{
                            clearTimeout(tempReload);
                            tempReload = setTimeout(()=>{ 
                                tempLoja = null;
                                BuscarDados(true); 
                            },1000) 
                        }} 
                        ordem={[
                            {id:'id_asc'        ,   name:'Menos Recentes'},
                            {id:'id_desc'       ,   name:'Mais Recentes'},
                            {id:'valor_desc'    ,   name:'Maior Valor'},
                            {id:'valor_asc'     ,   name:'Menor Valor'},
                            {id:'user_desc'     ,   name:'Usuário de Pagamento [Z-A]'},
                            {id:'user_asc'      ,   name:'Usuário de Pagamento [A-Z]'}, 
                        ]}
                        fast={['method','status','cliente']}
                        viewFilter={viewFilter}
                    />   
                    <ListaResultadoResponsive session={session}  page={dados.page}/>
                    <ListResponsive titles={titles} dados={lista} cmd={
                        [
                            { icon:<LuReceipt />,name:'Editar', click:(d)=>{  

                                setViewPage( <InfoWithdraw  open={open} setOpen={(el)=>{ 
                                    setOpen(el)
                                }} edit={d.id} setEdit={setEdit}/>)
                                setEdit(d.id);
                                setOpen(true); 
                            }}  
                        ]
                    } /> 
                    <ListPageResponsive onChange={()=>{ 
                        tempLoja = null;
                        BuscarDados(true);
                    }} session={session}  page={dados.page} />
                </Stack>
                <ButtonKNew  onClick={()=>{
                    setViewPage(<FormWithdraw   open={open} setOpen={(el)=>{
                        setOpen(el)
                    }} edit={edit} setEdit={setEdit}/>)
                    setOpen(true);
                    setEdit(null);
                }}>Solicitar Saque</ButtonKNew>
                <ModalPage open={open} setOpen={setOpen}>
                    {viewPage}
                </ModalPage>
                <BoxDialog param={alert}  />
            </Stack> 
        )
    }
    
}
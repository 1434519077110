import {  Flex,  Stack, Text, Grid, GridItem,  TagLeftIcon, TagLabel, Tag, TabPanels, TabPanel, TabList, Tab, Tabs} from "@chakra-ui/react"; 
import { useEffect, useState } from "react";
import LoadBg from "../../../components/backgound/LoadBg"; 
import { PageH, PageHK } from "../../../components/heading/HeadingKalyst";
import { sendFormSocket, user } from "../../../components/session/SessionAdmin"; 
import {  LuCheckCheck, LuReceipt, LuTruck, LuUsers2 } from "react-icons/lu";  
import ListResponsive, { ListaResultadoResponsive, ListPageResponsive } from "../../../components/list/ListResponsive"; 
import { LojaSelect } from "../../../layout/painel/MenuPainel"; 
import { FaArrowTrendUp, FaBarcode, FaCartShopping, FaCreditCard, FaPix, FaScaleBalanced } from "react-icons/fa6";
import { getFilter, setFilterFiltro, setFilterPage } from "../../../components/filter/FilterDate";
import md5 from "md5";
import { FilterKalyst } from "../../../components/filter/FIlterKalyst";
import { CardPrice } from "../../../components/card/CardValues";
import { BiMoney, BiMoneyWithdraw } from "react-icons/bi";
import { ButtonKNew } from "../../../components/button/ButtonKalyst";
import ModalPage from "../../../components/modal/ModalPage";
import BoxDialog from "../../../components/modal/BoxDialog"; 
import { TempoCronometro, viewPrice } from "../../../components/Function";
import { FaBalanceScale, FaCheckDouble } from "react-icons/fa";
// import FormPagamento from "./FormPagamento";
import FilterPainel from "../../../components/filter/FilterPainel";
import SelectK from "../../../components/select/SelectKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import { DataPickerKalyst } from "../../../components/input/DataPickerKalyst";


let session   = null;
export function PageAdminScaleStore(props){   
    const [open,setOpen]    = useState(false); 
    const [dados,setDados]  = useState(null); 
    const [edit,setEdit]    = useState({}); 
    const [alert,setAlert]    = useState({}); 
    
    const hoje = new Date(); 
    let tempLoja = null;
    let tempFilter = null;
    let dFilter   = null
    function BuscarDados(reload){  
        session = md5(user.id+'scaladstoreadminv2');
        dFilter = getFilter(session);
        if(LojaSelect !==tempLoja  || dFilter.up !==tempFilter ){
            tempLoja = LojaSelect
            tempFilter = dFilter.up
            reload = true; 
        }     
        sendFormSocket({campo:'pages', modulo:'scale',cmd:'store',form:{reload,loja:LojaSelect,filtro:dFilter},callback:(result)=>{   
            setDados(result)
        }});
    } 
    useEffect(()=>{ 
        BuscarDados(true);
        var interV =setInterval(()=>{
            BuscarDados(false);
        },500); 
        return  ()=>{
            clearInterval(interV);
        }
    },[]);

    if(dados == null){ 
        return <LoadBg/>
    }else{ 
        if(window.location.hash!=''){
            setTimeout(()=>{
                window.location.hash ='';
                setOpen(true);
                setEdit(null);
            })
           
        }  
        const titles = [
            {name:'Cliente',    campo:'usuario'   ,w:4},
            {name:'Loja',       campo:'title'       ,w:2},
            {name:'Valor',      campo:'valor'       ,w:2},
            {name:'Média',      campo:'valorMed'       ,w:2},
            {name:'Data',       campo:'data'        ,w:2}
        ];
        const boxInfo={
            v1:{value:0,info:0},
            v2:{value:0,info:0},
            v3:{value:0,info:0},
            v4:{value:0,info:0},
        }
        try {  
            boxInfo.v1.value = viewPrice(dados.saldos.saquesP); 
            
            boxInfo.v2.value = viewPrice(dados.saldos.saldo); 
            
            boxInfo.v3.value = viewPrice(dados.saldos.saquesD);
            boxInfo.v3.info  = viewPrice(dados.saldos.saques);
            
            boxInfo.v4.value = viewPrice(dados.saldos.vendasH);
            boxInfo.v4.info  = viewPrice(dados.saldos.vendas);
        } catch (error) {
            
        }
        const lista = [];
        dFilter = getFilter(session);
        let initial = {}
        try {
            Object.keys(dFilter.filtro).forEach((key)=>{
                initial[key]=dFilter.filtro[key].value;
            })
        } catch (error) {
            
        }  

        const status={
            'N'     :   {name:'Pago',       color:'green'}, 
            'B'     :   {name:'Bloqueado',  color:'red'},
            // Devolvido   :   {name:'Devolvido',  color:'red'},
            // Cancelado   :   {name:'Cancelado',  color:'red'},
            // Pendente    :   {name:'Pendente',   color:'orange'} , 
        }
                     
        if(dados.stores.length>0){    
            dados.stores.forEach((cli)=>{  
                const data = new Date(cli.criacao);
                const dataP = new Date(cli.dataP);
                lista.push({   
                    id:cli.id,
                    title       :   cli.usuario,   
                    valorMed    :   'R$'+viewPrice(cli.valorMed),    
                    valor       :   'R$'+viewPrice(cli.valor), 
                    data    :    new Date(data.getTime()-(data.getTimezoneOffset() * 60000)).toLocaleDateString('pt-BR', {  month: '2-digit', day: '2-digit',hour:'2-digit',minute:'2-digit'})  
                }) 
            }) 
        }   
        let fastV='';
        try {
            fastV = dFilter.filtro.fast.value;
        } catch (error) {
            
        }  

        //filter
        const viewFilter = (
            <Stack gap={'0px'}> 
                <Tabs variant='soft-rounded' colorScheme='green' size={'sm'}   pt={'0px'} px={'16px'}  pb='0'>
                    <TabList>
                        <Tab fontSize={'10px'}>Nome</Tab>
                        <Tab fontSize={'10px'}>Documento</Tab>
                        <Tab fontSize={'10px'}>Email</Tab>
                        <Tab fontSize={'10px'}>Telefone</Tab>
                    </TabList>
                    <TabPanels px={'0'}  pb='0'>
                        <TabPanel  px={'0'} pb='0' >
                            <InputK  focus={true}  type={'text'} label="Cliente Nome" size='sm'  name="LIKE_AND-cliente" placeholder={"Nome completo "}   autoComplete={false} initial={initial}/>  
                        </TabPanel>
                        <TabPanel  px={'0'} pb='0px'>
                            <InputK  focus={false}  type={'cpfcnpj'} label="Cliente Documento" size='sm'  name="LIKE_AND-documento" placeholder={"cpf ou cnpj"}   autoComplete={false} initial={initial}/>
                        </TabPanel>
                        <TabPanel  px={'0'} pb='0px'>
                            <InputK  focus={false}  type={'email'} label="Cliente E-mail" size='sm'  name="LIKE_AND-email" placeholder={"email válido"}   autoComplete={false} initial={initial}/>
                        </TabPanel>
                        <TabPanel  px={'0'} pb='0px'>
                            <InputK  focus={false}  type={'phone'} label="Cliente Telefone" size='sm'  name="LIKE_AND-telefone" placeholder={"ddd + número"}   autoComplete={false} initial={initial}/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
                <DataPickerKalyst  name="DATA_PERIODO-criacao" max='hoje'  initial={initial}/>
                <Stack gap='0px' px='16px'  >
                    <SelectK label={'Método'} placeholder="Selecione" size='sm'   value={[{value:'pix',name:'PIX'},{value:'credit',name:'Crédito'},{value:'boleto',name:'Boleto'}]} name='IGUAL_AND-method' initial={initial} />
                    <SelectK label={'Status'} placeholder="Selecione" size='sm'    value={[{value:'pago',name:'Pago'},{value:'pendente',name:'Pendente'},{value:'rejeitado',name:'Rejeitado'},{value:'chargeback',name:'Chargeback'},{value:'estorno',name:'Estornado'}]} name='IGUAL_AND-status' initial={initial}/>
                    <SelectK label={'Produto'} placeholder="Selecione"  size='sm'    value={[{value:'S',name:'Físico'},{value:'N',name:'Digital'}]} name='IGUAL_AND-fisico' initial={initial}/>
                    <SelectK label={'Rastreio'} placeholder="Selecione"  size='sm'    value={[{value:'S',name:'Enviado'},{value:'N',name:'Pendente'}]} name='IGUAL_AND-rastreioEnviado' initial={initial}/>
                    <InputK  focus={false}  type={'text'} label="Código Rastreio" size='sm'  name="LIKE_AND-rastreio|linkRastreio" placeholder={"Código ou Site"}   autoComplete={false} initial={initial}/>  
                </Stack> 
            </Stack> 
        )  
        var tempReload = null;
        return  ( 
            <Stack   w='full' gap={{base:'16px',md:'32px'}}  align={'flex-start'} justify='flex-start' overflowX='hidden'   minH={{base:'calc(100vh - 168px)',md:'calc(100vh - 152px)'}} pb='100px'  margin = '0 auto'  >
                {/* <Grid
                    w={'full'}  
                    templateColumns={{base:'repeat(4, 1fr)',sm:'repeat(8, 1fr)',md:'repeat(4, 1fr)',lg:'repeat(8, 1fr)'}}
                    gap={'16px'}
                    py='16px'
                >
                    <GridItem colSpan={2}  > 
                        <CardPrice icon={BiMoney} title='Saques Total'  
                        value={boxInfo.v1.value}   
                        info={<Text color={'orange.500'} fontWeight='bold'>Pendente</Text>}
                        // infoM={'Pedidos não Pago'}
                    />
                    </GridItem> 
                    <GridItem colSpan={2}  >
                        <CardPrice icon={FaCheckDouble} title='Saldo Geral'  
                        value={boxInfo.v2.value}   
                         info={<Text color={'green.500'} fontWeight='bold'>Disponível</Text>}
                    />
                    </GridItem> 
                    <GridItem colSpan={2}  >
                        <CardPrice icon={BiMoneyWithdraw} title='Saques Totais' value={boxInfo.v3.value} 
                         info={<Text color={'orange.500'} fontWeight='bold'>R$ {boxInfo.v3.info}</Text>}
                         infoM={'Saques Pago'}/>
                    </GridItem> 
                    <GridItem colSpan={2}  >
                        <CardPrice icon={FaCartShopping} title='Vendas Geral' value={boxInfo.v4.value}
                        info={<Text color={'orange.500'} fontWeight='bold'>R$ {boxInfo.v4.info}</Text>}
                        infoM={'Vendas Total'}/>
                    </GridItem> 
                </Grid> */}
                <PageHK>Escalonamento por loja</PageHK>  
                <Stack gap='0px' w={'full'} >   
                    <FilterPainel variant='admin' dFilter={dFilter} session={session}  
                        onChange={()=>{
                            clearTimeout(tempReload);
                            tempReload = setTimeout(()=>{ 
                                tempLoja = null;
                                BuscarDados(true); 
                            },1000) 
                        }} 
                        ordem={[
                            {id:'id_desc'       ,name:'Mais Recentes'},
                            {id:'id_asc'        ,name:'Menos Recentes'},
                            {id:'valor_desc'    ,name:'Maior Valor'},
                            {id:'valor_asc'     ,name:'Menor Valor'},
                            {id:'method_desc'   ,name:'Método de Pagamento [Z-A]'},
                            {id:'method_asc'    ,name:'Método de Pagamento [A-Z]'},
                            {id:'status_desc'   ,name:'Status [Z-A]'},
                            {id:'status_asc'    ,name:'Status [A-Z]'}
                        ]}
                        fast={['method','status','cliente']}
                        viewFilter={viewFilter}
                    />   
                    <ListaResultadoResponsive session={session}  page={dados.page}/>
                    <ListResponsive titles={titles} dados={lista} cmd={
                        [
                            { icon:<LuReceipt />,name:'Editar', click:(d)=>{  
                                setEdit(d.id);
                                setOpen(true); 
                            }}  
                        ]
                    } /> 
                    <ListPageResponsive onChange={()=>{ 
                        tempLoja = null;
                        BuscarDados(true);
                    }} session={session}  page={dados.page} />
                </Stack>
                <ModalPage open={open} setOpen={setOpen}>
                    {/* <FormPagamento  open={open} setOpen={(el)=>{ 
                        setOpen(el)
                    }} edit={edit} setEdit={setEdit}/> */}
                </ModalPage>
                <BoxDialog param={alert}  />
            </Stack> 
        )
    }
    
}
// import {  Flex, Image, Stack, Text,Icon,colorMode, toggleColorMode, useColorMode, Input, Grid, GridItem, SimpleGrid, Progress, Textarea, Button, useToast, useClipboard} from "@chakra-ui/react"; 
// import { useEffect, useState } from "react";
// import LoadBg from "../../../components/backgound/LoadBg"; 
// import { PageHK, SubHK } from "../../../components/heading/HeadingKalyst";
// import { sendFormSocket, user } from "../../../components/session/SessionAdmin"; 
// import { LuBox, LuCopy, LuLineChart, LuPen, LuTrash, LuUser2, LuUsers2 } from "react-icons/lu"; 
// import ListResponsive, { ListaResultadoResponsive } from "../../../components/list/ListResponsive";
// import { ButtonKNew } from "../../../components/button/ButtonKalyst";
// import ModalPage from "../../../components/modal/ModalPage";
// // import FormLink from "./FormLink";
// import { LojaSelect } from "../../../layout/painel/MenuPainel";
// import BoxDialog from "../../../components/modal/BoxDialog";
// import { config } from "../../../theme/PageConfig";
// import { viewPrice } from "../../../components/Function";
// import md5 from "md5";
// import { getFilter } from "../../../components/filter/FilterDate";

// let session   = null;
// export function PageAdminScaleStore(props){  
//     const [open,setOpen]    = useState(false); 
//     const [dados,setDados]  = useState(null); 
//     const [edit,setEdit]    = useState({}); 
//     const [alert,setAlert]    = useState({}); 
//     const toast = useToast();
//     const hoje = new Date(); 
//     let tempLoja = null;
//     let tempFilter = null;
//     let dFilter   = null
//     const { onCopy, value, setValue, hasCopied } = useClipboard('')
//     function BuscarDados(reload){ 
//         session = md5(user.id+'scaladstoreadminv2');
//         dFilter = getFilter(session); 
//         if(LojaSelect !==tempLoja){
//             tempLoja = LojaSelect
//             reload = true; 
//         } 
//         sendFormSocket({campo:'pages', modulo:'scale',cmd:'store',form:{reload,loja:LojaSelect},callback:(result)=>{  
//             console.log(result);
//             setDados(result)
//         }});
//     } 
//     useEffect(()=>{ 
//         BuscarDados(true);
//         var interV =setInterval(()=>{
//             BuscarDados(false);
//         },500);
        
//         return  ()=>{
//             clearInterval(interV);
//         }
//     },[]);

//     if(dados == null){ 
//         return <LoadBg/>
//     }else{  
//         const titles = [
//             {name:'Cliente',    campo:'usuario'   ,w:4},
//             {name:'Loja',  campo:'title'       ,w:2},
//             {name:'Valor',   campo:'valor'       ,w:2},
//             {name:'Média',   campo:'valorMed'       ,w:2},
//             {name:'Data',    campo:'data'        ,w:2}
//         ];
//         const status={
//             'N'     :   {name:'Pago',       color:'green'}, 
//             'B'     :   {name:'Bloqueado',  color:'red'},
//             // Devolvido   :   {name:'Devolvido',  color:'red'},
//             // Cancelado   :   {name:'Cancelado',  color:'red'},
//             // Pendente    :   {name:'Pendente',   color:'orange'} , 
//         }
//         const lista = [];    
//         if(dados.stores.length>0){  
//             dados.stores.forEach((cli)=>{ 
//                 lista.push({
//                     id          :   cli.id,
//                     title       :   cli.title, 
//                     usuario     :   cli.usuario,   
//                     valorMed    :   'R$'+viewPrice(cli.valorMed),    
//                     valor       :   'R$'+viewPrice(cli.valor), 
//                     data        :    new Date(cli.criacao).toLocaleDateString('pt-BR', {  month: '2-digit', day: '2-digit',hour:'2-digit',minute:'2-digit'}) 
//                 })
//             }) 
//         }  
        
//         return  ( 
//             <Stack zIndex={3} w='full' gap={{base:'16px',md:'32px'}}  align={'flex-start'} justify='flex-start'   minH={'100vh'} pb='100px'  margin = '0 auto'  >
//                 <PageHK>Escalonamento por Loja</PageHK> 
//                 <ListaResultadoResponsive session={session}  page={dados.page}/>
//                 <ListResponsive titles={titles} dados={lista} cmd={
//                     [
//                         // { icon:<LuCopy/>,name:'Copiar Link', click:(d)=>{    
                     
//                         //     setValue(d.link)
//                         //     onCopy();
//                         //     toast({
//                         //         title: 'Link Copiado',
//                         //         description: 'Link de pagamento copiado para memória',
//                         //         status: 'success',
//                         //         position:'top-right',
//                         //         duration: 6000,
//                         //         isClosable: true,
//                         //     });
//                         // }}, 
//                         // { icon:<LuPen/>,name:'Editar', click:(d)=>{  
//                         //     setEdit(d.id);
//                         //     setOpen(true); 
//                         // }}, 
                         
//                         // { icon:<LuTrash/>,name:'Deletar', click:(d)=>{  
//                         //     setAlert({load:true});
//                         //     setTimeout(()=>{ 
//                         //         setAlert({
//                         //             fechar:true,
//                         //             icon   : <Icon as={LuTrash} h={{base:'50px'}} w={{base:'50px'}} color='red.300' />, 
//                         //             titulo:'Deletar Link',
//                         //             size:'md',
//                         //             mensagem:'Deseja realmente deletar a Link <b>'+d.descrica+'<b>?', 
//                         //             botao:[
//                         //                 {
//                         //                     nome:'SIM', 
//                         //                     cmd:()=>{
//                         //                         setAlert({load:true});
//                         //                         sendFormSocket({ modulo:'link',cmd:'deleteLink',form:{id:d.id},callback:(result)=>{ 
//                         //                             setTimeout(()=>{
//                         //                                 setAlert({exit:true});
//                         //                                 BuscarDados(false); 
//                         //                             },500)
//                         //                         }});
//                         //                     }
//                         //                 },
//                         //                 {
//                         //                     nome:'NÃO',
//                         //                     variant:'ghost',
//                         //                 }
//                         //             ]
//                         //         });
//                         //     }) 
//                         // }}
//                     ]
//                 } />  
//                 <ModalPage open={open} setOpen={setOpen}>
//                     {/* <FormLink   open={open} setOpen={(el)=>{ 
//                         setOpen(el)
//                     }} edit={edit} setEdit={setEdit} 
//                     frets={frets} prods={prods}
                    
//                     /> */}
//                 </ModalPage>
//                 <BoxDialog param={alert}  />
//             </Stack> 
//         )
//     }
    
// }
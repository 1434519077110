import {   Stack, Box, Input, Flex, Img, Button,others, Text,Icon} from "@chakra-ui/react";
import { useState } from "react";
import { LuGlobe, LuGlobe2, LuLink, LuSave, LuTrash, LuUpload } from "react-icons/lu";
import { ButtonKalyst } from "../button/ButtonKalyst";
import { TextK1 } from "../text/TextKalyst"; 
 
import { InputK } from "../input/InputKalyst";
import { LinkK } from "../button/LinkKalyst";

export function  GerarFile(event,callback) { 
    var fileS = {};    
    Object.keys(event.target.files).forEach(async (key)=> {
        var file    =   event.target.files[key];   
        var reader      =   new FileReader();
        reader.onload =  (e)=> {  
            fileS= {
                file    :   'U',
                link    :   e.target.result, 
                size    :   (file.size*0.000001),
                width   :   0,
                height  :   0,
                nome    :   file.name.split('.')[0],
                tipo    :   file.name.split('.')[file.name.split('.').length-1] 
            };  
            var image = new Image();
            image.src = e.target.result; 
            image.onload = function() {
                // access image size here 
                fileS.width     =   this.width;
                fileS.height    =   this.height; 
                callback(fileS); 
            }; 
        }; 
        reader.readAsDataURL(file); 
    });  
    return fileS;
}
function importFile(url,callback){ 
    var image = new Image(); 
    var fileS= {
        file    :   'E',
        link    :   'https://'+url,  
        width   :   0,
        height  :   0
    }
    image.src = fileS.link; 
    image.onload = function() {
        // access image size here  
        fileS.width     =   this.width;
        fileS.height    =   this.height;  
        callback(fileS); 
    }; 
    image.onerror=function(){
        callback({});
    }
}
export function ImageUpload({onChange,value={},label,...others}){
    const [load,setLoad] = useState(false); 
    const [file,setFile] = useState(value); 
    const [error,setError] = useState(''); 
    const [link,setLink] = useState('');
    
    const [url,setUrl] = useState(false);
    const typeFiles = { 'png':'image/png', 'jpeg':'image/jpeg', 'jpg':'image/jpg', 'webp':'image/webp'};
    var formatos = '';
    Object.values(typeFiles).forEach((e,i)=>{
        if(i>0){
            formatos += ',';
        }
        formatos += e;
    })  
    if(url){
        return (  (Object.keys(file).length==0)?
                <Flex position={'relative'} w={'100%'} align='flex-end' {...others} > 
                    <InputK  type='link' errorI={error} value={link} onFocus={()=>{
                        setError('')
                    }} onChange={(v)=>{
                        setLink(v)
                    }}  w='calc(100% - 120px)' onBlur={(img)=>{   
                        importFile(img,(fl)=>{   
                            setFile(fl);
                            if(Object.keys(fl).length==0){ 
                                if(link!==''){ 
                                    setError('Arquivo de imagem inválido, adicione um link válido!')
                                }
                                setLink('');
                            } 
                            onChange(fl);
                        }) 
                    }}    label="URL Imagem" leftA={'https://'} leftAW={'5em'}  placeholder="www.site.com/img.jpg"   focus={true} autoComplete={false} borderRadius='4px 0px  0px 4px'/>
                    <ButtonKalyst  mb={{base:'13px',sm:'14px',lg:'15px'}}  leftIcon={<LuUpload/>}   borderRadius='0 4px 4px 0'  onClick={(e)=>{ 
                    e.preventDefault();
                    setUrl(false);
                    }}>UPLOAD</ButtonKalyst>
                </Flex>
                :
                <>
                    <Text fontSize={{base:'10px',md:'12px',lg:'14px'}} _dark={{ color:'textDark.100'}} title={label} noOfLines={1} >{label}</Text>
                    
                    <Stack position={'relative'} w={'100%'} h='200px' color={'textDark.300'} border='4px dashed' borderRadius='16px' overflow={'hidden'} bg={'rgba(136,136,136,0.1)'} {...others}>
                        <Flex position={'absolute'} left={'0px'}  top='0px'  w={'100%'} h={'100%'} overflow={'hidden'}   >
                            <Img zIndex={'1'} src={file.link} position={'absolute'}  left='50%' top='50%' transform={'translateX(-50%) translateY(-50%)'} w={'auto'} h={'100%'}/> 
                            <Flex zIndex={'2'} w='100%' align={'flex-end'} justify='center' gap='10px' pb='10px'>
                                <ButtonKalyst  variant={'ghost'} colorScheme="red"  leftIcon={<LuTrash/>} border='2px solid' bg='rgba(0,0,0,1)' onClick={(e)=>{
                                    e.preventDefault();
                                    setFile({}); 
                                    onChange({});
                                    setLink('');
                                }}>Remover</ButtonKalyst>
                            </Flex>
                        </Flex> 
                    </Stack>
                    
                        <LinkK maxW='100%' noOfLines='1' fontSize='10px' onClick={()=>{
                            window.open(file.link, '_blank', 'location=yes,scrollbars=yes,status=yes');
                        }}>
                             {file.link} 
                        </LinkK>
                        {/* <Icon as={LuLink}/><Text fontSize={{base:'10px' }} color='textDark.200'  title={label} noOfLines={1} >{file.link}</Text> */}
                     
                    
                    
                </>
         )
    }else{
        return (
            <>
            
            <Text fontSize={{base:'10px',md:'12px',lg:'14px'}} _dark={{ color:'textDark.100'}} title={label} noOfLines={1} >{label}</Text>
                <Stack position={'relative'} w={'100%'} h='200px' color={'textDark.300'} border='4px dashed' borderRadius='16px' overflow={'hidden'} bg={'rgba(136,136,136,0.1)'} {...others}>
                    <Flex position={'absolute'} left={'0px'}  top='0px'  w={'100%'} h={'100%'} overflow={'hidden'}   >
                        {typeof file.link!=='undefined'?
                            <>
                                <Img zIndex={'1'} src={file.link} position={'absolute'}  left='50%' top='50%' transform={'translateX(-50%) translateY(-50%)'} w={'auto'} h={'100%'}/> 
                                <Flex zIndex={'2'} w='100%' align={'flex-end'} justify='center' gap='10px' pb='10px'>
                                    <ButtonKalyst  variant={'ghost'} colorScheme="red"  leftIcon={<LuTrash/>} border='2px solid' bg='rgba(0,0,0,1)' onClick={(e)=>{
                                        e.preventDefault();
                                        setFile({}); 
                                        onChange({});
                                    }}>Remover</ButtonKalyst>
                                </Flex>
                            </>
                        :
                        
                            <Flex w={'100%'} direction='column' align={'center'} justify='center' cursor={'pointer'}> 
                                <Input   position={'absolute'} opacity={0} onChange={(e)=>{ 
                                    GerarFile(e,(f)=>{    
                                        setFile(f); 
                                        onChange(f);
                                    });  
                                }} h={'100%'} w={'100%'} type="file"  variant={'ghost'} accept={formatos} minW={'initial'} cursor={'pointer'} />
                                <TextK1  textAlign='center' color='gray.500' cursor={'pointer'}>
                                    Clique ou arraste uma imagem aqui
                                </TextK1>
                                <TextK1  textAlign='center' color='gray.500' cursor={'pointer'}>
                                    ou
                                </TextK1>
                                <ButtonKalyst mt='10px'  border='2px solid' bg='rgba(0,0,0,1)' onClick={(e)=>{
                                     e.preventDefault();
                                    setUrl(true)
                                }} zIndex={'2'} variant='ghost' leftIcon={<LuGlobe/>}>URL Externa</ButtonKalyst>
                            </Flex> 
                        }
                        
                        
                        {/* <Image position={'absolute'}  src={bgImg.link}  left={bgImg.left} right={bgImg.right} top={bgImg.top} bottom={bgImg.bottom} w={bgImg.w} h={bgImg.h} maxW={'initial'} transform={bgImg.translate} /> */}
                    </Flex>
                </Stack>
            </>
            
        )
    }
    
}
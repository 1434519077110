import { Flex, Grid, GridItem, HStack, SimpleGrid, Stack, Text, Textarea,Icon, IconButton, Skeleton, Input, Tag, Tooltip, TagLabel, TagLeftIcon, Spacer } from "@chakra-ui/react";
import { HK4, HK5, PageHK, SubBHK, SubHK, SubTHK } from "../../../components/heading/HeadingKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { object, string } from "yup";
import { SendForm } from "../../../components/form/SendForm";
import { TextK1 } from "../../../components/text/TextKalyst";
import { LinkK } from "../../../components/button/LinkKalyst";
import { useEffect, useRef, useState } from "react";
import SelectK from "../../../components/select/SelectKalyst";
import { sendFormSocket } from "../../../components/session/SessionAdmin"; 
import { LuAlertTriangle, LuCheck, LuDownload, LuImport, LuInfo, LuLink, LuLink2, LuPackage2, LuTruck, LuUpload } from "react-icons/lu"; 
import { CardInfoText, CardPrice, CardPriceMini } from "../../../components/card/CardValues";
import { DataNomeMes, ExportCSV, ImportCSV, parseValor, validateSITE, viewPrice } from "../../../components/Function";
import { LojaSelect } from "../../../layout/painel/MenuPainel";  


import React from 'react'  
import { FaWhatsapp } from "react-icons/fa"; 
import { BiMoneyWithdraw } from "react-icons/bi";
import BoxDialog from "../../../components/modal/BoxDialog";


export default function InfoAdminReports( {open,setOpen,edit = null,setEdit}){  
    const [load,setLoadD] = useState(false);
    const [base,setBase] = useState({saldoD:0}); 
    const [user,setUser] = useState({});
    const [lanc,setLanc] = useState({});
    const [tipos,setTipos] = useState({});
    const [adq,setAdq] = useState({});
    const [reload,setReload] = useState(false);
    const [alert,setAlert]    = useState({}); 
    
    const [lista,setLista] = useState(null);
    const ip = useRef(null); 
    function buscarDados(){ 
        // console.log({loja:LojaSelect,id:edit});
        sendFormSocket({ modulo:'reports',cmd:'info',form:{id:edit},callback:(result)=>{    
            setLoadD(true);   
            setAdq(result.adq); 
            setUser(result.seller); 
            setLanc(result.lanc); 
            setTipos(result.tipos);
            setBase(result.dados); 
            setReload(true); 
        }});
    } 
    useEffect(()=>{  
        setTimeout(()=>{
            buscarDados()
        },100); 
    },[]); 

    if(!reload && typeof base.id!= 'undefined'){  
         const method = {pix:'PIX',credit:'Cartão',boleto:'Boleto'};
         const status={
            Pago        :   {name:'Pago',       color:'green'}, 
            Rejeitado   :   {name:'Rejeitado',  color:'red'},
            Devolvido   :   {name:'Devolvido',  color:'red'},
            Cancelado   :   {name:'Cancelado',  color:'red'},
            Pendente    :   {name:'Pendente',   color:'orange'} , 
        }
        let total = 0;
        let desconto = 0;
        let frete = null;
        let produtos = [];
        //pedidos
        if(typeof base.items !== 'undefined' && base.items.length>0){
            base.items.forEach((i)=>{
                total+=i.quantity*i.unitPrice; 
                produtos.push(i)
            }) 
        }  
        //entrega
        try {
            if(typeof base.shipping.fee !== 'undefined'){
                desconto  += base.shipping.fee;
                frete = base.shipping.fee>0?viewPrice(base.shipping.fee):'Grátis';
            }
        } catch (error) {
            frete = null
        }
        //provedor
        let provedor = {
            nome    :   'Desconhecido',
            link    :   null,
            origem  :   null,
            site    :   null
        }; 
        try { base.metadata     =   JSON.parse(base.metadata) }       catch (error) { };
        try { provedor.nome     =   base.metadata.provider; }       catch (error) { };
        try { provedor.link     =   base.metadata.order_url; }      catch (error) { };
        try { provedor.origem   =   base.metadata.referrer_link; }  catch (error) { };
        try { provedor.site     =   base.metadata.sell_url; }       catch (error) { };
        //lançamento
        let lancamentos = [];
        lanc.forEach((v)=>{
            lancamentos.push({
                id      :   v.id,
                tipo    :   tipos[v.codtip].nome,
                tipoCor :   tipos[v.codtip].cor,
                valor   :   v.valor,
                valorR  :   v.valorR,
                valorT  :   v.taxaGT
            });
        })
        console.log(adq,base)
        return   <Stack w={'full'} >
                <TextK1 px='16px'  fontSize={{base:"12px",sm:'14px'}}>Pagamento via <Text as={'span'} color={'textAdmin.300'}>{method[base.paymentMethod]}</Text></TextK1>
                <Stack w={'100%'} gap={'0px'}   align={'flex-start'}  justify={'flex-start'}  p='16px' pt='0px' borderRadius='8px'>   
                    <SubBHK   textAlign='left'>Dados Pedido 
                        <Tag size={{base:'sm',sm:'md'}} ml='10px'  variant='subtle' colorScheme={typeof status[base.status] !=='undefined'?status[base.status].color:'gray'}> 
                            <TagLabel>{ typeof status[base.status] !=='undefined'?status[base.status].name:base.status}</TagLabel>
                        </Tag>
                    </SubBHK>  
                    <Stack  w={'100%'} gap={'5px'} pb={'10px'}>
                        {produtos.length==0?<TextK1 w='100%' textAlign='right'>Nenhum produto adicionado</TextK1>:produtos.map((p,ky)=>{ 
                        return <Flex key={'item'+ky} w={'100%'} align={'center'}   p='6px' borderRadius={'6px'} justify={'space-between'} gap={{base:'10px',md:'20px'}}  bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}}>
                                <TextK1 fontSize={'12px'}>{p.title}</TextK1>
                                <Flex gap={'10px'} minW={'100px'}  justify={'space-between'}>
                                    <Text  fontSize={'12px'} color={'gray.500'}  >{p.quantity} x  </Text>
                                    <Text as='span' fontSize={'12px'} color={'gray.500'} >R$ {viewPrice(p.unitPrice)}</Text> 
                                </Flex> 
                            </Flex>
                        })} 
                    </Stack>  
                    <Stack  w={'100%'} gap={'0px'} pt='5px' pb={'15px'} px='6px' >
                        <Flex  w={'100%'} justify={'flex-end'} gap={'10px'}>
                            <Text as={'span'} fontSize='12px' color={'gray.500'} > Subtotal</Text> <Text as={'span'} fontSize='12px' color={'orange.500'} > R$ {viewPrice(total)}</Text> 
                        </Flex>
                        {((total+desconto) != base.valor? 
                            <Flex  w={'100%'} justify={'flex-end'} gap={'10px'}>
                                <Text as={'span'} fontSize='12px' color={'gray.500'} > Desconto</Text> <Text as={'span'} fontSize='12px' color={'red.500'} >  R$ {viewPrice((total+desconto)-base.amount)}</Text> 
                            </Flex>
                        : null)}
                        {base.traceable?
                        <Flex  w={'100%'} justify={'flex-end'} gap={'10px'}>
                            <Text as={'span'} fontSize='12px' color={'gray.500'} > Frete</Text> <Text as={'span'} fontSize='12px' color={'green.500'} > {frete}</Text> 
                        </Flex>
                        :null} 
                    </Stack>  
                    <SubTHK><Text as={'span'} fontSize={{base:'12px',md:'16px'}} color={'gray.500'} pr='10px'> Total </Text> <Text as={'span'} fontSize={{base:'18px',md:'24px'}} color={ 'textAdmin.300'} > R$ {viewPrice(total)}</Text></SubTHK>
                </Stack>  
                <Flex w={'full'} gap={{base:'0px',md:'20px'}} align={'lex-start'}  justify={'space-between'} direction={{base:'column',md:"row"}}>
                    <Stack w={'100%'} gap={'0px'} mt='16px' align={'flex-start'}  justify={'flex-start'}  p='16px' pt='6px' bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}} borderRadius='8px'>   
                        <Stack w={'100%'} gap={'0px'} align={'flex-start'}  justify={'flex-start'} > 
                            <SubBHK   textAlign='left'>Dados Vendedor</SubBHK> 
                            <CardInfoText  load={load}  variant="admin"  title='Nome'  value={user.nome}  /> 
                            <CardInfoText  load={load}  variant="admin" title='E-mail'  value= {user.fantasia } /> 
                            <CardInfoText  load={load}  variant="admin" title='Telefone'  value={user.telefone}  />
                            <CardInfoText  load={load}  variant="admin" title={'Documento'}  value={user.documento}  />
                        </Stack>
                    </Stack>
                    <Stack w={'100%'} gap={'0px'} mt='16px' align={'flex-start'}  justify={'flex-start'}  p='16px' pt='6px' bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}} borderRadius='8px'>   
                        <Stack w={'100%'} gap={'0px'} align={'flex-start'}  justify={'flex-start'} > 
                            <SubBHK   textAlign='left'>Adquirente</SubBHK> 
                            {base.shipping !== null && typeof base.shipping.address !='undefined'? 
                                <Stack w={'100%'} gap={'0px'}> 
                                    <CardInfoText  load={load}  variant="admin"  title='Nome'           value={adq[base.adq].name}  />  
                                    <CardInfoText  load={load}  variant="admin" title='Pedido_ID'      value= {base.id } /> 
                                    <CardInfoText  load={load}  variant="admin" title='Transação_ID'   value= {base.transactionId} />  
                                    <CardInfoText  load={load}  variant="admin"  title='Externo_ID'     value= {base.externalRef} /> 
                                </Stack>
                            : 
                            <Text>
                                Endereço não informada
                            </Text>}   
                        
                        </Stack> 
                    </Stack>
                </Flex>
                <Flex w={'full'} gap={{base:'0px',md:'20px'}} align={'lex-start'}  justify={'space-between'} direction={{base:'column',md:"row"}}>
                    <Stack w={'100%'} gap={'0px'} mt='16px' align={'flex-start'}  justify={'flex-start'}  p='16px' pt='6px' bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}} borderRadius='8px'>   
                        <Stack w={'100%'} gap={'0px'} align={'flex-start'}  justify={'flex-start'} > 
                            <SubBHK   textAlign='left'>Dados Comprador</SubBHK> 
                            <CardInfoText  load={load} variant="admin"  title='Nome'  value={base.customer.name}  /> 
                            <CardInfoText  load={load} variant="admin"  title='E-mail'  value= {base.customer.email } /> 
                            <CardInfoText  load={load} variant="admin"  title='Telefone'  value={base.customer.phone}  />
                            <CardInfoText  load={load} variant="admin"  title={base.customer.document.type.toUpperCase()}  value={base.customer.document.number}  />
                        </Stack>
                    </Stack>
                    <Stack w={'100%'} gap={'0px'} mt='16px' align={'flex-start'}  justify={'flex-start'}  p='16px' pt='6px' bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}} borderRadius='8px'>   
                        <Stack w={'100%'} gap={'0px'} align={'flex-start'}  justify={'flex-start'} > 
                            <SubBHK   textAlign='left'>Entrega</SubBHK> 
                            {base.shipping !== null && typeof base.shipping.address !='undefined'? 
                                <Stack w={'100%'} gap={'0px'}> 
                                    <CardInfoText  load={load} variant="admin"  title='CEP'            value={base.shipping.address.zipCode}  /> 
                                    <CardInfoText  load={load} variant="admin"  title='Endereço'       value={base.shipping.address.street}  /> 
                                    <CardInfoText  load={load} variant="admin"  title='Bairro'         value= {base.shipping.address.neighborhood } /> 
                                    <CardInfoText  load={load} variant="admin"  title='Cidade - UF'    value= {base.shipping.address.city+' - '+base.shipping.address.state } /> 
                                </Stack>
                            : 
                            <Text>
                                Endereço não informada
                            </Text>}   
                        
                        </Stack> 
                    </Stack>
                </Flex> 
                <Flex w={'full'} gap={{base:'0px',md:'20px'}} align={'lex-start'}  justify={'space-between'} direction={{base:'column',md:"row"}}>
                    <Stack w={'100%'} gap={'0px'} mt='16px' align={'flex-start'}  justify={'flex-start'}  p='16px' pt='6px' bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}} borderRadius='8px'>   
                        <Stack w={'100%'} gap={'0px'} align={'flex-start'}  justify={'flex-start'} > 
                            <SubBHK   textAlign='left'>Lançamentos</SubBHK> 
                             {lancamentos.length>0?
                                lancamentos.map((v,k)=>{
                                    return <Flex key={'lanc'+k} w={'full'} fontSize={'12px'} gap='10px' align={'center'} h={'24px'} px={'6px'} borderRadius={'6px'}  bg='#ffffff' _dark={{bg:'#000000'}}>
                                        <Text color={'gray.500'}>{v.tipo}</Text>
                                        <Spacer/> 
                                        {v.valorT>0?
                                            <Flex gap={'5px'} align={'center'} pt='4px'>
                                                <Tooltip hasArrow label={'Valor Taxa'} color='lightText.100'  _light={{ bg:'lightTema1.100',borderColor:'lightTema1.100'}} _dark={{ bg:'darkTema1.100',borderColor:'darkTema1.100',color:'darkText.100'}}>
                                                    <IconButton icon={<LuInfo/>} variant='ghost' minW='initial'    fontSize='10px'   />
                                                </Tooltip>
                                                <Text color={ 'red.500'}   fontSize={'10px'}>  R$ -{viewPrice(v.valorT)} </Text>
                                            </Flex> 
                                        :null}
                                        <Flex gap={'5px'} align={'center'}>
                                            <Tooltip hasArrow label={'Valor liquido'} color='lightText.100'  _light={{ bg:'lightTema1.100',borderColor:'lightTema1.100'}} _dark={{ bg:'darkTema1.100',borderColor:'darkTema1.100',color:'darkText.100'}}>
                                                <IconButton icon={<LuInfo/>} variant='ghost' minW='initial'    fontSize='16px'  />
                                            </Tooltip>
                                            <Text color={v.tipoCor+'.500'}    fontSize={'16px'}>  R$ {viewPrice(v.valor)} </Text>
                                        </Flex> 
                                    </Flex>
                                })
                             :<Text>Nenhum Lançamento</Text>}   
                            {/*  
                            <CardInfoText  load={load}   title='E-mail'  value= {base.customer.email } /> 
                            <CardInfoText  load={load}   title='Valor Fi'  value={base.customer.phone}  />  */}
                        </Stack>
                    </Stack>
                    <Stack w={'100%'} gap={'0px'} mt='16px' align={'flex-start'}  justify={'flex-start'}  p='16px' pt='6px' bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}} borderRadius='8px'>   
                        <Stack w={'100%'} gap={'0px'} align={'flex-start'}  justify={'flex-start'} > 
                            <SubBHK   textAlign='left'>Checkout</SubBHK> 
                            {base.shipping !== null && typeof base.shipping.address !='undefined'? 
                                <Stack w={'100%'} gap={'0px'}> 
                                    <CardInfoText  load={load}   variant="admin"  title='Provedor'           value={provedor.nome}  copy={false} /> 

                                    <CardInfoText  load={load}   variant="admin"  title='Link Pagamento'     value={(provedor.link==null?'Não informado':null)}  obj={(provedor.link!==null? 
                                        <Tag size='sm' colorScheme="green"  cursor={'pointer'} onClick={()=>{
                                            window.open((provedor.link), '_blank'); 
                                        }}> 
                                            <TagLeftIcon  as={LuLink} />
                                            <TagLabel  fontSize={'12px'}>ACESSAR</TagLabel>
                                        </Tag>  
                                    :null)}        copy={false} /> 
                                    <CardInfoText  load={load}   variant="admin"  title='Link Origem'     value={(provedor.origem==null?'Não informado':null)}  obj={(provedor.origem!==null? 
                                        <Tag size='sm' colorScheme="green" cursor={'pointer'}   onClick={()=>{
                                            window.open((provedor.origem), '_blank'); 
                                        }}> 
                                            <TagLeftIcon  as={LuLink} />
                                            <TagLabel fontSize={'12px'}>ACESSAR</TagLabel>
                                        </Tag>  
                                    :null)}        copy={false} />
                                    <CardInfoText  load={load}    variant="admin" title='Link Site'     value={(provedor.site==null?'Não informado':null)}  obj={(provedor.site!==null? 
                                        <Tag size='sm' colorScheme="green"  cursor={'pointer'}  onClick={()=>{
                                            window.open((provedor.site), '_blank'); 
                                        }}> 
                                            <TagLeftIcon  as={LuLink} />
                                            <TagLabel fontSize={'12px'}>ACESSAR</TagLabel>
                                        </Tag>  
                                    :null)}        copy={false} />  
                                </Stack>
                            : 
                            <Text>
                                Endereço não informada
                            </Text>}   
                        
                        </Stack> 
                    </Stack>
                </Flex> 
                 {base.rastreio!==null?
                    <Stack w={'100%'} gap={'0px'} mt='16px' align={'flex-start'}  justify={'flex-start'}  p='16px' pt='16px' bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}} borderRadius='8px'>
                        <SubBHK   textAlign='left'>Rastreio</SubBHK> 
                            <Flex w={'full'} gap={'20px'} direction={{base:'column',md:"row"}}>
                                
                                <CardInfoText  load={load}   title='Código'         value= {base.rastreio } /> 
                                
                                <CardInfoText  load={load}   title='Link'       value= {base.linkRastreio }    obj={( 
                                <Tag size='sm' colorScheme="green" cursor={'pointer'}  value= {base.linkRastreio }  onClick={()=>{
                                    window.open((base.linkRastreio), '_blank'); 
                                }}> 
                                    <TagLeftIcon  as={LuLink} />
                                    <TagLabel  fontSize={'12px'}>ACESSAR</TagLabel>
                                </Tag> )}        copy={false} /> 
                            </Flex>
                    </Stack>   
                 :null}    
                <Stack w={'100%'} gap={'0px'} mt='16px' align={'flex-start'}  justify={'flex-start'}  p='16px' pt='16px' bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}} borderRadius='8px'>   
                    <Flex w={'100%'} gap={'10px'} direction={{base:'column',md:"row"}} align={'center'} justify={{base:'',md:'space-between'}}>
                        <ButtonKalyst  variant="admin" leftIcon={<Icon as={FaWhatsapp}/>} size={{base:'md',md:'lg'}} fontSize='12px' onClick={()=>{
                            window.open(('https://wa.me/55'+base.customer.phone), '_blank'); 
                        }}>Abrir Whatsapp</ButtonKalyst>     
                        <ButtonKalyst leftIcon={<Icon as={BiMoneyWithdraw }/>} size={{base:'md',md:'lg'}} fontSize='12px' variant={'ghost'} colorScheme="red" onClick={()=>{
                            setAlert({load:true});
                            setTimeout(()=>{ 
                                setAlert({
                                    fechar:true,
                                    icon   : <Icon as={LuAlertTriangle} h={{base:'50px'}} w={{base:'50px'}} color='red.300' />, 
                                    titulo:'Estornar Pedido',
                                    size:'md',
                                    mensagem:'Deseja realmente devolver o ped#<b style="color:red">'+base.transactionId+'</b>?', 
                                    botao:[
                                        {
                                            nome:'SIM', 
                                            cmd:()=>{
                                                setAlert({load:true});
                                                sendFormSocket({ modulo:'reports',cmd:'refound',form:{id:edit},callback:(result)=>{  
                                                    if(result.error){
                                                        result.size = 'md';  
                                                        result.icon = <Icon as={LuAlertTriangle} h={{base:'50px'}} w={{base:'50px'}} color='orange.300' />;
                                                        // result.titulo = 'Estornar Pedido';
                                                        console.log(result);
                                                        result.mensagem = result.message[0]; 
                                                        setAlert(result);
                                                    }else{ 
                                                        buscarDados();
                                                        setTimeout(()=>{
                                                            buscarDados();
                                                        },1000)
                                                        setTimeout(()=>{
                                                            buscarDados();
                                                            result.fechar =true; 
                                                            setAlert(result); 
                                                        },2000);
                                                        setAlert(result);
                                                    }  
                                                }}); 
                                            }
                                        },
                                        {
                                            nome:'NÃO',
                                            variant:'ghost',
                                        }
                                    ]
                                });
                            }) 
                        }}>Realizar Estorno</ButtonKalyst>  
                    </Flex>    
                </Stack>  
                <Flex w={'full'} align={'center'} justify={'center'}>
                <Text as={'span'} color={'textAdmin.300'}>{DataNomeMes(new Date(new Date(base.criacao).getTime()-(new Date().getTimezoneOffset() * 60000) ))}</Text>
                </Flex> 
                <BoxDialog param={alert}  />
            </Stack>  
        
    }else{
        setTimeout(()=>{setReload(false)})
        return null;
    }
    
}
import { Alert,Box, AlertDescription, AlertIcon, AlertTitle, Button, CloseButton, useDisclosure } from "@chakra-ui/react"
import { NavLink, Navigate } from "react-router-dom"
 

export function AlertBox({title='',desc='',url='',status='error',btName=''}){ 
    const { isOpen: isVisible } = useDisclosure({ defaultIsOpen: true })
    
    return isVisible ? (
        <Alert status={status}>
            <AlertIcon />
            <Box>
                <AlertTitle>
                    {title}
                </AlertTitle>
                <AlertDescription>
                    {desc}
                </AlertDescription>
            </Box>
                
            {url!=''?
                <NavLink to={url}>
                    <Button
                    zIndex={'10'}
                        position='absolute' 
                        right={'10px'}
                        top={'10px'}
                        minW={'100px'} 
                        variant={'ghost'} >
                        
                        {btName==''?'RESOLVER':''}
                    </Button> 
                </NavLink>
            :null }
            
        </Alert>
    ) : (
        null
    ) 
}
import {  Flex, Image, Stack, Text,Icon,colorMode, toggleColorMode, useColorMode, Input, Grid, GridItem, SimpleGrid, Progress, Toast} from "@chakra-ui/react"; 
import { useEffect, useState } from "react";
import LoadBg from "../../../components/backgound/LoadBg"; 
import { PageHK, SubHK } from "../../../components/heading/HeadingKalyst";
import { sendFormSocket } from "../../../components/session/SessionKalyst"; 
import { LuBox, LuEye, LuLineChart, LuPen, LuTrash, LuUser2, LuUsers2 } from "react-icons/lu"; 
import ListResponsive from "../../../components/list/ListResponsive";
import { ButtonKNew } from "../../../components/button/ButtonKalyst";
import ModalPage from "../../../components/modal/ModalPage";
import FormClint from "./FormApi";
import { LojaSelect } from "../../../layout/painel/MenuPainel";
import BoxDialog from "../../../components/modal/BoxDialog";


export function PageAPI(props){  
    const [open,setOpen]    = useState(false); 
    const [dados,setDados]  = useState(null); 
    const [edit,setEdit]    = useState({});  
    const [alert,setAlert]    = useState({}); 
    const hoje = new Date(); 
    let tempLoja = null;
    function BuscarDados(reload){ 
        if(LojaSelect !==tempLoja){
            tempLoja = LojaSelect
            reload = true; 
        } 
        sendFormSocket({campo:'pages', modulo:'api',cmd:'list',form:{reload,loja:LojaSelect},callback:(result)=>{  
            setDados(result)
        }});
    } 
    useEffect(()=>{ 
        BuscarDados(true);
        var interV =setInterval(()=>{
            BuscarDados(false);
        },500);
        
        return  ()=>{
            clearInterval(interV);
        }
    },[]);
 
    if(dados == null){ 
        return <LoadBg/>
    }else{ 
        const titles = [
            {name:'Nome',       campo:'nome'   ,w:4},
            {name:'Pública',    campo:'pub'    ,w:6}
        ];
        const lista = []
        if(dados.api.length>0){
            dados.api.forEach((api)=>{
                lista.push({id:api.id,title:api.nome,status:api.situa,  nome:api.nome,       pub:api.pub,  sec:api.sec})
            }) 
        } 
        return  ( 
            <Stack zIndex={3} w='full' gap={{base:'16px',md:'32px'}}  align={'flex-start'} justify='flex-start'   minH={'100vh'} pb='100px'  margin = '0 auto'  >
                <PageHK>Chaves de API</PageHK> 
                <ListResponsive titles={titles} dados={lista} cmd={
                    [
                        { icon:<LuEye/>,name:'Ver API', click:(d)=>{  
                            setEdit(d.id); 
                            setOpen(true); 
                        }}, 
                        { icon:<LuTrash/>,name:'Deletar', click:(d)=>{  
                            setAlert({load:true});
                            setTimeout(()=>{ 
                                setAlert({
                                fechar:true,
                                icon   : <Icon as={LuTrash} h={{base:'50px'}} w={{base:'50px'}} color='red.300' />, 
                                titulo:'Deletar Loja',
                                size:'md',
                                mensagem:'Deseja realmente deletar a loja <b>'+d.nome+'<b>?', 
                                botao:[
                                    {
                                        nome:'SIM', 
                                        cmd:()=>{
                                            setAlert({load:true});
                                            sendFormSocket({ modulo:'api',cmd:'deleteAPI',form:{id:d.id},callback:(result)=>{ 
                                                setTimeout(()=>{
                                                    setAlert({exit:true});
                                                    BuscarDados(false); 
                                                },500)
                                            }});
                                        }
                                    },
                                    {
                                        nome:'NÃO',
                                        variant:'ghost',
                                    }
                                ]
                                });
                            }) 
                        }}
                    ]
                } /> 
                <ButtonKNew onClick={()=>{ 
                    setOpen(true);
                    setEdit(null);
                }}>Nova API</ButtonKNew>
                <ModalPage open={open}  setOpen={setOpen}>
                    <FormClint   open={open} setOpen={(el)=>{ 
                        setOpen(el)
                    }} edit={edit} setEdit={setEdit}/>
                </ModalPage>
                <BoxDialog param={alert}  />
            </Stack> 
        )
    }
    
}
import { Box, Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input,InputGroup, InputLeftAddon, InputLeftElement, InputRightAddon, InputRightElement, others, Text, Icon, Skeleton, Flex, Toast, useClipboard, useToast} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { LuEye ,LuEyeOff } from "react-icons/lu";
import { getMaskString } from "./rhf-funcion-mask"; 
import { LinkK } from "../button/LinkKalyst";
import { ButtonKalyst } from "../button/ButtonKalyst";
export function InputKCopy({label='',as='',name='',loaded=true,disabled=false,placeholder='',variant='solid',leftAW='4.5em',ref=null,value=null,w='full',autoComplete=true,chromeFake = false,step='0',max='',focus:defaultFocus = false, leftA=null,leftE=null,rightA=null,rightE,type='text',help='',required=false,initial={},onChange,onBlur,error:errorForm={},...others}){
    const ip = useRef(null); 
    const [input, setInput] = useState((typeof initial[name]==='undefined')?'':getMaskString({ value: initial[name], type: type, step, max }).result);  
    const [hidden, setHidden] = useState(type==='password'?true:false);  
    const [focus, setFocos] = useState(defaultFocus); 
    const [errorI,setErrorI]= useState('')
    const { onCopy,value:valueCopy,  setValue, hasCopied } = useClipboard('');
    const toast = useToast();

    let errorM = '';
    let isError = false;  
    if(errorI!==''){ 
        if(input !==''){
            errorM= errorI;
            isError = true;
        }else{
            setErrorI('')
        } 
    }else if(Object.keys(errorForm).length>0){  
        if(typeof errorForm[name] !== 'undefined'){
            errorM= errorForm[name]
            isError = true;
        }  
    } 
    useEffect(() => {
        if (focus) {
            try {
                ip.current.focus();
            } catch (error) {
                
            } 
        }
    }, [focus]); 
    useEffect(()=>{
        // if(focus){
            // setOpen(true); 
        // }
    }) 
    let viewType = 'text';
    switch (type) {
        case 'cnjp':case 'cpf': case 'cnpj': case 'number': case 'decimal':
            viewType= 'tel'
        break;
        case 'date':
              viewType = 'date'; 
        break;  
        case 'password':
            if(hidden){
                viewType = 'password';
            }else{
                viewType = 'text';
            } 
        break;  
    } 
    if(input!==valueCopy){
        setTimeout(()=>{
            setValue(input);
        }) 
    } 
    let open = (focus?true:false);  
        return (
            <>
                <FormControl color={'textLight'} w={w}  maxW={w}    _dark={{ color:'darkTema1.300'}}  isInvalid={isError} isRequired={isError}>
                    <FormLabel fontSize={{base:'10px',md:'12px',lg:'14px'}} _dark={{ color:'textDark.100'}} title={label} noOfLines={1} >{label}</FormLabel>
                    
                    <Skeleton isLoaded={loaded}>
                        <Flex align={'center'}  borderRadius='4px'  border='1px solid' borderColor={'gray.600'}>
                            <InputGroup  position={'relative'} size={{base:'sm',md:'md',lg:'lg'}}    pr='0px'   _hover={{}} borderRight={'0px'} {...others} >
                                {leftA===null?null:<InputLeftAddon  border='none'   borderRadius='0' bg={variant=='admin'?'textAdmin.300':'lightTema1.300'} color={'textDark.100'} fontSize={{base:'13px',md:'14px',lg: '16px'}} px='0px' w={'calc('+leftAW+' + 0.5em)'} justifyContent={'center'}>{leftA}</InputLeftAddon>}
                                {leftE===null?null:<InputLeftElement pointerEvents='none'  fontSize='1.2em' color='textLight.300' noOfLines={'1'}>{leftE}</InputLeftElement>}
                                <Input   as={as} lineHeight={'20px'}  zIndex={1} position={'relative'} readOnly={1} type={viewType}  ref={(ref==null?ip:ref)} resize={'none'}    display={'block'}   data-test={"input."+type}  fontFamily={'Inter'} autoComplete={name}
                                onBlur={(e)=>{ 
                                    var validar = getMaskString({ value: e.target.value, type: type,  step, max  });
                                    if(validar.valid){
                                        setErrorI('')
                                    }else{
                                        setErrorI(validar.message)
                                    } 
                                    setFocos(false); 
                                    if(typeof onBlur === 'function'){
                                        onBlur(e.target.value);
                                    }
                                    if(e.target.value.trim()!==e.target.value){
                                        setInput(e.target.value.trim()); 
                                    }
                                }} 
                                onFocus={(e)=>{
                                    setErrorI('')
                                }}
                                onChange={(e)=>{  
                                    if(e.target.value===' '){
                                        setInput('')
                                    }else{ 
                                        if(type == 'decimal'){
                                            if(e.target.value!==''){
                                                setInput(getMaskString({ value: e.target.value , type: type, step, max }).result.replace('.',',')) 
                                            } 
                                        }else{
                                            setInput(getMaskString({ value: e.target.value , type: type, step, max }).result) 
                                        } 
                                        if(typeof onChange === 'function'){
                                            onChange(getMaskString({ value: e.target.value , type: type, step, max }).result);
                                        }
                                    }
                                    
                                    
                                }} value={input} borderRadius='3px' border='none' bg='transparent'  placeholder={placeholder}   name={name} _dark={{color:'textDark.100'}}/>
                                {rightA===null?null:<InputRightAddon  border='none'  borderRadius='0' bg='lightTema1.300' color={'textDark.100'}>{rightA}</InputRightAddon>}
                                {type==='password'?<InputRightElement cursor={'pointer'}  border='none'   borderRadius='0' color='lightTema1.300'  _hover={{}} onClick={()=>{
                                    if(hidden){
                                        setHidden(false);
                                    }else{
                                        setHidden(true);
                                    }
                                }}>{hidden?<Icon as={LuEye}/>:<Icon as={LuEyeOff}/>}</InputRightElement>:null}
                                
                            </InputGroup>
                            <Button onClick={()=>{
                                  setValue(input)
                                  onCopy();
                                  toast({
                                      title: 'Dados Copiado',
                                      description: 'Dados copiado para memória',
                                      status: 'success',
                                      position:'top-right',
                                      duration: 6000,
                                      isClosable: true,
                                  }); 
                            }} minW={'auto'}  borderRadius='0 4px 4px 0'  pr='0px'  size={{base:'sm',md:'md',lg:'lg'}} borderColor={'gray.500'} px={'0'} variant={variant}>COPY</Button>
                        </Flex>
                        
                    </Skeleton>
                    
                    
                     
                    <Box   h={{base:'12px', md:'13px',lg:'14px'}} pt='4px' >
                        {!isError ? (
                            <FormHelperText noOfLines={1}  data-test='error-help' p={0} m='0'  h={{base:'12px', md:'13px',lg:'14px'}} fontSize={{base:'12px', md:'13px',lg:'14px'}}>
                                {help}
                            </FormHelperText>
                        ) : (
                            <FormErrorMessage  data-test='error-message'  p={0} m='0'  h={{base:'12px', md:'13px',lg:'14px'}} fontSize={{base:'12px', md:'13px',lg:'14px'}}><Text noOfLines={'1'} color={'red'} title={errorM}>{errorM}</Text></FormErrorMessage>
                        )}  

                    </Box> 
                </FormControl> 
            </>
        ); 
}
export function TextareaKCopy({label='',as='',name='',loaded=true,disabled=false,placeholder='',rsa=false,file='',variant='solid',leftAW='4.5em',h='140px',ref=null,value=null,w='full',autoComplete=true,chromeFake = false,step='0',max='',focus:defaultFocus = false, leftA=null,leftE=null,rightA=null,rightE,type='text',help='',required=false,initial={},onChange,onBlur,error:errorForm={},...others}){
    const ip = useRef(null); 
    const [input, setInput] = useState((typeof initial[name]==='undefined')?'':getMaskString({ value: initial[name], type: type, step, max }).result);  
    const [hidden, setHidden] = useState(type==='password'?true:false);  
    const [focus, setFocos] = useState(defaultFocus); 
    const [errorI,setErrorI]= useState('')
    const { onCopy,value:valueCopy,  setValue, hasCopied } = useClipboard('');
    const toast = useToast();

    let errorM = '';
    let isError = false;  
    if(errorI!==''){ 
        if(input !==''){
            errorM= errorI;
            isError = true;
        }else{
            setErrorI('')
        } 
    }else if(Object.keys(errorForm).length>0){  
        if(typeof errorForm[name] !== 'undefined'){
            errorM= errorForm[name]
            isError = true;
        }  
    } 
    useEffect(() => {
        if (focus) {
            try {
                ip.current.focus();
            } catch (error) {
                
            } 
        }
    }, [focus]); 
    useEffect(()=>{
        // if(focus){
            // setOpen(true); 
        // }
    }) 
    let viewType = 'text';
    switch (type) {
        case 'cnjp':case 'cpf': case 'cnpj': case 'number': case 'decimal':
            viewType= 'tel'
        break;
        case 'date':
              viewType = 'date'; 
        break;  
        case 'password':
            if(hidden){
                viewType = 'password';
            }else{
                viewType = 'text';
            } 
        break;  
    } 
    if(input!==valueCopy){
        setTimeout(()=>{
            setValue(input);
        }) 
    } 
    let open = (focus?true:false);  
        return (
            <>
                <FormControl color={'textLight'} w={w}  maxW={w}    _dark={{ color:'darkTema1.300'}}  isInvalid={isError} isRequired={isError}>
                <Flex w={'100%'} align={'center'} justify={'space-between'}>
                    <FormLabel fontSize={{base:'10px',md:'12px',lg:'14px'}} _dark={{ color:'textDark.100'}} title={label} noOfLines={1} >{label} </FormLabel>
                    {
                        rsa? <ButtonKalyst variant={variant} mb='10px' fontSize={{base:'10px',md:'12px',lg:'14px'}} size='sm' _dark={{ color:'textDark.100'}} title={label} noOfLines={1}  
                        onClick={(e)=>{ 
                            e.preventDefault();
                            const element = document.createElement("a");
                            const file = new Blob([input], {type: 'text/plain'}); 
                            element.href = URL.createObjectURL(file);
                            element.download = "public.pem";
                            document.body.appendChild(element); // Required for this to work in FireFox 
                            element.click();
                            document.body.removeChild(element);
                        }}>
                            Baixar Public.pem
                    
                        </ButtonKalyst>:false
                    } 
                    </Flex>
                    <Skeleton isLoaded={loaded}>
                        <Flex direction={'column'} align={'center'}  borderRadius='4px'  border='1px solid' borderColor={'gray.600'}>
                            <InputGroup  position={'relative'} size={{base:'sm',md:'md',lg:'lg'}}   pr='0px'   _hover={{}} borderRight={'0px'} {...others} >
                                {leftA===null?null:<InputLeftAddon  border='none'   borderRadius='0' bg={variant=='admin'?'textAdmin.300':'lightTema1.300'} color={'textDark.100'} fontSize={{base:'13px',md:'14px',lg: '16px'}} px='0px' w={'calc('+leftAW+' + 0.5em)'} justifyContent={'center'}>{leftA}</InputLeftAddon>}
                                {leftE===null?null:<InputLeftElement pointerEvents='none'  fontSize='1.2em' color='textLight.300' noOfLines={'1'}>{leftE}</InputLeftElement>}
                                <Input   as={'textarea'} py='16px' lineHeight={'14px'}  zIndex={1} position={'relative'} readOnly={1} type={viewType}  ref={(ref==null?ip:ref)} resize={'none'}    display={'block'}   data-test={"input."+type}  fontFamily={'Inter'}   autoComplete={name}
                                onBlur={(e)=>{ 
                                    var validar = getMaskString({ value: e.target.value, type: type,  step, max  });
                                    if(validar.valid){
                                        setErrorI('')
                                    }else{
                                        setErrorI(validar.message)
                                    } 
                                    setFocos(false); 
                                    if(typeof onBlur === 'function'){
                                        onBlur(e.target.value);
                                    }
                                    if(e.target.value.trim()!==e.target.value){
                                        setInput(e.target.value.trim()); 
                                    }
                                }} 
                                onFocus={(e)=>{
                                    setErrorI('')
                                }}
                                onChange={(e)=>{  
                                    if(e.target.value===' '){
                                        setInput('')
                                    }else{

                                        if(type == 'decimal'){
                                            if(e.target.value!==''){
                                                setInput(getMaskString({ value: e.target.value , type: type, step, max }).result.replace('.',',')) 
                                            } 
                                        }else{
                                            setInput(getMaskString({ value: e.target.value , type: type, step, max }).result) 
                                        } 
                                        if(typeof onChange === 'function'){
                                            onChange(getMaskString({ value: e.target.value , type: type, step, max }).result);
                                        }
                                    }
                                    
                                    
                                }} value={input} borderRadius='3px' border='none' bg='transparent'  placeholder={placeholder}   name={name} h={h} fontSize={'12px'} _dark={{color:'textDark.100'}}/>
                                {rightA===null?null:<InputRightAddon  border='none'  borderRadius='0' bg='lightTema1.300' color={'textDark.100'}>{rightA}</InputRightAddon>}
                                {type==='password'?<InputRightElement cursor={'pointer'}  border='none'   borderRadius='0' color='lightTema1.300'  _hover={{}} onClick={()=>{
                                    if(hidden){
                                        setHidden(false);
                                    }else{
                                        setHidden(true);
                                    }
                                }}>{hidden?<Icon as={LuEye}/>:<Icon as={LuEyeOff}/>}</InputRightElement>:null}
                                
                            </InputGroup>
                            <Button onClick={()=>{
                                  setValue(input)
                                  onCopy();
                                  toast({
                                      title: 'Dados Copiado',
                                      description: 'Dados copiado para memória',
                                      status: 'success',
                                      position:'top-right',
                                      duration: 6000,
                                      isClosable: true,
                                  }); 
                            }} w={'full'}   borderRadius='0 0 3px 3px'  pr='0px'  size={{base:'sm',md:'sm',lg:'md'}} borderColor={'gray.500'} px={'0'} variant={variant}>COPY</Button>
                        </Flex>
                        
                    </Skeleton>
                    
                    
                     
                    <Box   h={{base:'12px', md:'13px',lg:'14px'}} pt='4px' >
                        {!isError ? (
                            <FormHelperText noOfLines={1}  data-test='error-help' p={0} m='0'  h={{base:'12px', md:'13px',lg:'14px'}} fontSize={{base:'12px', md:'13px',lg:'14px'}}>
                                {help}
                            </FormHelperText>
                        ) : (
                            <FormErrorMessage  data-test='error-message'  p={0} m='0'  h={{base:'12px', md:'13px',lg:'14px'}} fontSize={{base:'12px', md:'13px',lg:'14px'}}><Text noOfLines={'1'} color={'red'} title={errorM}>{errorM}</Text></FormErrorMessage>
                        )}  

                    </Box> 
                </FormControl> 
            </>
        ); 
}
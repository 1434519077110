import {  Flex, Image, Stack, Text,Icon,colorMode, toggleColorMode, useColorMode, Input, Grid, GridItem, SimpleGrid, Progress, Tag, PinInput, PinInputField, HStack, useToast, TabList, Tab, Tabs, TabPanel, TabPanels} from "@chakra-ui/react"; 
import { useEffect, useState } from "react";
import LoadBg from "../../../components/backgound/LoadBg"; 
import { PageHK, SubHK } from "../../../components/heading/HeadingKalyst";
import { sendFormSocket, user } from "../../../components/session/SessionAdmin"; 
import { setSession } from "../../../components/session/SessionKalyst"; 
import { LuBox, LuLineChart, LuPen, LuTrash, LuUser2, LuUsers2 } from "react-icons/lu"; 
import ListResponsive, { ListaResultadoResponsive, ListPageResponsive } from "../../../components/list/ListResponsive";
import { ButtonKNew } from "../../../components/button/ButtonKalyst";
import ModalPage from "../../../components/modal/ModalPage";
import FormClint from "./FormClient";
import { LojaSelect } from "../../../layout/painel/MenuPainel";
import BoxDialog from "../../../components/modal/BoxDialog";
import ListBox from "../../../components/list/ListBox";
import { MdBlock, MdLogin, MdSecurity } from "react-icons/md";
import FormDocumentClient from "./FormDocumentClient";
import { TextK1 } from "../../../components/text/TextKalyst";
import FormAdquirenteCliente from "./FormAdquirenteCliente";
import Box2Fa from "../../../components/2fa/Box2Fa";
import FilterPainel from "../../../components/filter/FilterPainel";
import { getFilter } from "../../../components/filter/FilterDate";
import md5 from "md5";
import { InputK } from "../../../components/input/InputKalyst";
import { DataPickerKalyst } from "../../../components/input/DataPickerKalyst";
import SelectK from "../../../components/select/SelectKalyst";

let session   = null;
export function PageAdminClient(props){  
    const [open,setOpen]    =   useState(false); 
    const [dados,setDados]  =   useState(null); 
    const [edit,setEdit]    =   useState({}); 
    const [alert,setAlert]  =   useState({});  
    const [view2fa,setView2fa]    =   useState(null); 
    const toast = useToast(); 
    const [viewBox,setViewBox]    =   useState(null)
    const hoje = new Date(); 
    let tempLoja = null;  
    let tempFilter = null;
    let dFilter   = null
    function BuscarDados(reload){  
        session = md5(user.id+'useradminv2');
        dFilter = getFilter(session); 
        if(LojaSelect !==tempLoja || dFilter.up !== tempFilter){
            tempLoja = LojaSelect
            tempFilter = dFilter.up
            reload = true; 
        }  
        sendFormSocket({campo:'pages', modulo:'user',cmd:'list',form:{reload,loja:LojaSelect,filtro:dFilter},callback:(result)=>{  
            setDados(result)
        }});
    } 
    useEffect(()=>{ 
        BuscarDados(true);
        var interV =setInterval(()=>{
            BuscarDados(false);
        },500);
        
        return  ()=>{
            clearInterval(interV);
        }
    },[]);

    if(dados == null){ 
        return <LoadBg/>
    }else{ 
        const titles = [
            {name:'Telefone',   campo:'telefone'        ,w:2}, 
            {name:'Documento',  campo:'documento'       ,w:2}, 
            {name:'Data',       campo:'criacao'         ,w:1}   
        ];
        const lista = [] 
        dFilter = getFilter(session);  
        let initial = {}
        try {
            Object.keys(dFilter.filtro).forEach((key)=>{
                initial[key]=dFilter.filtro[key].value;
            })
        } catch (error) {
            
        }  
        if(dados.users.length>0){
            dados.users.forEach((cli)=>{
                lista.push({
                    id          :   cli.id,
                    title       :   cli.nome, 
                    sub         :   <>
                        <Text fontSize={{base:'10px',md:'12px'}} color={'textAdmin.100'}>Total Vendas</Text>
                        <Text fontSize={{base:'10px',md:'12px'}} color={'textAdmin.300'}>R$ {(cli.saldos.vendas*0.01  ).toLocaleString('pt-BR',{minimumFractionDigits:2})}</Text>
                    </>,
                    nome        :   cli.nome,  
                    documento   :   cli.documento,  
                    telefone    :   cli.telefone,  
                    email       :   cli.email,     
                    nlojas      :   cli.nlojas, 
                    status      :   <Tag size={'sm'} minW={'max-content'} colorScheme={cli.status.c}>{cli.status.n}</Tag>,  
                    criacao     :   new Date(cli.criacao).toLocaleDateString('pt-BR', {  month: '2-digit', day: '2-digit',hour:'2-digit',minute:'2-digit'})
                })
            }) 
        }  

        let fastV='';
        try {
            fastV = dFilter.filtro.fast.value;
        } catch (error) {
            
        }  
        const viewFilter = (
            <Stack gap={'0px'}> 
            <Tabs variant='soft-rounded'  color={'#ffffff'} size={'sm'}   pt={'0px'} px={'16px'}  pb='0'>
                    <TabList>
                        <Tab fontSize={'10px'} color={'#ffffff'} variant='admin' >Nome</Tab>
                        <Tab fontSize={'10px'} color={'#ffffff'} >Documento</Tab>
                        <Tab fontSize={'10px'} color={'#ffffff'} >Email</Tab>
                        <Tab fontSize={'10px'} color={'#ffffff'} >Telefone</Tab>
                    </TabList>
                    <TabPanels px={'0'}  pb='0'>
                        <TabPanel  px={'0'} pb='0' >
                            <InputK  focus={true}  type={'text'} label="Cliente Nome" size='sm'  name="LIKE_AND-nome" placeholder={"Nome completo "}   autoComplete={false} initial={initial}/>  
                        </TabPanel>
                        <TabPanel  px={'0'} pb='0px'>
                            <InputK  focus={false}  type={'cpfcnpj'} label="Cliente Documento" size='sm'  name="LIKE_AND-documento" placeholder={"cpf ou cnpj"}   autoComplete={false} initial={initial}/>
                        </TabPanel>
                        <TabPanel  px={'0'} pb='0px'>
                            <InputK  focus={false}  type={'email'} label="Cliente E-mail" size='sm'  name="LIKE_AND-email" placeholder={"email válido"}   autoComplete={false} initial={initial}/>
                        </TabPanel>
                        <TabPanel  px={'0'} pb='0px'>
                            <InputK  focus={false}  type={'phone'} label="Cliente Telefone" size='sm'  name="LIKE_AND-telefone" placeholder={"ddd + número"}   autoComplete={false} initial={initial}/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
                <Stack gap='0px' px='16px'  > 
                    <SelectK label={'Status'} placeholder="Selecione" size='sm'    value={[{value:'Aprovado',name:'Aprovado'},{value:'Adquirente',name:'Adquirente'},{value:'Documentos',name:'Documentos'},{value:'Reprovado',name:'Reprovado'},{value:'Pendente',name:'Pendente'},{value:'Bloqueado',name:'Bloqueado'},{value:'Não Validados',name:'Não Validados'}]} name='IGUAL_AND-fase' initial={initial}/>
                </Stack>
                <DataPickerKalyst  name="DATA_PERIODO-criacao" max='hoje'  initial={initial}/>
                {/* 
                <Tabs variant='soft-rounded' colorScheme='green' size={'sm'}   pt={'0px'} px={'16px'}  pb='0'>
                    <TabList>
                        <Tab fontSize={'10px'}>Nome</Tab>
                        <Tab fontSize={'10px'}>Documento</Tab>
                        <Tab fontSize={'10px'}>Email</Tab>
                        <Tab fontSize={'10px'}>Telefone</Tab>
                    </TabList>
                    <TabPanels px={'0'}  pb='0'>
                        <TabPanel  px={'0'} pb='0' >
                            <InputK  focus={true}  type={'text'} label="Lojista Fantasia" size='sm'  name="LIKE_AND-sellerNome" placeholder={"Nome completo "}   autoComplete={false} initial={initial}/>  
                        </TabPanel>
                        <TabPanel  px={'0'} pb='0' >
                            <InputK  focus={true}  type={'text'} label="Lojista Fantasia" size='sm'  name="LIKE_AND-sellerFantasia" placeholder={"Nome completo "}   autoComplete={false} initial={initial}/>  
                        </TabPanel>
                        <TabPanel  px={'0'} pb='0px'>
                            <InputK  focus={false}  type={'cpfcnpj'} label="Lojista Documento" size='sm'  name="LIKE_AND-sellerDocumento" placeholder={"cpf ou cnpj"}   autoComplete={false} initial={initial}/>
                        </TabPanel> 
                        <TabPanel  px={'0'} pb='0px'>
                            <InputK  focus={false}  type={'phone'} label="Lojista Telefone" size='sm'  name="LIKE_AND-sellerTelefone" placeholder={"ddd + número"}   autoComplete={false} initial={initial}/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
                
                  */}
            </Stack> 
        )  
        let tempReload = null;   
        return  ( 
            <Stack zIndex={3} w='full' gap={{base:'16px',md:'32px'}}  align={'flex-start'} justify='flex-start'   minH={'100vh'} pb='100px'  margin = '0 auto'  >
                <PageHK>Clientes</PageHK> 
                <Stack gap='0px' w={'full'} >  
                    <FilterPainel  variant='admin' dFilter={dFilter} session={session}  
                        onChange={()=>{
                            clearTimeout(tempReload);
                            tempReload = setTimeout(()=>{  
                                BuscarDados(true); 
                            },400) 
                        }} 
                        ordem={[
                            {id:'id_desc'       ,name:'Mais Recentes'},
                            {id:'id_asc'        ,name:'Menos Recentes'},
                            {id:'vendas_desc'   ,name:'Maior Vendas'},
                            {id:'vendas_asc'    ,name:'Menor Vendas'}, 
                            {id:'nome_desc'   ,name:'Nome [Z-A]'},
                            {id:'nome_asc'    ,name:'Nome [A-Z]'},

                            {id:'fase_desc'   ,name:'Status [Z-A]'},
                            {id:'fase_asc'    ,name:'Status [A-Z]'}
                        ]}
                        fast={['vendas','fase','nome']}
                        viewFilter={viewFilter}
                    />  
                    <ListaResultadoResponsive session={session}  page={dados.page} pages={[12,24,48,96]}/>
                    <ListBox titles={titles} dados={lista} cmd={[
                        { icon:<LuPen/>,name:'Adquirentes', click:(d)=>{   
                            setViewBox(
                                <FormAdquirenteCliente  title={d.nome+' -  adquirentes'}  open={open} setOpen={(el)=>{ 
                                    setOpen(el)
                                    setEdit(null); 
                                    setViewBox(null)
                                }} edit={d.id} setEdit={setEdit}/>
                            );  
                            setEdit(d.id);  
                            setOpen(true);
                        }}, 
                        { icon:<LuPen/>,name:'Dados', click:(d)=>{   
                            setViewBox(<FormDocumentClient  title={'Aprovação de Cliente'}  open={open} setOpen={(el)=>{ 
                                setOpen(el)
                                setEdit(null); 
                                setViewBox(null)
                            }} edit={d.id} setEdit={setEdit}/>);  
                            setEdit(d.id);  
                            setOpen(true);
                        }}, 
                        { icon:<MdLogin/>,name:'Acessar', click:(d)=>{   
                            setView2fa(<Box2Fa callback={(sendCode)=>{ 
                                sendFormSocket({ modulo:'user',cmd:'login2fa',form:{code:sendCode,user:d.id},callback:(result)=>{ 
                                    if(result.erro){
                                        toast({
                                            title:result.titulo,
                                            description: result.mensagem[0].mensagem,
                                            status: 'error',
                                            position:'top-right',
                                            duration: 6000,
                                            isClosable: true,
                                        });
                                    }else{
                                        toast({
                                            title:'Acesso Liberado',
                                            description: 'Foi aberto uma nova janela com o acesso ao cliente:'+d.nome,
                                            status: 'success',
                                            position:'top-right',
                                            duration: 6000,
                                            isClosable: true,
                                        });    
                                        setSession(result.hash);
                                        setTimeout(()=>{
                                            window.open((window.location.protocol+'//'+window.location.host+'/'), '_blank');
                                        },500);
                                        setView2fa(null); 
                                    };
                                }}); 
                            }} onClose={()=>{
                                setView2fa(null); 
                                console.log('close lista')
                            }} titulo='Acessar Conta' mensagem={d.nome} variant={'admin'}
                            />);
                        
                        }},
                        { icon:<MdBlock/>,name:'Bloquear', click:(d)=>{  
                            setAlert({load:true});
                            setTimeout(()=>{ 
                                setAlert({
                                fechar:true,
                                icon   : <Icon as={MdBlock} h={{base:'50px'}} w={{base:'50px'}} color='red.300' />, 
                                titulo:'Bloquear Cliente',
                                size:'md',
                                mensagem:'Deseja realmente bloquear o cliente <b>'+d.nome+'<b>?', 
                                botao:[
                                    {
                                        nome:'SIM', 
                                        cmd:()=>{
                                            setAlert({load:true}); 
                                            sendFormSocket({ modulo:'client',cmd:'blockUser',form:{codusu:d.id},callback:(result)=>{ 
                                                setTimeout(()=>{
                                                    setAlert({exit:true});
                                                    BuscarDados(false); 
                                                },500)
                                            }});
                                        }
                                    },
                                    {
                                        nome:'NÃO',
                                        variant:'ghost',
                                    }
                                ]
                                });
                            }) 
                        }}, 
                        { icon:<LuTrash/>,name:'Deletar', click:(d)=>{  
                            setAlert({load:true});
                            setTimeout(()=>{ 
                                setAlert({
                                fechar:true, 
                                icon   : <Icon as={LuTrash} h={{base:'50px'}} w={{base:'50px'}} color='red.300' />, 
                                titulo:'Deletar Cliente',
                                size:'md',
                                mensagem:'Deseja realmente deletar o cliente <b>'+d.nome+'<b>?', 
                                botao:[
                                    {
                                        nome:'SIM', 
                                        cmd:()=>{
                                            setAlert({load:true});
                                            sendFormSocket({ modulo:'client',cmd:'deleteUser',form:{codusu:d.id},callback:(result)=>{ 
                                                setTimeout(()=>{
                                                    setAlert({exit:true});
                                                    BuscarDados(false); 
                                                },500)
                                            }});
                                        }
                                    },
                                    {
                                        nome:'NÃO',
                                        variant:'ghost',
                                    }
                                ]
                                });
                            }) 
                        }}
                    ]} />  
                    <ListPageResponsive onChange={()=>{ 
                        tempLoja = null;
                        BuscarDados(true);
                    }} session={session}  page={dados.page} />
                </Stack>
                 <ModalPage  open={open}   isCentered={false} size="'full" setOpen={setOpen}>
                    {viewBox}
                </ModalPage> 
                {view2fa}
                <BoxDialog param={alert}  />
            </Stack> 
        )
    }
    
}
import { Flex, Grid, GridItem, HStack, SimpleGrid, Stack, Text, Textarea,Icon, IconButton, Skeleton, Input, Tag, Tooltip } from "@chakra-ui/react";
import { HK4, HK5, PageHK } from "../../../components/heading/HeadingKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { object, string } from "yup";
import { SendForm } from "../../../components/form/SendForm";
import { TextK1 } from "../../../components/text/TextKalyst";
import { LinkK } from "../../../components/button/LinkKalyst";
import { useEffect, useRef, useState } from "react";
import SelectK from "../../../components/select/SelectKalyst";
import { sendFormSocket } from "../../../components/session/SessionKalyst"; 
import { LuCheck, LuDownload, LuImport, LuPackage2, LuTruck, LuUpload } from "react-icons/lu";
import { CardPrice } from "../../../components/card/CardValues";
import { ExportCSV, ImportCSV, parseValor, validateSITE, viewPrice } from "../../../components/Function";
import { LojaSelect } from "../../../layout/painel/MenuPainel";  


import React from 'react'  
import { MdWarning } from "react-icons/md";


export default function FormReports( {open,setOpen,edit = null,setEdit}){  
    const [load,setLoadD] = useState(false);
    const [base,setBase] = useState({saldoD:0}); 
    const [info,setInfo] = useState({});
    const [reload,setReload] = useState(false);
    
    const [lista,setLista] = useState(null);
    const ip = useRef(null);
    function buscarDados(){
        sendFormSocket({ modulo:'reports',cmd:'pending',form:{loja:LojaSelect},callback:(result)=>{  
            setLoadD(true);
            setInfo(result.info);
            setBase(result.dados); 
            setReload(true); 
        }});
    } 
    useEffect(()=>{  
        setTimeout(()=>{
            buscarDados()
        },100); 
    },[]); 

    if(!reload){  
         
        if(lista != null){
            var send  = [];
            var dados = [];
            var erro = false; 
            var erro2 = false;
            lista.forEach((l)=>{ 
                erro = false;
                erro2 = false; 
                var link =l.site_rastreio;
                if(typeof l.site_rastreio == 'undefined' || l.site_rastreio.trim() == ''){
                    link = <Tooltip label={'Link: Vazio' } fontSize='md'><Tag colorScheme={'red'} size='sm'>Link inválido</Tag></Tooltip>; 
                    erro = true; 
                }else{ 
                    if(!validateSITE(l.site_rastreio)){
                        link = <Tooltip label={'Link: inválido'} fontSize='md'><Tag colorScheme={'red'} size='sm'>Link inválido</Tag></Tooltip>;   
                        erro = true;
                    }  
                }
                try {
                    if(typeof l.code_rastreio == 'undefined' || l.code_rastreio.trim() == ''){
                        erro2 = true; 
                    }
                } catch (error) {
                    erro2 = true; 
                }
                
                dados.push({ 
                    id: l.id,
                    icon:( erro || erro2?<Icon as={MdWarning} fontSize='20px' color={'red.600'}/>:<Icon as={LuCheck} fontSize='20px' color={'green'}/>) ,
                    email: l.email,
                    name: l.name,
                    code_rastreio: (!erro2 ?l.code_rastreio:<Tooltip label={'Código: Vazio'} fontSize='md'><Tag colorScheme={'red'} size='sm'>Código inválido</Tag></Tooltip>),
                    site_rastreio: link,
                })  
                if(!erro && !erro2){
                    send.push({
                        id              :   l.id,
                        code_rastreio   :   l.code_rastreio,
                        site_rastreio   :   l.site_rastreio
                    })
                }
            })
            
            return <Stack>
            {send.length>0?
                <SendForm  dados = {{modulo:'reports',cmd:'tracking',form:{rastreios:send,loja:LojaSelect}}}   
                    callback={(result)=>{  
                        setOpen(false); 
                    }}
                    gap={'10px'}
                    > 
                    
                    <PageHK   textAlign='left'>Salvar Rastreios</PageHK>   
                
                    <TextK1   textAlign='left'>Deseja realmente <Text as={'span'} fontWeight='bold'>{send.length}/{dados.length}</Text> salvar esses rastreios?</TextK1> 
                    <Flex w={'full'} gap={'20px'} align={'center'}  justify={'flex-end'}> 
                        <ButtonKalyst loaded={load}  >
                            Salvar Rastreios
                        </ButtonKalyst> 
                        <LinkK onClick={()=>{
                            setReload(true);
                            setLista(null)
                        }}>
                            Cancelar
                        </LinkK>
                    </Flex>
                </SendForm>:
                <Stack>
                    <PageHK   textAlign='left'>Falha na Importação</PageHK> 
                    <TextK1   textAlign='left'>Nenhum Rastreio foi localizado, preencha as colunas <Text as={'span'} fontWeight='bold'>code_rastreio e site_rastreio</Text> </TextK1> 
                    <Flex w={'full'} gap={'20px'} align={'center'}  justify={'flex-end'}>  
                        <LinkK onClick={()=>{
                            setReload(true);
                            setLista(null)
                        }}>
                            Tentar Novamente
                        </LinkK>
                    </Flex>
                </Stack>
            }   
          
            <PageHK   textAlign='left'>Dados de Envio</PageHK> 
                {dados.length>0?dados.map((l,key)=>{ 
                    return <Flex direction={{base:'column',md:'row'}} fontSize={'10px'} w='full' key={'mpa'+key}  borderRadius='8px'  justify='space-between'    bg='lightTema1.200' _dark={{bg:'darkTema1.100'}} p='5px' gap={'10px'} align='center'>
                        
                        {l.icon}
                        <Flex w={{base:'calc(100% - 40px)',md:'calc(35%  - 40px)'}}>
                            <Text color='textLight.100' _dark={{color:'textDark.100'}} noOfLines='1'>{key+1} - {l.id}</Text>
                        </Flex> 
                        <Flex   w={{base:'calc(100% - 40px)',md:'calc(20%)'}} justify='center'>
                            <Text color='textLight.100' _dark={{color:'textDark.100'}} noOfLines={1}>{l.code_rastreio}</Text> 
                        </Flex>
                         <Flex   w={{base:'calc(100% - 40px)',md:'calc(45%)'}} justify='flex-end'>
                            <Text color='textLight.100' _dark={{color:'textDark.100'}} noOfLines={1}>{l.site_rastreio}</Text>  
                        </Flex> 
                    </Flex>
                }):null}
            </Stack>
        }else{
            return <Stack>
                <PageHK   textAlign='left'>Rastreio Pedidos</PageHK>
                <Grid
                    w={'full'}  
                    templateColumns={{base:'repeat(4, 1fr)'}}
                    gap={'16px'}
                    py='16px'
                >  
                    <GridItem colSpan={2}  > 
                        <CardPrice  load={load} icon={LuPackage2} title='Pedidos' sigla={'N°'} value={info.nPed} 
                            info={<Text color={'orange.500'} fontWeight='bold'>Pendentes</Text>}/> 
                    </GridItem> 
                    <GridItem colSpan={2}  >
                        <CardPrice load={load} icon={LuTruck} title='Valor' value={'R$ '+viewPrice(info.vPed)}
                        info={<Text color={'orange.500'} fontWeight='bold'>Saldo Bloqueado</Text>}/>
                    </GridItem> 
                </Grid>
                {base.length>0?
                    <Skeleton isLoaded={load}> 
                        <Flex  w={'full'}  gap={'16px'}>  
                            <Stack  w={'50%'}  borderRadius='8px'  justify='space-between'  direction={{base:'column',md:'row'}}  bg='lightTema1.200' _dark={{bg:'darkTema1.100'}} p='16px' gap={'10px'} align='center'>
                                <Flex w={'full'} gap={'10px'}>
                                    <Icon as={LuUpload} h='30px' w='40px'/>
                                    <Stack gap={'0px'}>
                                        <Text fontSize={'12px'} color={'textLight.100'} _dark={{color:'textDark.100'}}>Exportar</Text>
                                        <Text fontSize={'12px'} color={'textLight.100'} _dark={{color:'textDark.100'}}> Rastreios Pendentes</Text> 
                                    </Stack>
                                </Flex>
                                <Flex  gap={'10px'}> 
                                    <ExportCSV csvData={base} fileName={'rastreio-'+new Date().toISOString()} /> 
                                </Flex> 
                            </Stack>
                            <Stack  w={'50%'} borderRadius='8px'  justify='space-between' direction={{base:'column',md:'row'}}  bg='lightTema1.200' _dark={{bg:'darkTema1.100'}} p='16px' gap={'10px'} align='center'>
                                <Flex w={'full'} gap={'10px'}>
                                    <Icon as={LuImport} h='30px' w='40px'/>
                                    <Stack gap={'0px'}>
                                        <Text fontSize={'12px'} color={'textLight.100'} _dark={{color:'textDark.100'}}>Importar</Text>
                                        <Text fontSize={'12px'} color={'textLight.100'} _dark={{color:'textDark.100'}}>Rastreios Pendentes</Text> 
                                    </Stack>
                                </Flex> 
                                <Flex  gap={'10px'} position='relative'>  
                                    
                                    <Input ref={ip}  type={'file'}  position={'absolute'} opacity={0} onChange={(e)=>{  
                                        ImportCSV(e,(redt)=>{
                                            if(!redt.error){
                                                setLista(redt.dados)
                                            }
                                        
                                        })
                                    }} h={'100%'} w={'100%'}   variant={'ghost'} accept=".xlsx, .xls, .csv" minW={'initial'} cursor={'pointer'}/>
                                    <ButtonKalyst w={'full'}  h='30px' leftIcon={<LuUpload/>} onClick={()=>{
                                        ip.current.click(); 
                                    }}  >Enviar</ButtonKalyst>  
                                </Flex> 
                            </Stack> 
                        </Flex> 
                    </Skeleton>:
                    <Text fontSize={{base:'10px',md:'12px',lg:'13px'}} fontWeight='900'>Nenhum rastreio para enviar</Text>
                }
               
                <Flex w={'full'} gap={'20px'} pt='20px' align={'center'}  justify={'flex-end'}>  
                    <LinkK onClick={()=>{
                        setOpen(false);
                        setEdit(null)
                    }}>
                        Cancelar
                    </LinkK>
                </Flex>
            </Stack> 
        }
        
    }else{
        setTimeout(()=>{setReload(false)})
        return null;
    }
    
}
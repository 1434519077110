import { Box, Button, Stack, others,Icon, Flex} from "@chakra-ui/react";
import { isDragActive } from "framer-motion"; 
import { useEffect, useRef, useState } from "react";
import { LuRotateCw, LuZoomIn, LuZoomOut } from "react-icons/lu";

export default function ViewFileZoom({file, ...others}){
    const [load,setLoad] = useState(false);
    const [box,setBox] = useState({w:0,h:0});
    const [img,setImg] = useState({w:0,h:0});
    const [rotate,setRotate] = useState(0);
    const [zoom,setZoom] = useState(1);
    const play = useRef();
    let view = true;
    useEffect(()=>{ setBox({w:play.current.offsetWidth,h:play.current.offsetHeight}); window.addEventListener("resize", ()=>{ if(view){ }}); return ()=>{   view = false; } },[]);  
    useEffect(()=>{ },[box,img,load]);
    if(load){ 
        var size = {w:0,h:0,r:0,z:0};
        if(img.h<img.w){
            size.w=box.w;
            size.h=parseInt(img.h*(box.w/img.w));
            if(rotate%2){
                var div=box.h/size.w;
                size.w*=div;
                size.h*=div; 
            } 
        }else{
            size.w=parseInt(img.w*(box.h/img.h));
            size.h=box.h; 
            if(rotate%2){
                var div=box.w/size.wh;
                size.w*=div;
                size.h*=div; 
            } 
        }
      
    }   
    switch (file.type.toUpperCase()) {
        case 'PDF': 
            return (
                <Stack   ref={play} align={'center'} justify={'center'}  position={'relative'}  w={'100%'}   h={{base:'400px',sm:'500px' ,lg:'600px'}}  maxW={{base:'320px',sm:'420px' ,lg:'500px'}} bg={'rgba(137,137,137,0.1)'}  backdropFilter='blur(60px)' {...others}>
                    <Box position={'relative'} overflow={'auto'}  w={'100%'}  h={'100%'} m={'0 auto'} >
                        <Stack  position={'relative'}   w={'100%'}    >
                            {load? <embed  style={{  
                                width:box.w,
                                height:box.h,  
                                
                            }}  draggable="false" src={file.link}    />  :
                            <embed   src={file.link}   onLoad={(e)=>{ setImg({w:e.target.offsetWidth,h:e.target.offsetHeight}); setBox({w:play.current.offsetWidth,h:play.current.offsetHeight}); setLoad(true); }}  style={{position:'absolute',opacity:load?'1':'0'}} />
                                } 
                        </Stack>
                    </Box>
                </Stack>
            )
        break; 
        default:
            return (
                <Stack   ref={play}  position={'relative'}  w={'100%'}   h={{base:'400px',sm:'500px' ,lg:'600px'}}  maxW={{base:'320px',sm:'420px' ,lg:'500px'}} bg={'rgba(137,137,137,0.1)'}  backdropFilter='blur(60px)' {...others}>
                    <Flex zIndex={2} position={'absolute'} left={'15px'} gap={'5px'} top={'5px'}    align={'center'} justify={'center'}>
                       <Button minW={'initial'}  fontSize={'24px'} onClick={()=>{
                           
                               setRotate(rotate+1);
                      
                       }} variant={'ghost'} w={'40px'} h={'40px'} backdropFilter='blur(10px) hue-rotate(90deg)' bg={'rgba(0,0,0,0.5)'} _hover={{color:'textAdmin.300'}} cursor={'pointer'}>
                           <Icon as={LuRotateCw }  />
                       </Button> 
                    </Flex>
                    <Flex zIndex={2} position={'absolute'} right={'15px'} gap={'5px'} top={'5px'}    align={'center'} justify={'center'}>
                       <Button minW={'initial'}  fontSize={'24px'} onClick={()=>{
                           if(zoom>=3){
                               setZoom(3);
                           }else{
                               setZoom(zoom+0.1);
                           }
                       }} variant={'ghost'} w={'40px'} h={'40px'} backdropFilter='blur(10px) hue-rotate(90deg)' bg={'rgba(0,0,0,0.5)'} _hover={{color:'textAdmin.300'}} cursor={'pointer'}>
                           <Icon as={LuZoomIn}  />
                       </Button>
                       <Button minW={'initial'} onClick={()=>{
                           if(zoom>1){
                               setZoom(zoom-0.1);
                           }else{
                               setZoom(1);
                           }
                           
                       }} variant={'ghost'} w={'40px'} h={'40px'} backdropFilter='blur(10px) hue-rotate(90deg)' bg={'rgba(0,0,0,0.5)'} _hover={{color:'textAdmin.300'}} cursor={'pointer'}>
                           <Icon as={LuZoomOut} fontSize={'24px'} />
                       </Button>
                    </Flex>
                    <Box position={'relative'} overflow={'auto'}  w={'100%'}  h={'100%'} >
                        <Stack  position={'relative'} w={box.w*zoom} h={box.h*zoom}   >
                            {load? <embed  style={{ 
                                position:'absolute',
                                width:size.w*zoom,
                                height:size.h*zoom,  
                                top:'50%',
                                left:'50%', 
                                transform: 'translateY(-50%) translateX(-50%) rotate('+(90*rotate)+'deg) '
                            }}  draggable="false" src={file.link}    />  :
                            <embed   src={file.link}   onLoad={(e)=>{ setImg({w:e.target.offsetWidth,h:e.target.offsetHeight}); setBox({w:play.current.offsetWidth,h:play.current.offsetHeight}); setLoad(true); }}  style={{position:'absolute',opacity:load?'1':'0'}} />
                        } 
                        </Stack>
                    </Box> 
               </Stack>
           );
        break;
    } 
}
import { Input, Stack,Box,Text, Flex, Button, Icon, Spacer, Textarea } from "@chakra-ui/react";
import { PageHK } from "../heading/HeadingKalyst"; 
import { useState } from "react";
import { ButtonKalyst } from "../button/ButtonKalyst"; 
import { SendForm } from "../form/SendForm";
import { sendFormSocket } from "../session/SessionAdmin";
import { LuFileCheck, LuFileKey, LuKey, LuKeyboard, LuMedal, LuRss } from "react-icons/lu";
import { InputKCopy, TextareaKCopy } from "./InputKalystCopy";
import { LinkK } from "../button/LinkKalyst";
import Box2Fa from "../2fa/Box2Fa";
function  ViewFile({sendF,setSendF,crop,onClose}) { 
    
    return <>
         <Flex>
         <SendForm  dados = {{modulo:'user',cmd:'documento',form:sendF }}  
            callback={(result)=>{ 
            //   console.log(result);
            }}
            gap={'10px'}
            > 
            <ButtonKalyst onClick={()=>{ 
                onClose();
            }}>Confirmar arquivo</ButtonKalyst> 
        </SendForm>
        <ButtonKalyst variant={'ghost'} colorScheme="red" onClick={()=>{
            setSendF({})
        }}>Cancelar</ButtonKalyst> 
        </Flex>
        <Flex  
            pos={'relative'}
            border='transparent'
            borderRadius='10px'
            align='center' 
            h={'600px'}
            p='0'
            w='100%'
            maxH={'100%'}
            overflow={'hidden'}
            overflowY={'auto'}
            direction={'column'} 
            >  
                <embed src={sendF.link}    style={{ width:'100%',height:(sendF.tipo=='pdf'?'100%':'auto'),  position:'absolute',left:'0px',top:'0px',transform: 'translate(-50% -50%)'}} />
        </Flex>
    </>
}
export function InputUploadRSA({title='',name='', key='',adq='', value=''}){  
 

    const [bg,setBg]= useState(false); 
    const [error,setError]= useState('');  
    const [load,setLoad]= useState(false);  
    const [view2fa,setView2fa]    =   useState(null); 

    const [link,setLink]= useState(''); 
    
    return <Stack key={key} gap={'8px'} w='full'  justify={'center'} align={'center'} > 
        <Text   fontSize={ {base:'10px',md:'12px',lg:'14px'}} _dark={{ color:'textDark.100'}} title={title} noOfLines={1}  textColor={'center'}>{title}</Text>
        {
            load?
            <Stack position={'relative'} align={'center'} justify={'center'} w={'100%'} h='48px'   bg={'bgAdmin.300'}     >  
                <Text fontSize={{base:'14px',md:'16px',lg:'18px'}} fontWeight={'bold'} color={'#ffffff'}   >{value>0?'Consultando RSA file...':'Gerando RSA file...'}</Text>  
            </Stack>:
            link!=''?
            <Flex position={'relative'} align={'center'} justify={'center'} w={'100%'}  gap={'10px'}  px={'0px'}    >   
                <TextareaKCopy   variant="admin"  focus={false}  label="Chave RSA Pública" h='170px' name='rsa' rsa={true}  initial={{rsa:link}}  file='public.pem'  />  
            </Flex>:
            value>0?
            <Stack position={'relative'} align={'center'} justify={'center'} w={'100%'} h='48px' gap={'10px'}    px={'0px'} borderRadius={'-moz-initial'}   >   
                <Button variant={'admin'} leftIcon={<LuFileKey/>} colorScheme="red" minW={'auto'} onClick={()=>{ 
                    setView2fa(<Box2Fa callback={()=>{ 
                        setLoad(true); 
                        sendFormSocket({  modulo:'acquirer',cmd:'saveAdqRSA',form:{adq:adq},callback:(result)=>{   
                            setLoad(false);  
                            setLink(result.code) 
                        }}); 
                        setView2fa(null); 
                    }} onClose={()=>{
                        setView2fa(null);  
                    }} titulo='Mostrar Chave'  variant={'admin'}
                     />); 
                }}>Mostrar RSA Pública</Button>
            </Stack>
            :
            <Stack position={'relative'} align={'center'} justify={'center'} w={'100%'} h='48px' gap={'10px'}    px={'0px'} borderRadius={'-moz-initial'}   >   
                <Button variant={'admin'} leftIcon={<LuFileKey/>} colorScheme="red" minW={'auto'} onClick={()=>{
                    setLoad(true); 
                    sendFormSocket({  modulo:'acquirer',cmd:'saveAdqRSA',form:{adq:adq},callback:(result)=>{   
                        setLoad(false);  
                        setLink(result.code) 
                    }}); 
                }}>Gerar Certificado RSA</Button>
            </Stack>
        } 
        {view2fa}
    </Stack>
} 
function  gerarFile(event,callback) { 
    var fileS = {};   
    Object.keys(event.target.files).forEach(async (key)=> {
        var file    =   event.target.files[key];   
        var reader      =   new FileReader();
        reader.onload =  (e)=> { 
            fileS= {
                link:   e.target.result, 
                size:   (file.size*0.000001),
                nome:   file.name.split('.')[0],
                tipo:   file.name.split('.')[file.name.split('.').length-1] 
            };  
            callback(fileS);
        }; 
        reader.readAsDataURL(file); 
    });  
    return fileS;
}
 import { Helmet } from "react-helmet-async";
import { BgAnimatedLogin } from "../../../components/backgound/BgAnimatedLogin";
import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";

export default function PageNotConfig(){
    return <Box bg={'#000000'} w='100%' minH='100vh'>
        <Helmet>
            <title>Construção</title>
            <meta name='description' content='' />
        </Helmet>
        <BgAnimatedLogin/>
        <Flex w='100vw' h='100vh'   align={'center'} justify={'center'}>
            <Stack w={'300px'} color={'gray'}>
                <Image h='82px'  src={'/assets/emp/logo_menu_light.png'} alt='login'   borderRadius={'5px'}/>
                <Text>Direcionamento realizado com sucesso para o domínio:</Text>        
                <Text>{window.location.hostname.replace('painel.','').replace('sandbox.','')}</Text>   
                <Text>Aguarde enquanto configuramos seu sistema!</Text>   
            </Stack>
        </Flex> 
    </Box>
}
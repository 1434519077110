import { Flex, Grid, GridItem, HStack, SimpleGrid, Stack, Text, Textarea,Icon, IconButton, Skeleton, Input, Tag, Tooltip, TagLabel, TagLeftIcon, Spacer } from "@chakra-ui/react";
import { HK4, HK5, PageHK, SubBHK, SubHK, SubTHK } from "../../../components/heading/HeadingKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { object, string } from "yup";
import { SendForm } from "../../../components/form/SendForm";
import { TextK1 } from "../../../components/text/TextKalyst";
import { LinkK } from "../../../components/button/LinkKalyst";
import { useEffect, useRef, useState } from "react";
import SelectK from "../../../components/select/SelectKalyst";
import { sendFormSocket } from "../../../components/session/SessionKalyst"; 
import { LuCheck, LuDownload, LuImport, LuInfo, LuLink, LuLink2, LuPackage2, LuTruck, LuUpload } from "react-icons/lu"; 
import { CardInfoText, CardPrice, CardPriceMini } from "../../../components/card/CardValues";
import { DataNomeMes, ExportCSV, ImportCSV, parseValor, validateSITE, viewPrice } from "../../../components/Function";
import { LojaSelect } from "../../../layout/painel/MenuPainel";  


import React from 'react'  
import { FaList, FaWhatsapp } from "react-icons/fa"; 
import { BiMoneyWithdraw } from "react-icons/bi";
import ModalPage from "../../../components/modal/ModalPage";
import InfoReports from "../reports/InfoReports";


export default function InfoTransaction( {open,setOpen,edit = null,setEdit}){  
    const [openP,setOpenP]    =   useState(false); 
    const [load,setLoadD]   =   useState(false);
    const [base,setBase]    =   useState({saldoD:0});  
    const [lanc,setLanc]    =   useState({});
    const [tipos,setTipos] = useState({});
    const [editP,setEditP]    = useState({});   
    const [reload,setReload] = useState(false); 
    const ip = useRef(null);
    function buscarDados(){  
        sendFormSocket({ modulo:'transaction',cmd:'info',form:{loja:LojaSelect,id:edit},callback:(result)=>{     
            setLoadD(true);  
            setLanc(result.lanc); 
            setTipos(result.tipos);
            setBase(result.dados); 
            setEditP(result.dados.id); 
            setReload(true); 
        }});
    } 
    useEffect(()=>{  
        setTimeout(()=>{
            buscarDados()
        },100); 
    },[]); 

    if(!reload && typeof base.id!= 'undefined'){  
         const method = {pix:'PIX',credit:'Cartão',boleto:'Boleto'};
         const status={
            Pago        :   {name:'Pago',       color:'green'}, 
            Rejeitado   :   {name:'Rejeitado',  color:'red'},
            Devolvido   :   {name:'Devolvido',  color:'red'},
            Cancelado   :   {name:'Cancelado',  color:'red'},
            Pendente    :   {name:'Pendente',   color:'orange'} , 
        }
        let total = 0;
        let desconto = 0;
        let frete = null;
        let produtos = [];
        //pedidos
        if(typeof base.items !== 'undefined' && base.items.length>0){
            base.items.forEach((i)=>{
                total+=i.quantity*i.unitPrice; 
                produtos.push(i)
            }) 
        }  
        //entrega
        try {
            if(typeof base.shipping.fee !== 'undefined'){
                desconto  += base.shipping.fee;
                frete = base.shipping.fee>0?viewPrice(base.shipping.fee):'Grátis';
            }
        } catch (error) {
            frete = null
        }
        //provedor
        let provedor = {
            nome    :   'Desconhecido',
            link    :   null,
            origem  :   null,
            site    :   null
        };
        try { provedor.nome     =   base.metadata.provider; }       catch (error) { };
        try { provedor.link     =   base.metadata.order_url; }      catch (error) { };
        try { provedor.origem   =   base.metadata.referrer_link; }  catch (error) { };
        try { provedor.site     =   base.metadata.sell_url; }       catch (error) { }; 

        return   <Stack w={'full'}>
                {/* <TextK1 px='16px'  fontSize='14px'>Lançamento <Text as={'span'} color={'textLight.300'}></Text></TextK1> */}
                <Stack w={'100%'} gap={'0px'}   align={'flex-start'}  justify={'flex-start'}  p='16px' pt='0px' borderRadius='8px'>   
                    <SubBHK   textAlign='left'>{tipos[lanc.codtip].nome}</SubBHK>   
                    <Stack  w={'100%'} gap={'0px'} pt='5px' pb={'15px'} px='6px' >
                        <Flex  w={'100%'} justify={'flex-end'} gap={'10px'}>
                            <Text as={'span'} fontSize='12px' color={'gray.500'} >Bruto</Text> <Text as={'span'} fontSize='12px' color={'blue.500'} > R$ {viewPrice(lanc.valorR)}</Text>  
                        </Flex>
                        <Flex  w={'100%'} justify={'flex-end'} gap={'10px'}>
                            <Text as={'span'} fontSize='12px' color={'gray.500'} >Taxa</Text> <Text as={'span'} fontSize='12px' color={'blue.500'} > R$ {viewPrice(lanc.taxaGT)}</Text>  
                        </Flex> 
                    </Stack>  
                    <SubTHK><Text as={'span'} fontSize='16px' color={'gray.500'} pr='10px'> Líquido </Text> <Text as={'span'} fontSize='24px' color={'textLight.300'} > R$ {viewPrice(lanc.valor)}</Text></SubTHK>
                </Stack>  
                <Flex w={'full'} gap={'10px'} align={'lex-start'}  justify={'space-between'} direction={'column'}>
                    <Stack w={'100%'} gap={'16px'} mt='16px' align={'center'}  justify={'flex-start'}  p='16px' pt='6px' bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}} borderRadius='8px'>   
                        <Stack w={'100%'} gap={'0px'} align={'flex-start'}  justify={'flex-start'} > 
                            <SubBHK   textAlign='left'>Dados Pedido</SubBHK> 
                            <CardInfoText  load={load}   title='Nome'       value={base.customer.name}  /> 
                            <CardInfoText  load={load}   title='E-mail'     value= {base.customer.email } /> 
                            <CardInfoText  load={load}   title='Telefone'   value={base.customer.phone}  />
                            <CardInfoText  load={load}   title={base.customer.document.type.toUpperCase()}  value={base.customer.document.number}  />
                        </Stack>
                        <ButtonKalyst leftIcon={<Icon as={FaList}/>} size={{base:'md',md:'lg'}} fontSize='12px' onClick={()=>{
                            setOpenP(true)
                        }}>Ver Pedido</ButtonKalyst> 
                    </Stack> 
                </Flex>  
                <Stack w={'full'} textAlign={'center'} pt='16px'>
                    <Text as={'span'} color={'textLight.300'}>{DataNomeMes(new Date(new Date(lanc.criacao).getTime()-(new Date().getTimezoneOffset() * 60000) ))}</Text>
                </Stack> 
                <ModalPage open={openP} setOpen={setOpenP}>
                    <InfoReports  open={openP} setOpen={(el)=>{ 
                        setOpenP(el)
                    }} edit={base.id} setEdit={setEditP}/> 
                </ModalPage>
            </Stack>  
        
    }else{
        setTimeout(()=>{setReload(false)})
        return null;
    }
    
}
import { Flex, Spinner, Stack, Text } from "@chakra-ui/react";
import { HK5 } from "../heading/HeadingKalyst";
import { TextK1 } from "../text/TextKalyst";

export default function LoadBg(){
    return (
        <Stack w={'full'} h='100%'  minH={{base:'calc(100vh - 200px)',md:'calc(100vh - 152px)'}}   align='center' justify='center'> 
            <Flex   align='center' justify='center' >
                <Spinner   size={'xl'} color='textLight.300' _dark={{color:'textDark.300'}}/>
                <HK5 ml='15px' noOfLines='2' color='textLight.100' _dark={{color:'textDark.100'}} >Aguarde um momento...</HK5>
            </Flex>  
        </Stack>
    )
}
import {Stack, Text,Icon,Grid, GridItem} from "@chakra-ui/react"; 
import {useEffect, useState } from "react";
import LoadBg from "../../../components/backgound/LoadBg"; 
import {PageHK} from "../../../components/heading/HeadingKalyst";
import {sendFormSocket } from "../../../components/session/SessionAdmin"; 
import {LuPen, LuTrash } from "react-icons/lu";  
import { ButtonKNew } from "../../../components/button/ButtonKalyst";
import ModalPage from "../../../components/modal/ModalPage"; 
import { LojaSelect } from "../../../layout/painel/MenuPainel";
import BoxDialog from "../../../components/modal/BoxDialog";
import ListBox from "../../../components/list/ListBox";
import { CardSettings } from "../../../components/card/CardSettings"; 
// import FormAdminAcquirer from "./FormPlans"; 
export function PageAdminSettings(props){  
    const [open,setOpen]    = useState(false); 
    const [dados,setDados]  = useState(null); 
    const [edit,setEdit]    = useState({}); 
    const [alert,setAlert]    = useState({});  

    let tempLoja = null;
    function BuscarDados(reload){ 
        if(LojaSelect !==tempLoja){
            tempLoja = LojaSelect
            reload = true; 
        } 
        sendFormSocket({campo:'pages', modulo:'settings',cmd:'list',form:{reload,loja:LojaSelect},callback:(result)=>{   
            setDados(result);
            setEdit(result.base); 
        }});
    } 
    useEffect(()=>{ 
        BuscarDados(true);
        var interV =setInterval(()=>{
            BuscarDados(false);
        },500); 
        return  ()=>{
            clearInterval(interV);
        }
    },[]);
    useEffect(()=>{ },[edit])
    if(dados == null){ 
        return <LoadBg/>
    }else{ 
        let temp = {};
        let config = [];
        if(typeof dados.campos != 'undefined' && Object.keys( dados.campos).length>0){
            Object.keys( dados.campos).forEach(key => {
                var el = dados.campos[key];
                var gr = dados.grupos[el.grupo]
                if(typeof temp[el.grupo] == 'undefined'){ 
                    temp[el.grupo]  = {
                        id:key,
                        title:gr.name,
                        size:{x:gr.x,y:gr.y},
                        dados:[]
                    }
                }
                el.id = key;
                temp[el.grupo].dados.push(el)
            }); 
        }
        config = Object.values(temp);  
        return  ( 
            <Stack zIndex={3} w='full' gap={{base:'16px',md:'32px'}}  align={'flex-start'} justify='flex-start'   minH={'100vh'} pb='100px'  margin = '0 auto'  >
                <PageHK>Configurações</PageHK>  
                <Grid
                    w={{base:'100%',md:'100%'}}  
                    templateColumns={{base:'repeat(1fr, 1fr)','lg':'repeat(2, 1fr)','2xl':'repeat(2, 1fr)'}}
                    templateRows='repeat(4, 1fr)' 
                    gap={{base:'4px',md:'16px'}}   
                > 
                    {
                        config.map((c,i)=>{  
                            return(
                                <GridItem  key={'set-'+i} rowSpan={{base:'1','2xl':c.size.y}} colSpan={c.size.x} > 
                                    <CardSettings dados={c} variant='admin' onChange={(dados)=>{  
                                        sendFormSocket({ modulo:'config',cmd:'update',form:dados,callback:(result)=>{ 
                                            console.log(result); 
                                        }}); 
                                    }} value={edit}    /> 
                                </GridItem>  
                            )
                        })
                    } 
                </Grid>
               
                <ModalPage open={open} setOpen={setOpen}>
                    {/* <FormAdminAcquirer   open={open} setOpen={(el)=>{ 
                        setOpen(el)
                    }} edit={edit} setEdit={setEdit}   plans={dadosPlan}/> */}
                </ModalPage>
                <BoxDialog param={alert}  />
            </Stack> 
        )
    }
    
}
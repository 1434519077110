import { Input, Stack,Box,Text, Flex, Button, Icon, Spacer } from "@chakra-ui/react";
import { PageHK } from "../heading/HeadingKalyst"; 
import { useState } from "react";
import { ButtonKalyst } from "../button/ButtonKalyst"; 
import { SendForm } from "../form/SendForm";
import { sendFormSocket } from "../session/SessionAdmin";
import { LuFileCheck } from "react-icons/lu";
function  ViewFile({sendF,setSendF,crop,onClose}) { 
    
    return <>
         <Flex>
         <SendForm  dados = {{modulo:'user',cmd:'documento',form:sendF }}  
            callback={(result)=>{ 
            //   console.log(result);
            }}
            gap={'10px'}
            > 
            <ButtonKalyst onClick={()=>{ 
                onClose();
            }}>Confirmar arquivo</ButtonKalyst> 
        </SendForm>
        <ButtonKalyst variant={'ghost'} colorScheme="red" onClick={()=>{
            setSendF({})
        }}>Cancelar</ButtonKalyst> 
        </Flex>
        <Flex  
            pos={'relative'}
            border='transparent'
            borderRadius='10px'
            align='center' 
            h={'600px'}
            p='0'
            w='100%'
            maxH={'100%'}
            overflow={'hidden'}
            overflowY={'auto'}
            direction={'column'} 
            >  
                <embed src={sendF.link}    style={{ width:'100%',height:(sendF.tipo=='pdf'?'100%':'auto'),  position:'absolute',left:'0px',top:'0px',transform: 'translate(-50% -50%)'}} />
        </Flex>
    </>
}
export function InputUploadAPI({title='',name='', key='', value='',format =''}){  
 

    const [bg,setBg]= useState(false);
    const [error,setError]= useState('');  
    const [load,setLoad]= useState(false); 
    const [link,setLink]= useState(value); 
    
    return <Stack key={key} gap={'8px'} w='full'   > 
        <Text fontSize={ {base:'10px',md:'12px',lg:'14px'}} _dark={{ color:'textDark.100'}} title={title} noOfLines={1} >{title}</Text>
        {
            load?
            <Stack position={'relative'} align={'center'} justify={'center'} w={'100%'} h='48px'   bg={'bgAdmin.300'}     >  
                <Text fontSize={{base:'14px',md:'16px',lg:'18px'}} fontWeight={'bold'} color={'#ffffff'} >Load file...</Text>  
            </Stack>:
            link!=''?
            <Flex position={'relative'} align={'center'} justify={'center'} w={'100%'} h='48px' gap={'10px'}  bg={'bgAdmin.300'} px={'20px'}    >  
                <Icon as={LuFileCheck} fontSize={'22px'}/>
                <Text fontSize={{base:'14px',md:'16px',lg:'18px'}} fontWeight={'bold'} color={'#ffffff'} >{link.replace('../keys/','').toLowerCase()}</Text>  
                <Input type="hidden" name={name} value={link}/>
                <Spacer/>
                <Button variant={'ghost'} colorScheme="red" onClick={()=>{
                    setLink('');
                }}>Delete</Button>
            </Flex>:
            <Stack position={'relative'} align={'center'} justify={'center'} w={'100%'} h='48px' bg={bg?'bgAdmin.300':'transparent'} border={'3px dashed'} color={bg?'bgAdmin.200':error==''?'bgAdmin.300':'red'} _dark={{color:bg?'bgAdmin.200':error==''?'bgAdmin.300':'red'}} borderRadius={'8px'} onDragOver={()=>{
                setBg(true)
            }} onDragLeave={()=>{
                setBg(false)
            }}   > 
                
                <Text fontSize={{base:'14px',md:'16px',lg:'18px'}} fontWeight={'bold'} color={bg?'bgAdmin.200':error==''?'bgAdmin.300':'red'} _dark={{color:bg?'bgAdmin.200':error==''?'bgAdmin.300':'red'}} >{error==''?bg?'Solte o arquivo para enviar':'Clique ou arraste para enviar o arquivo':error}</Text>
                <Input   position={'absolute'} opacity={0} onChange={(e)=>{
                setLoad(true);
                gerarFile(e,(file)=>{ 
                    sendFormSocket({  modulo:'acquirer',cmd:'saveAdqFile',form:{file},callback:(result)=>{   
                        setLoad(false); 
                        setLink(result) 
                    }}); 
                //     var add = false;
                //     types.forEach((t)=>{
                //         if(file.tipo.toUpperCase() == t){
                //             add = true
                //         }
                //     })
                //     if(add){
                //         file.codtip =tipo.id
                //         switch(file.tipo.toLowerCase()){
                //             case 'png':case 'jpg':case 'jpeg':
                //                 if(crop == null){
                //                     if(file.size>size){
                //                         setError('Tamanho máximo é ('+size+'mb)')
                //                     }else{
                //                         setSendF(file);
                //                     }
                //                 }else{ 
                //                     setSendF(file);
                //                 }
                //             break;
                //             default:
                //                 if(file.size>size){
                //                     setError('Tamanho máximo é ('+size+'mb)')
                //                 }else{
                //                     setSendF(file);
                //                 }
                //             break;
                        
                //         } 
                //     }else{
                //         setError('Formato de arquivo válidos são: '+JSON.stringify(types)+' ')
                //     }
                    
                }); 
            }} h={'100%'} w={'100%'} type="file"  variant={'ghost'} bg={'transparent'} />  
            </Stack> 
        }
       
        
    </Stack>
} 
function  gerarFile(event,callback) { 
    var fileS = {};   
    Object.keys(event.target.files).forEach(async (key)=> {
        var file    =   event.target.files[key];   
        var reader      =   new FileReader();
        reader.onload =  (e)=> { 
            fileS= {
                link:   e.target.result, 
                size:   (file.size*0.000001),
                nome:   file.name.split('.')[0],
                tipo:   file.name.split('.')[file.name.split('.').length-1] 
            };  
            callback(fileS);
        }; 
        reader.readAsDataURL(file); 
    });  
    return fileS;
}
import { Flex,Box, Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input,InputGroup, InputLeftAddon, InputLeftElement, InputRightAddon, InputRightElement, others, Text, Icon, Skeleton} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { LuEye ,LuEyeOff } from "react-icons/lu";
import { getMaskString } from "./rhf-funcion-mask";
import { nl2br } from "../Function";
import { InputK } from "./InputKalyst";
export function DataPickerKalyst({ max = null,name='data',min=null,valueMin=null, valueMax = null,initial=null,onChange= ()=>{},...others}){
    var initialData= {};
    if(initial !== null){
        if(typeof initial[name]!=='undefined'){
            var data =initial[name].split('|');
            if(data.length==2){ 
                try { initialData.dataI=data[0]==null?null:data[0].split('T')[0]} catch (error) { }
                try { initialData.dataF=data[1]==null?null:data[1].split('T')[0]} catch (error) { }
            }
            
        }
    }
    const ipD = useRef(null)
    const [dataI,setDataI]= useState(min);
    const [dataF,setDataF]= useState((max=='hoje'?new Date(new Date().getTime()-(new Date().getTimezoneOffset()*60000)).toISOString().split('T')[0]:max)) 
    const [inputI,setInputI]= useState(valueMin);
    const [inputF,setInputF]= useState(valueMax);
    // useEffect(() => {
        
    //     // ipD.current.change()
    //     // console.log(inputI+'|'+inputF);
    // }, [inputI,inputF]);   
    return (
        <Flex w={'100%'}  gap={'20px'} px={'16px'}  py='0' >
            <InputK  type='date' label="Início" value={initialData.dataI}    placeholder="dd/mm/aaaa" w="calc(50% - 10px)" size='sm' onChange={(e)=>{
                if(e ==''){
                    ipD.current.value = null+'|'+inputF;
                    setInputI(null);
                }else{
                    var data = new Date(e); 
                    ipD.current.value = data.toISOString().split('T')[0]+'T00:00:00.000Z'+'|'+inputF;
                    setInputI(data.toISOString().split('T')[0]+'T00:00:00.000Z');
                }  
                
            }}  minData={dataI}  maxData={dataF}   autoComplete={false}/>
            <InputK  type='date' label="Final"  value={initialData.dataF}    placeholder="dd/mm/aaaa"    w="calc(50% - 10px)" size='sm'  onChange={(e)=>{
                if(e ==''){ 
                    ipD.current.value = inputI+'|'+null;
                    setInputF(null);
                }else{
                    var data = new Date(e); 
                    ipD.current.value = inputI+'|'+data.toISOString().split('T')[0]+'T23:59:59.999Z';
                    setInputF(data.toISOString().split('T')[0]+'T23:59:59.999Z');
                }   
             
            }}  minData={dataI}  maxData={dataF}    autoComplete={false}/>
            <Input type="hidden" ref={ipD}    name={name} defaultValue={(inputI!=null)||inputF!=null?inputI+'|'+inputF:''}/> 
        </Flex>
    ); 
}
import {  Flex, Image, Stack, Text,Icon,colorMode, toggleColorMode, useColorMode, Input, Grid, GridItem, SimpleGrid, Progress} from "@chakra-ui/react"; 
import { useEffect, useState } from "react";
import LoadBg from "../../../components/backgound/LoadBg"; 
import { PageHK, SubHK } from "../../../components/heading/HeadingKalyst";
import { sendFormSocket, user } from "../../../components/session/SessionKalyst"; 
import { LuBox, LuEye, LuLineChart, LuPen, LuTrash, LuUser2, LuUsers2, LuView } from "react-icons/lu"; 
import ListResponsive, { ListaResultadoResponsive, ListPageResponsive } from "../../../components/list/ListResponsive";
import { ButtonKNew } from "../../../components/button/ButtonKalyst";
import ModalPage from "../../../components/modal/ModalPage";
import FormClint from "./FormProducts";
import { LojaSelect } from "../../../layout/painel/MenuPainel";
import BoxDialog from "../../../components/modal/BoxDialog";
import { viewPrice } from "../../../components/Function";
import FilterPainel from "../../../components/filter/FilterPainel";
import md5 from "md5";
import { getFilter } from "../../../components/filter/FilterDate";
import { InputK } from "../../../components/input/InputKalyst";
import { DataPickerKalyst } from "../../../components/input/DataPickerKalyst";
import SelectK from "../../../components/select/SelectKalyst";

let session   = null;
export function PagePainelProducts(props){  
    const [open,setOpen]      =     useState(false); 
    const [dados,setDados]    =     useState(null); 
    const [edit,setEdit]      =     useState({}); 
    const [alert,setAlert]    =     useState({});  
    let tempLoja = null;
    
    const hoje = new Date();  
    let tempFilter = null;
    let dFilter   = null
    function BuscarDados(reload){ 
        session = md5(user.id+'productsV2');
        dFilter = getFilter(session);
        if(LojaSelect !==tempLoja){
            tempLoja = LojaSelect
            reload = true; 
        } 
        sendFormSocket({campo:'pages', modulo:'product',cmd:'list',form:{reload,loja:LojaSelect,filtro:dFilter},callback:(result)=>{ 
            setDados(result)
        }});
    } 
    useEffect(()=>{ 
        BuscarDados(true);
        var interV =setInterval(()=>{
            BuscarDados(false);
        },500);
        
        return  ()=>{
            clearInterval(interV);
        }
    },[]); 
    if(dados == null){ 
        return <LoadBg/>
    }else{ 
        const titles = [
            {name:'Nome',       campo:'title'        ,w:7},
            {name:'Tangível',   campo:'tangible'    ,w:6},
            {name:'Preço Total',      campo:'valorMinMax'       ,w:7},
            {name:'Preço Médio',      campo:'valorMed'       ,w:7}, 
            {name:'Vendas',      campo:'vendas'       ,w:7}, 
            {name:'Unid.',      campo:'quantity'       ,w:7} 
        ];
        const lista = [] 
        dFilter = getFilter(session);  
        let initial = {}
        try {
            Object.keys(dFilter.filtro).forEach((key)=>{
                initial[key]=dFilter.filtro[key].value;
            })
        } catch (error) {
            
        } 
        if(dados.products.length>0){
            dados.products.forEach((i)=>{
                lista.push({
                    id          :   i.id,
                    title       :   i.title,
                    tangible    :   i.tangible,
                    quantity    :   i.quantity, 
                    valorMinMax :   viewPrice(i.valorMin) +'~'+viewPrice(i.valorMax),
                    valorMin    :   viewPrice(i.valorMin),
                    valorMax    :   viewPrice(i.valorMax),
                    valorMed    :   viewPrice(i.valorMed),
                    vendas      :   i.vendas,
                    criacao     :   i.criacao 
                }) 
            }) 
        } 
        let tempReload = null;
        const viewFilter = (
            <Stack gap={'0px'}> 
                <InputK  focus={true}  type={'text'} label="Produto Nome" size='sm'  name="LIKE_AND-title" placeholder={"Nome do produto "}   autoComplete={false} initial={initial}/>  
                         
                <DataPickerKalyst  name="DATA_PERIODO-criacao" max='hoje'  initial={initial}/>
                <Stack gap='0px' px='16px'  >
                    <SelectK label={'Método'} placeholder="Selecione" size='sm'   value={[{value:'pix',name:'PIX'},{value:'credit',name:'Crédito'},{value:'boleto',name:'Boleto'}]} name='IGUAL_AND-method' initial={initial} />
                    <SelectK label={'Status'} placeholder="Selecione" size='sm'    value={[{value:'pago',name:'Pago'},{value:'pendente',name:'Pendente'},{value:'rejeitado',name:'Rejeitado'},{value:'chargeback',name:'Chargeback'},{value:'estorno',name:'Estornado'}]} name='IGUAL_AND-status' initial={initial}/>
                    <SelectK label={'Produto'} placeholder="Selecione"  size='sm'    value={[{value:'S',name:'Físico'},{value:'N',name:'Digital'}]} name='IGUAL_AND-fisico' initial={initial}/>
                    <SelectK label={'Rastreio'} placeholder="Selecione"  size='sm'    value={[{value:'S',name:'Enviado'},{value:'N',name:'Pendente'}]} name='IGUAL_AND-rastreioEnviado' initial={initial}/>
                    <InputK  focus={false}  type={'text'} label="Código Rastreio" size='sm'  name="LIKE_AND-rastreio|linkRastreio" placeholder={"Código ou Site"}   autoComplete={false} initial={initial}/>  
                </Stack> 
            </Stack> 
        ) 
        return  ( 
            <Stack   w='full' gap={{base:'16px',md:'32px'}}  align={'flex-start'} justify='flex-start' overflowX='hidden'   minH={{base:'calc(100vh - 168px)',md:'calc(100vh - 152px)'}} pb='100px'  margin = '0 auto'  >
                <PageHK>Produtos</PageHK> 
                <Stack gap='0px' w={'full'} >   
                    {/* <FilterPainel dFilter={dFilter} session={session}  
                        onChange={()=>{
                            clearTimeout(tempReload);
                            tempReload = setTimeout(()=>{ 
                                tempLoja = null;
                                BuscarDados(true); 
                            },1000) 
                        }} 
                        ordem={[
              
                        ]}
                        fast={['method','status','cliente']}
                        viewFilter={viewFilter}
                    />  */}
                    <FilterPainel dFilter={dFilter} session={session}  
                        onChange={()=>{
                            clearTimeout(tempReload);
                            tempReload = setTimeout(()=>{ 
                                tempLoja = null;
                                BuscarDados(true); 
                            },1000) 
                        }} 
                        ordem={[
                            {id:'id_desc'       ,name:'Mais Recentes'},
                            {id:'id_asc'        ,name:'Menos Recentes'},   
                            {id:'valorMin_desc'    ,name:'Maior Preço Mínimo'},
                            {id:'valorMin_asc'     ,name:'Menor Preço Mínimo'}, 
                            {id:'valorMax_desc'    ,name:'Maior Preço Máximo'},
                            {id:'valorMax_asc'     ,name:'Menor Preço Máximo'}, 
                            {id:'valorMed_desc'    ,name:'Maior Preço Médio'},
                            {id:'valorMed_asc'     ,name:'Menor Preço Médio'},  
                            {id:'quantity_desc'    ,name:'Maior Quantidade'},
                            {id:'quantity_asc'     ,name:'Menor Preço Médio'}, 
                        ]}
                        fast={['method','status','cliente']}
                        viewFilter={viewFilter}
                    /> 
                     <ListaResultadoResponsive session={session}  page={dados.page} onChange={()=>{
                        tempLoja = null;
                        BuscarDados(true);
                    }}/>
                    <ListResponsive titles={titles} dados={lista} cmd={
                        [
                            // { icon:<LuPen/>,name:'Editar', click:(d)=>{  
                            //     setEdit(d.id);
                            //     setOpen(true); 
                            // }}, 
                            { icon:<LuEye/>,name:'Deletar', click:(d)=>{  
                                // setAlert({load:true});
                                // setTimeout(()=>{ 
                                //     setAlert({
                                //     fechar:true,
                                //     icon   : <Icon as={LuTrash} h={{base:'50px'}} w={{base:'50px'}} color='red.300' />, 
                                //     titulo:'Deletar Loja',
                                //     size:'md',
                                //     mensagem:'Deseja realmente deletar a loja <b>'+d.nome+'<b>?', 
                                //     botao:[
                                //         {
                                //             nome:'SIM', 
                                //             cmd:()=>{
                                //                 setAlert({load:true});
                                //                 sendFormSocket({ modulo:'store',cmd:'deleteStore',form:{id:d.id},callback:(result)=>{ 
                                //                     setTimeout(()=>{
                                //                         setAlert({exit:true});
                                //                         BuscarDados(false); 
                                //                     },500)
                                //                 }});
                                //             }
                                //         },
                                //         {
                                //             nome:'NÃO',
                                //             variant:'ghost',
                                //         }
                                //     ]
                                //     });
                                // }) 
                            }}
                        ]
                    } /> 
                    <ListPageResponsive onChange={()=>{ 
                        tempLoja = null;
                        BuscarDados(true);
                    }} session={session}  page={dados.page} />
                </Stack> 
                <ModalPage open={open} setOpen={setOpen}>
                    <FormClint   open={open} setOpen={(el)=>{ 
                        setOpen(el)
                    }} edit={edit} setEdit={setEdit}/>
                </ModalPage>
                <BoxDialog param={alert}  />
            </Stack> 
        )
    }
    
}
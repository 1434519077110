import { Stack, Text ,Icon, Flex, Spacer, Textarea, Tooltip,  others, useToast } from "@chakra-ui/react";  
import { useState } from "react"; 
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { SendForm } from "../../../components/form/SendFormAdmin";
import { InputK } from "../../../components/input/InputKalyst";
import { object, string } from "yup";
import { HK4 } from "../../../components/heading/HeadingKalyst";
import SelectK from "../../../components/select/SelectKalyst";
import { LinkK } from "../../../components/button/LinkKalyst";
import { AlertBox } from "../../../components/heading/AlertBox"; 
import { sendFormSocket } from "../../../components/session/SessionAdmin";
const bancos = [
    {value:'1', name:'Banco do Brasil'},
    {value:'3', name:'Banco da Amazonia'},
    {value:'4', name:'Banco do Nordeste'},
    {value:'33', name:'Santander'},
    {value:'41', name:'Banrisul'},
    {value:'47', name:'Banco Banese'},
    {value:'77', name:'Inter'},
    {value:'103', name:'Mercado Pago'},
    {value:'104', name:'Caixa Econômica'},
    {value:'121', name:'Agibank'},
    {value:'128', name:'Crefisa'},
    {value:'129', name:'XP Investimentos'},
    {value:'173', name:'BRL Trust DTVM'},
    {value:'197', name:'Stone'},
    {value:'208', name:'Banco BTG Pactual'},
    {value:'212', name:'Banco Original'},
    {value:'237', name:'Bradesco/Next'},
    {value:'246', name:'Banco ABC Brasil'},
    {value:'260', name:'Nubank'},
    {value:'290', name:'Pagseguro'},
    {value:'318', name:'Banco BMG'},
    {value:'336', name:'Banco C6'},
    {value:'341', name:'Itaú'},
    {value:'348', name:'BANCO XP S.A.'},
    {value:'364-6', name:'Gerencianet Pagamentos do Brasil'},
    {value:'376', name:'JP Morgan'},
    {value:'383', name:'Juno'},
    {value:'389', name:'Mercantil do Brasil'},
    {value:'403', name:'Cora'},
    {value:'422', name:'Safra'},
    {value:'481', name:'PJBank'},
    {value:'536', name:'Neon/Votorantim'},
    {value:'613', name:'Banco Omni'},
    {value:'623', name:'Banco Pan'},
    {value:'637', name:'BCO SOFISA S.A.'},
    {value:'655', name:'Votorantim'},
    {value:'745', name:'Citibank'},
    {value:'748', name:'Sicredi'},
    {value:'756', name:'Sicoob'}
]

function BoxInfoFlex({title,children,...others}) {
    return <Flex w={'100%'} align={'center'} justify={'space-between'}  {...others}>
        <Text  noOfLines={'1'} color={'textAdmin.200'} fontSize={{base:'12px',md:'14px', lg:'16px'}}>{title}</Text>  
        <Tooltip label={children}  bg={'rgba(0,0,0,0.5)'} backdropFilter='blur(60px)' color={'textAdmin.300'}>
            <Text noOfLines={'1'}  color={'textAdmin.300'} fontSize={{base:'12px',md:'14px', lg:'16px'}}>{children}</Text>
        </Tooltip>
    </Flex>
}
function BoxTextFlex({title,children,...others}) {
    return <Stack w={'100%'} align={'flex-start'} justify={'space-between'} gap={'0px'}  {...others} >
        <Text  noOfLines={'1'} color={'textAdmin.200'} fontSize={{base:'12px',md:'14px', lg:'16px'}}>{title}</Text>  
        <Tooltip label={children}  bg={'rgba(0,0,0,0.5)'} backdropFilter='blur(60px)' color={'textAdmin.300'}>
            <Text  color={'textAdmin.300'} fontSize={{base:'12px',md:'14px', lg:'16px'}} dangerouslySetInnerHTML={{__html: children.replace(/\n/g,'<br />')}}></Text>
        </Tooltip>
    </Stack>
}
function BoxAprovarDados({title,codusu,modulo='',cmd='',children,BuscarDados,...others}) { 
    const [repro,setRepro] = useState(false);
    const [obs,setObs] = useState('');
    const toast = useToast(); 
    function sendDados(){ 
        sendFormSocket({form:{codusu,situa:repro?'R':'N',  obs:obs }, modulo,cmd,callback:(result)=>{    
            BuscarDados();
            toast({
                position: 'top-right',
                title: 'Dados enviado com sucesso',
                description: "Operação realizada com sucesso!",
                status: 'success',
                containerStyle:{
                    color:'textAdmin.100',
                    bg:'bgAdmin.300'
                },
                duration: 5000,
                isClosable: true,
            });
        }});
    }
    if(repro){
        return  (
            <Stack zIndex={30}   w={'100%'} p='16px'  borderRadius={'8px'} border={'1px solid'} borderColor={'bgAdmin.100'}   bg={'rgba(137,137,137,0.3)'} backdropFilter='blur(60px)' {...others}>
               <InputK as={Textarea} type='text' label="Observação"  onChange={(v)=>{ 
                    setObs(v)
                }}    placeholder="Essa observação aparecerá para o cliente"       autoComplete={false}/>
                <Flex   w={'100%'} align={'center'} gap={'16px'}>
                     <Spacer/>
                    <ButtonKalyst variant={'admin'} bg="red" opacity='0.7' _hover={{opacity:'1'}} onClick={()=>{
                        sendDados()
                    }} >Reprovar</ButtonKalyst>
                    <ButtonKalyst variant={'ghost'}  colorScheme="red"  border='1px solid' onClick={()=>{
                        setRepro(false);
                    }} >Cancelar</ButtonKalyst>
                </Flex>
            </Stack>
        )
    }else{
        return  (
            <Stack zIndex={30}   w={'100%'} p='16px'  borderRadius={'8px'} border={'1px solid'} borderColor={'bgAdmin.100'}   bg={'rgba(137,137,137,0.3)'} backdropFilter='blur(60px)'  {...others}>
                <Flex  w={'100%'} align={'center'} gap={'16px'}>
                    <Text color={'textAdmin.100'} pl='10px'>{title}</Text><Spacer/>
                    <ButtonKalyst variant={'admin'} onClick={()=>{
                        setRepro(false);
                        sendDados();
                    }} >Aprovar</ButtonKalyst>
                    <ButtonKalyst variant={'ghost'}  colorScheme="red"  border='1px solid' onClick={()=>{
                        setRepro(true);
                    }} >Reprovar</ButtonKalyst>
                </Flex>
            </Stack>
        )
    }
    
}
export default function FormDocumentFrente({fase,setFase,dados,BuscarDados}){ 
    let viewForm = null;  
    const [reset,setReset]  = useState(false);
    switch (fase) {  
        case 1:  
            viewForm = ( 
                <>     
                    {(dados.user.sysInf == 'N')?<Stack pb='12px'><AlertBox title='Dados Aprovados'  status="success"/></Stack>:null}  
                    {(dados.user.sysInf == 'R')?<Stack pb='12px'><AlertBox title='Dados Reprovados' desc={dados.user.obsInfo} status="error"  /></Stack>:null}  

                    <BoxInfoFlex title ='Nome'>{dados.user.nome ==null?'':dados.user.nome}</BoxInfoFlex> 
                    <BoxInfoFlex title ='Fantasia'>{dados.user.fantasia==null?'':dados.user.fantasia}</BoxInfoFlex> 
                    <BoxInfoFlex title ='CNPJ'>{dados.user.documento ==null?'':dados.user.documento}</BoxInfoFlex> 
                    <BoxInfoFlex title ='Abertura'>{dados.user.inscricao ==null?'':new Date(dados.user.inscricao).toLocaleDateString('pt-BR', {  month: '2-digit', day: '2-digit',year:'numeric'})}</BoxInfoFlex> 
                    <BoxInfoFlex title ='Telefone'>{dados.user.telefone ==null?'':dados.user.telefone }</BoxInfoFlex> 
                    <BoxInfoFlex title ='E-mail' pb='10px'>{dados.user.email ==null?'':dados.user.email}</BoxInfoFlex> 
                    <BoxTextFlex title ='Código e descrição da atividade principal' pb='10px'>{dados.user.atiPrincipal==null?'':dados.user.atiPrincipal}</BoxTextFlex>  
                    <BoxTextFlex title ='Código e descrição da atividade secundária'  pb='10px'>{dados.user.atiSecundaria==null?'':dados.user.atiSecundaria}</BoxTextFlex>  
                    
                    {(dados.user.sysInf == 'P')?
                        <BoxAprovarDados title='Deseja aprovar os dados da empresa?' modulo="client" cmd='saveClientInfo' codusu={dados.user.id} BuscarDados={BuscarDados} />
                    :null}  
                    
                </>
                 
            ) 
        break;
        case 2:
            viewForm = ( 
                <>
                    {(dados.user.sysLoj == 'N')?<Stack pb='12px'><AlertBox title='Dados Aprovados'   status="success"/></Stack>:null}  
                    {(dados.user.sysLoj == 'R')?<Stack pb='12px'><AlertBox title='Dados Reprovados' desc={dados.user.obsLoj } status="error"/></Stack>:null}  
                    <BoxInfoFlex title ='Faturamento'>      {dados.user.faturamento==null?'':dados.user.faturamento.toFixed(2).replace('.',',')}</BoxInfoFlex> 
                    <BoxInfoFlex title ='Ticket'>           {dados.user.tckMedio==null?'':dados.user.tckMedio}</BoxInfoFlex> 
                    <BoxInfoFlex title ='Produto Físico?'>  {dados.user.prdFisico==null?'':dados.user.prdFisico}</BoxInfoFlex> 
                    <BoxInfoFlex title ='Site'>             {dados.user.site==null?'':dados.user.site}</BoxInfoFlex> 
                    <BoxTextFlex title ='Descrição' pb='10px'>{dados.user.desTrabalho==null?'':dados.user.desTrabalho}</BoxTextFlex>  
                    {(dados.user.sysLoj == 'P')? 
                        <BoxAprovarDados title='Deseja aprovar os dados da loja?' modulo="client" cmd='saveClientLoja' codusu={dados.user.id} BuscarDados={BuscarDados} />
                    :null}   
                </> 
            ) 
        break
        case 3:
            viewForm = ( 
                <Stack gap={'5px'}> 
                        {(dados.user.sysRep == 'N')?<Stack pb='12px'><AlertBox title='Dados Aprovados' desc={'' } status="success"/></Stack>:null}  
                        {(dados.user.sysRep == 'R')?<Stack pb='12px'><AlertBox title='Dados Reprovados' desc={dados.user.obsRep } status="error"/></Stack>:null}  
              
                        <BoxInfoFlex title ='Nome'>{dados.user.nomeRep ==null?'':dados.user.nomeRep}</BoxInfoFlex> 
                        <BoxInfoFlex title ='CPF'>{dados.user.cpfRep==null?'':dados.user.cpfRep}</BoxInfoFlex> 
                        <BoxInfoFlex title ='RG'>{dados.user.rgRep==null?'':dados.user.rgRep}</BoxInfoFlex> 
                        <BoxInfoFlex title ='Telefone'>{dados.user.telefoneRep==null?'':dados.user.telefoneRep}</BoxInfoFlex> 
                        <BoxInfoFlex title ='Nascimento'>{dados.user.nascimentoRep==null?'':new Date(dados.user.nascimentoRep).toLocaleDateString('pt-BR', {  month: '2-digit', day: '2-digit',year:'numeric'})}</BoxInfoFlex> 
                        <BoxInfoFlex title ='Nome Mãe'>{dados.user.maeRep==null?'':dados.user.maeRep}</BoxInfoFlex>  
                        
                        {(dados.user.sysRep == 'P')?
                            <BoxAprovarDados title='Deseja aprovar os dados do representante?' modulo="client" cmd='saveClientRepe' codusu={dados.user.id} BuscarDados={BuscarDados} />
                        :null}  
                       
                </Stack>
            ) 
        break;
        case 4:
            viewForm = ( 
                <>
                    {(dados.user.sysEnd == 'N')?<Stack pb='12px'><AlertBox title='Dados Aprovados'   status="success"/></Stack>:null}  
                    {(dados.user.sysEnd == 'R')?<Stack pb='12px'><AlertBox title='Dados Reprovados' desc={dados.user.obsEnd } status="error"/></Stack>:null}  
                    <BoxInfoFlex title ='CEP'>      {dados.user.cep ==null?'':dados.user.cep}</BoxInfoFlex> 
                    <BoxInfoFlex title ='Endereço'> {dados.user.rua ==null?'':dados.user.rua}</BoxInfoFlex> 
                    <BoxInfoFlex title ='Número'>{dados.user.num ==null?'':dados.user.num}</BoxInfoFlex> 
                    <BoxInfoFlex title ='Complemento'>{dados.user.complemento ==null?'':dados.user.complemento}</BoxInfoFlex> 
                    <BoxInfoFlex title ='Bairro'>{dados.user.bairro ==null?'':dados.user.bairro}</BoxInfoFlex> 
                    <BoxInfoFlex title ='Cidade'>{dados.user.cidade ==null?'':dados.user.cidade}</BoxInfoFlex> 
                    <BoxInfoFlex title ='Estado'>{dados.user.estado ==null?'':dados.user.estado}</BoxInfoFlex> 
                    <BoxInfoFlex title ='País'>{dados.user.pais ==null?'':dados.user.pais}</BoxInfoFlex>   
                    {(dados.user.sysEnd == 'P')?
                        <BoxAprovarDados title='Deseja aprovar os dados do representante?' modulo="client" cmd='saveClientEnd' codusu={dados.user.id} BuscarDados={BuscarDados} />
                    :null}   
                </>
            ) 
        break;
        case 5:
            var bancoNome = 'Desconhecido';  
            bancos.forEach((v)=>{
                if(dados.user.banco == v.value){
                    bancoNome =   v.name; 
                }
                
            });
            viewForm = ( 
                <>
                    {(dados.user.sysBan == 'N')?<Stack pb='12px'><AlertBox title='Dados Aprovados' desc={'' } status="success"/></Stack>:null}  
                    {(dados.user.sysBan == 'R')?<Stack pb='12px'><AlertBox title='Dados Reprovados' desc={dados.user.obsBanco } status="error"/></Stack>:null}  
                    <BoxInfoFlex title ='Banco'>{bancoNome}</BoxInfoFlex> 
                    <BoxInfoFlex title ='Agência'>{dados.user.agencia ==null?'':dados.user.agencia} {dados.user.agenciaDig==null?'':' - '+dados.user.agenciaDig}</BoxInfoFlex> 
                    <BoxInfoFlex title ='Conta'>{dados.user.conta==null?'':dados.user.conta} {dados.user.contaDig==null?'':' - '+dados.user.contaDig}</BoxInfoFlex>  
                    {(dados.user.sysBan == 'P')?<BoxAprovarDados title='Deseja aprovar os dados bancários?' modulo="client" cmd='saveClientBan' codusu={dados.user.id} BuscarDados={BuscarDados} />:null}  
                    
                </>
            ) 
        break;
    } 
    return <Stack gap={'0px'}>
        {reset?null:viewForm} 
    </Stack>
}
import { FaUsers } from "react-icons/fa";
import { LuArrowLeftRight, LuBarChart2, LuBoxes, LuDollarSign, LuFile, LuFileText, LuLink, LuLink2, LuListChecks, LuLocate, LuMapPin, LuPercent, LuSettings, LuStore, LuUploadCloud, LuUser2, LuUserCog2, LuUserPlus, LuUsers, LuUsers2, LuWrench } from "react-icons/lu";
 
import { PageAdminLogin } from "../../pages/admin/auth/PageLogin";  
import { PageAdminDashboard } from "../../pages/admin/dashboard/PageAdminDashboard";
import { PageAdminClient } from "../../pages/admin/client/PageClient";
import {PageAdminClientDocument} from "../../pages/admin/document/PageClientDocument";
// import { PageAdminDocument } from "../../pages/painel/document/PageDocument";
 
import { PageAdminPlans } from "../../pages/admin/plans/PagePlans"; 
import { fase } from "../../components/session/SessionAdmin"; 
import { MdOutlineBusinessCenter } from "react-icons/md";
import { TbPlugConnected } from "react-icons/tb";
import { PageAdminAcquirer } from "../../pages/admin/acquirer/PageAcquirer";
import { PageAdminTransaction } from "../../pages/admin/transaction/PageAdminTransaction"; 
import { PageAdminReports } from "../../pages/admin/reports/PageAdminReports";


import { PageAdminAlerts } from "../../pages/admin/transaction/PageAlerts";
import { PageAdminTransferPendent } from "../../pages/admin/transfer/PageTransferPendent";
import { PageAdminTransfer } from "../../pages/admin/transfer/PageTransfer";
import { PageAdminLog } from "../../pages/admin/config/PageLog";
import { PageAdminScaleProduct } from "../../pages/admin/scale/PageAdminScaleProduct";
import { PageAdminScaleStore } from "../../pages/admin/scale/PageAdminScaleStore";
import { PageAdminTracking } from "../../pages/admin/tracking/PageTracking";
import { PageAdminWithdraw } from "../../pages/admin/withdraw/PageAdminWithdraw";
import { PageAdminBass } from "../../pages/admin/bass/PageAdminBass";
import { RiBankLine } from "react-icons/ri";
import { PageAdminSettings } from "../../pages/admin/settings/PageSettings";

let painelRoutes = null;
export function getPainelRoutes(){
    return painelRoutes;
};
export function getMenuRoutes(login=false,acessos={}){
    let sendM= [];
    let subs = {}; 
    painelRoutes.forEach(rota => {
        if(login ){   
            if(rota.login  && (rota.fase == null|| rota.fase == fase)){
                if(rota.sub == null){
                    sendM.push({title:rota.title,icon:rota.icon,link:rota.url,hidden:rota.menu,tema:rota.tema});
                }else{
                    if(typeof subs[rota.sub.title] == 'undefined'){
                        subs[rota.sub.title] = {title:rota.sub.title,icon:rota.sub.icon,sub:[],hidden:false};
                        painelRoutes.forEach(rotaS => {
                            if(rotaS.sub!==null && rota.sub.title == rotaS.sub.title){
                                if(rotaS.menu){
                                    subs[rota.sub.title].hidden=true;
                                }
                                subs[rota.sub.title].sub.push({title:rotaS.title,icon:rotaS.icon,link:rotaS.url,hidden:rotaS.menu,tema:rotaS.tema})
                            }
                        })
                        sendM.push(subs[rota.sub.title]);
                    } 
                }
                
            }
        }else{
            if(!rota.login && rota.menu){
                sendM.push(rota);
            }
        }
    });
    return sendM;
};
const newRoutePainel = function({title='',icon=null,description='',url = '',tema=false,layout='painel',login=false,page, session='sessionPainel',redirect='',menu=false, sub=null,acesso=0,fase:faseI=null}){
    painelRoutes.push({
        title,
        icon,
        description,
        layout,
        login,
        page,
        url,
        session,
        redirect,
        menu,
        sub,
        fase:faseI,
        tema,
        acesso
    })
}

const controller = '/admin';
export function loadPainelRoutes(){
    if(painelRoutes == null){
        painelRoutes = [];
        //public
        newRoutePainel({ title       :   "Login",       url:controller+"",             page        : PageAdminLogin,     tema:false,   login       : false, redirect    : controller+'/dashboard' });
        newRoutePainel({ title       :   "Login",       url:controller+"/login",       page        : PageAdminLogin,     tema:false,    login       : false, redirect    : controller+'/dashboard' });
       
        //admin
        newRoutePainel({  title      :   "Início",             icon:LuBarChart2,       url:controller+"/dashboard",    page    : PageAdminDashboard,   tema:true,  login : true , menu:true  });
        //documentos
        newRoutePainel({fase: 2,icon: LuFile, title       :   "Aprovar Documentos",      url:controller+"/client/approve",        page:   PageAdminClientDocument,        tema:true,    login : true , menu:true   });
         

        newRoutePainel({ fase: 10,title       :   "Todas Pedidos",       url:controller+"/reports",         page:   PageAdminReports,         tema:true,    login : true , menu:true  ,     sub:{ title       :   "Transações",  icon: MdOutlineBusinessCenter}});
        newRoutePainel({fase: 10, title       :   "Saques Pendentes",    url:controller+"/withdraw",        page:   PageAdminWithdraw,        tema:true,    login : true , menu:true  ,     sub:{ title       :   "Transações"}});
        newRoutePainel({fase: 10, title       :   "Todas Transações",    url:controller+"/transaction",     page:   PageAdminTransaction,        tema:true,    login : true , menu:true  ,     sub:{ title       :   "Transações"}});
        

  
        newRoutePainel({fase: 10, title       :   "Aprovar Documentos",      url:controller+"/client/approve",        page:   PageAdminClientDocument,        tema:true,    login : true , menu:true  ,     sub:{ title :   "Clientes",  icon: LuUsers2}});
        newRoutePainel({fase: 10, title       :   "Listar Clientes",         url:controller+"/client/list",        page:   PageAdminClient,        tema:true,    login : true , menu:true  ,         sub:{ title :   "Clientes"}});
        
       
        newRoutePainel({fase: 10, title       :   "Adquirentes", icon: TbPlugConnected  , url:controller+"/integration/acquirer",        page:   PageAdminAcquirer,        tema:true,    login : true , menu:true  });
        newRoutePainel({fase: 10, title       :   "BasS", icon: RiBankLine   , url:controller+"/integration/bass",        page:   PageAdminBass,        tema:true,    login : true , menu:true  });
        newRoutePainel({fase: 10, title       :   "Planos",   icon:  LuPercent ,   url:controller+"/plans/list",        page:   PageAdminPlans,        tema:true,    login : true , menu:true  });
       
        
        newRoutePainel({fase: 10, title       :   "Por Loja",        url:controller+"/scale/store",        page:   PageAdminScaleStore,        tema:true,    login : true , menu:true  ,    sub:{ title :   "Escalabilidade",  icon: LuBarChart2}});
        newRoutePainel({fase: 10, title       :   "Por Produto",     url:controller+"/scale/product",        page:   PageAdminScaleProduct,        tema:true,    login : true , menu:true  ,                  sub:{ title :   "Escalabilidade"}});
       
        newRoutePainel({fase: 10, title       :   "Configuração",   icon:  LuSettings ,   url:controller+"/settings",        page:   PageAdminSettings,        tema:true,    login : true , menu:true  });
       
        
        // newRoutePainel({ title       :   "Logs",            icon:LuFileText ,       url:controller+"/logs",    page    : PageAdminLog,   tema:true,  login : true , menu:false  });
 
    } 
    return painelRoutes;
}
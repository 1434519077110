import {  Flex, Image, Stack, Text,Icon,colorMode, toggleColorMode, useColorMode, Input, Grid, GridItem, SimpleGrid, Progress} from "@chakra-ui/react"; 
import { useEffect, useState } from "react";
import LoadBg from "../../../components/backgound/LoadBg";
import { CardPrice } from "../../../components/card/CardValues";
import { PageHK, SubHK } from "../../../components/heading/HeadingKalyst";
import { sendFormSocket } from "../../../components/session/SessionKalyst";
import { BiMoney } from "react-icons/bi";
import { FaArrowTrendUp } from "react-icons/fa6";
import { LuBox, LuDelete, LuLineChart, LuPen, LuStopCircle, LuTrash, LuUser2, LuUsers2 } from "react-icons/lu";
import LineChart from "../../../components/charts/LineChart" 
import BarChart from "../../../components/charts/BarChart"
import DonutChart from "../../../components/charts/DonutChart"
import { Box, HandCoins, List } from 'lucide-react';
import { lineChartDataDashboard, lineChartOptionsDashboard,barChartDataCharts2,barChartOptionsCharts2, donutChartDataCharts1,donutChartOptionsCharts1} from "../../../theme/theme";
import ListResponsive from "../../../components/list/ListResponsive";
import { ButtonKNew } from "../../../components/button/ButtonKalyst";
import ModalPage from "../../../components/modal/ModalPage";
import FormStore from "./FormStore";
import BoxDialog from "../../../components/modal/BoxDialog"; 

export function PagePainelStore(props){  
    const [open,setOpen]    = useState(false); 
    const [dados,setDados]  = useState(null);
    const [edit,setEdit]    = useState({}); 
    const [alert,setAlert]    = useState({}); 
    function BuscarDados(reload){
        sendFormSocket({campo:'pages', modulo:'store',cmd:'list',form:{reload},callback:(result)=>{ 
            setDados(result)
        }});
    } 
    useEffect(()=>{
        BuscarDados(true);
        var interV =setInterval(()=>{
            BuscarDados(false);
        },500);
        return  ()=>{
            clearInterval(interV);
        }
    },[]);

    if(dados == null){ 
        return <LoadBg/>
    }else{ 
        const titles = [
            {name:'Nome',       campo:'nome'        ,w:7},
            {name:'Telefone',   campo:'telefone'    ,w:6},
            {name:'E-mail',     campo:'email'       ,w:7} 
        ];
        const lista = []
        if(dados.lojas.length>0){
            dados.lojas.forEach((loja)=>{
                lista.push({id:loja.id,title:loja.nome,status:loja.situa,  nome:loja.nome,       telefone:loja.telefone,  email:loja.email})
            }) 
        } 
        return  ( 
            <Stack zIndex={3} w='full' gap={{base:'16px',md:'32px'}}  align={'flex-start'} justify='flex-start'   minH={'100vh'} pb='100px'  margin = '0 auto'  >
                <PageHK>Lojas</PageHK> 
                <ListResponsive titles={titles} dados={lista} cmd={
                    [
                        { icon:<LuPen/>,name:'Editar', click:(d)=>{  
                            setEdit(d.id);
                            setOpen(true); 
                        }}, 
                        { icon:<LuTrash/>,name:'Deletar', click:(d)=>{  
                            setAlert({load:true});
                            setTimeout(()=>{ 
                                setAlert({
                                fechar:true,
                                icon   : <Icon as={LuTrash} h={{base:'50px'}} w={{base:'50px'}} color='red.300' />, 
                                titulo:'Deletar Loja',
                                size:'md',
                                mensagem:'Deseja realmente deletar a loja <b>'+d.nome+'<b>?', 
                                botao:[
                                    {
                                        nome:'SIM', 
                                        cmd:()=>{
                                            setAlert({load:true});
                                            sendFormSocket({ modulo:'store',cmd:'deleteStore',form:{id:d.id},callback:(result)=>{ 
                                                setTimeout(()=>{
                                                    setAlert({exit:true});
                                                    BuscarDados(false); 
                                                },500)
                                            }});
                                        }
                                    },
                                    {
                                        nome:'NÃO',
                                        variant:'ghost',
                                    }
                                ]
                                });
                            })
                        
                        }}
                    ]
                } /> 
                <ButtonKNew onClick={()=>{ 
                    setEdit(null);
                    setOpen(true);
                }}>Adicionar Loja</ButtonKNew>
                <ModalPage open={open} setOpen={setOpen}>
                    <FormStore   open={open} setOpen={(el)=>{ 
                        setOpen(el)
                    }} edit={edit} setEdit={setEdit}/>
                </ModalPage> 
                <BoxDialog param={alert}  />
            </Stack> 
        )
    }
    
}
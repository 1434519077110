import { Flex, Grid, GridItem, HStack, Icon, SimpleGrid, Spacer, Stack, Switch, Text, Textarea } from "@chakra-ui/react";
import { HK4, HK5, PageHK } from "../../../components/heading/HeadingKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { object, string } from "yup";
import { SendForm } from "../../../components/form/SendForm";
import { TextK1 } from "../../../components/text/TextKalyst";
import { LinkK } from "../../../components/button/LinkKalyst";
import { useEffect, useRef, useState } from "react";
import SelectK from "../../../components/select/SelectKalyst";
import { sendFormSocket } from "../../../components/session/SessionKalyst";
import { LojaSelect } from "../../../layout/painel/MenuPainel";
import { CardSettings } from "../../../components/card/CardSettings";
import { FaInfoCircle } from "react-icons/fa";

export function BoxAcesso({acesso,setAcesso,a}){
    const [reload,setReload] = useState(false);
    return (
        <Flex  w={'full'} gap='14px' align={'center'} justify='flex-start'> 
            <Switch colorScheme="purple" isChecked={acesso[a.name]=='S'?true:false}  onChange={(e)=>{ 
                const temp =acesso;
                if(acesso[a.name]=='S'){
                    temp[a.name]='N';
                }else{
                    temp[a.name]='S';
                }  
                setAcesso(temp); 
                setReload({});
            }}/> 
            <Text  color={'textLight.100'} _dark={{color:'textDark.100'}} fontSize={{base:'12px',md:'13px',lg:'14px'}} >{a.title}</Text> 
            <Icon title={a.info} as={FaInfoCircle}  color={'textLight.100'} _dark={{color:'textDark.100'}} fontSize={{base:'12px',md:'14px',lg:'16px'}}/>
        </Flex> 
    )
}
export default function FormAPI( {open,setOpen,edit = null,setEdit}){  
    const [load,setLoadD] = useState(null);
    const [base,setBase] = useState(null);
    const [reload,setReload] = useState(false);
    
    const [acesso,setAcesso] = useState({
        pago        :   'S',
        chargeback  :   'S',
        estorno     :   'S',
        protesto    :   'S',
        recusado    :   'S',
        rastreio    :   'N'
    });
    useEffect(()=>{
        if(edit!==null){
            setLoadD(0);
            setTimeout(()=>{
                sendFormSocket({ modulo:'webhook',cmd:'info',form:{id:edit},callback:(result)=>{ 
                    setLoadD(1);
                    setBase(result);
                    setReload(true); 
                }});
            },1000);
        }else{
            setLoadD(1);
        } 
    },[])  
    
    let dados = {};
    let listaAcessos = [
        {title:'Pago',      name:'pago',        value:'S', info:'Pedido Pagos'},
        {title:'Chargback', name:'chargeback',  value:'S', info:'Pedido Chargeback'},
        {title:'Estorno',   name:'estorno',     value:'S', info:'Pedido Estornados'},
        {title:'Protesto',  name:'protesto',    value:'S', info:'Pedido em Protesto'},
        {title:'Recusado',  name:'recusado',    value:'S', info:'Pedido Recusado'},
        {title:'Rastreio',  name:'rastreio',    value:'S', info:'Pedido com Rastreio Informado'}
    ]
    let send ={loja:LojaSelect}; 
    if(base!== null){ 
        send={
            id      :   base.id,
            loja    :   LojaSelect
        }; 
        dados = {
            nome        :   base.nome,
            link        :   base.link 
        };
    } 

    if(!reload){
        return (
            <SendForm  dados = {{modulo:'webhook',cmd:'saveWebhook',form:send}}  
                initial={ dados } 
                schema={ 
                    object({ 
                        nome:   string().required('Nome do Webhook é obrigatório!'),
                        link:    string().required('URL é obrigatório!') 
                    })
                } 
                callback={(result)=>{ 
                    setOpen(false); 
                }}
                gap={'10px'}
                > 
                <PageHK   textAlign='left'>Webhook</PageHK>   
                <InputK  loaded={load} focus={true} type='name' label="Nome"   name="nome" placeholder="Nome do webhook"   autoComplete={false}/> 
                <InputK  type='site'    loaded={load}   label="URL" leftA={'HTTPS://'} leftAW={'5em'}  name="link" placeholder="www.meusite.com.br"   focus={false} autoComplete={false}/>
                <Stack w={'full'}  borderRadius='8px'     align='center' justify={'center'} bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}} p='16px'    >
                    <TextK1>Status Pedidos:</TextK1>
                    <Grid
                        w={{base:'100%',md:'100%'}}  
                        templateColumns={{base:'repeat(2fr, 1fr)','lg':'repeat(3, 1fr)','2xl':'repeat(3, 1fr)'}} 
                        gap={{base:'4px',md:'16px'}}   
                    > 
                        {listaAcessos.map((a,i)=>{
                            return (
                                <GridItem key={'pos'+i} > 
                                   <BoxAcesso acesso={acesso} setAcesso={setAcesso} a={a}/>
                                </GridItem>
                            )
                        })}
                               
                    </Grid>
                </Stack>
                    
                <Flex w={'full'} gap={'20px'} align={'center'}  justify={'flex-end'}> 
                    <ButtonKalyst loaded={load}  >
                        {edit==null?'Registrar Webhook':'Salvar Webhook'}
                    </ButtonKalyst> 
                    <LinkK onClick={()=>{
                        setOpen(false);
                        setEdit(null)
                    }}>
                        Cancelar
                    </LinkK>
                </Flex>
            </SendForm>
        )
    }else{
        setTimeout(()=>{setReload(false)})
        return null;
    }
    
}